import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mergeObjects'
})
export class MergeObjectsPipe implements PipeTransform {
	transform(object1: any, object2: any, ...propertiesToMerge: any[]): any {
		if (!propertiesToMerge.length) {
			return { ...object1, ...object2 };
		}

		const firstObject = { ...object1 };
		const newObject = { ...object2 };

		// Iterate over additional arguments (if any)
		for (let i = 0; i < propertiesToMerge.length; i++) {
			const propName = propertiesToMerge[i];

			// Only copy the property if it exists in the firstObject
			if (propName in firstObject) {
				newObject[propName] = firstObject[propName];
			}
		}

		return newObject;
	}
}

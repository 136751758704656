import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'applyCustomFunction'
})
export class ApplyCustomFunctionPipe implements PipeTransform {
	transform(value: any, func: (...args: any[]) => any, ...args: any[]): any {
		if (typeof func !== 'function') {
			throw new Error('Second argument must be a function');
		}
		return func(value, ...args);
	}
}

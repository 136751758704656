import { Component, ElementRef, Input, OnInit } from '@angular/core';

/**
 * Back to Top Component
 * This component provides a simple button to navigate back to the top of a page.
 * Use it wherever it may be handy!
 */
@Component({
	selector: 'app-back-to-top',
	templateUrl: './back-to-top.component.html',
	styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent implements OnInit {
	@Input() elClass: string;
	@Input() elementRef?: ElementRef;

	constructor() {}

	ngOnInit(): void {}

	click() {
		const element = this.elementRef?.nativeElement || document.getElementsByClassName(this.elClass)?.[0] || window
		element.scrollTo({ top: 0, behavior: 'smooth' });
	}
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { GlobalService } from '../../../../state/global/global.service';
import { FileService } from '../file.service';

@Component({
  selector: 'app-file-google-docs-viewer',
  templateUrl: './file-google-docs-viewer.component.html',
  styleUrls: ['./file-google-docs-viewer.component.scss']
})
export class FileGoogleDocsViewerComponent implements OnInit {

  @Input() file: PublicFile;
  iframeUrl: string;

  constructor(
	  private readonly fileService: FileService,
	  private readonly globalService: GlobalService
  ) { }

  ngOnInit(): void {
	  this.getTemporaryFileURL(this.file);
  }

  getTemporaryFileURL(file: PublicFile){
	  this.fileService.getTemporaryFileURL(file.path).subscribe(
		  (response) => {
			  let encodedUrl = encodeURIComponent(response.url);
			  this.iframeUrl = 'https://docs.google.com/viewer?embedded=true&url=' + encodedUrl;
		  },
		  (err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
	  );
  }
}

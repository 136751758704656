import { Component, Input, OnInit } from '@angular/core';
import { getBackgroundColorFromString } from '../../../_core/utils/string.utils';

@Component({
	selector: 'app-initial-avatar',
	templateUrl: './initial-avatar.component.html',
	styleUrls: ['./initial-avatar.component.scss']
})
export class InitialAvatarComponent implements OnInit {
	@Input() initials: string;
	@Input() backgroundColor?: string;
	@Input() textColor?: string = '#fff';
	@Input() fontSize?: string = '2.2rem';
	@Input() scale?: string = '50px';

	constructor() { }

	ngOnInit(): void {

		if (!this.initials) {
			console.error('Initials are required for the initial avatar component');
			return;
		}

		if (!this.backgroundColor) {
			this.backgroundColor = getBackgroundColorFromString(this.initials);
		}
	}

}

<button
	*ngIf="itemCtrl"
	#selectButton
	class="select-button {{ buttonColor }}"
	[class.button-small]="buttonSize === 'small'"
	[class.button-extra-small]="buttonSize === 'extra-small'"
	[class.icon-left]="buttonText && buttonText !== ''"
	[class.mat-flat-button]="buttonText && buttonText !== ''"
	[class.mat-mini-fab]="!buttonText || buttonText === ''"
>
	<span *ngIf="buttonText && buttonText !== ''">{{ buttonText }} &nbsp;&nbsp;</span><i class="uil {{ iconName }}"></i>

	<mat-select
		disableOptionCentering
		panelClass="button-select-panel"
		#matSelect
		[multiple]="selectLayout === 'groups' || selectLayout === 'multiples'"
		[value]="currentValue"
		(closed)="removeOverlay()"
		[formControl]="itemCtrl"
		[compareWith]="compareFn"
		[ngClass]="showExpandButton ? '' : 'hide'"
	>
		<!-- Groups -->
		<ng-container *ngIf="selectLayout === 'groups'">
			<mat-optgroup *ngFor="let group of options; trackBy: trackByFn" [label]="group.name" class="condensed-options">
				<ng-container *ngFor="let entity of group.items; trackBy: trackByFn">
					<mat-option *ngIf="!entity.hideFromMenu" [value]="entityValue ? entity[entityValue] : entity">
						{{ entity.name | entityFieldMask: entity.extra?.maskPath }}
					</mat-option>
				</ng-container>
			</mat-optgroup>
		</ng-container>

		<!-- Multiples -->
		<ng-container *ngIf="selectLayout === 'multiples'">
			<ng-container *ngFor="let entity of options; trackBy: trackByFn">
				<mat-option *ngIf="!entity.hideFromMenu" [value]="entityValue ? entity[entityValue] : entity">
					{{ entity.name | entityFieldMask: entity.extra?.maskPath }}
				</mat-option>
			</ng-container>
		</ng-container>

		<!-- Singles -->
		<ng-container *ngIf="selectLayout === 'singles'">
			<ng-container *ngFor="let entity of options; trackBy: trackByFn">
				<mat-option *ngIf="!entity.hideFromMenu" [value]="entityValue ? entity[entityValue] : entity">
					{{ entity.name | titlecase | entityFieldMask: entity.extra?.maskPath }}
				</mat-option>
			</ng-container>
		</ng-container>

		<button
			*ngIf="applyButton"
			mat-flat-button
			class="button-small icon-left sticky-bottom no-border-radius"
			color="primary"
			(click)="applyValue()"
		>
			Apply<i class="icon icon-check"></i>
		</button>
	</mat-select>
</button>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { GlobalService } from '../../../../state/global/global.service';
import { FileService } from '../file.service';

@Component({
  selector: 'app-file-microsoft-viewer',
  templateUrl: './file-microsoft-viewer.component.html',
  styleUrls: ['./file-microsoft-viewer.component.scss']
})
export class FileMicrosoftViewerComponent implements OnInit {

	@Input() file: PublicFile;
	@Input() maxH: boolean = false;
	iframeUrl: string;

	constructor(
		private readonly fileService: FileService,
		private readonly globalService: GlobalService
	) { }

	ngOnInit(): void {
		this.getTemporaryFileURL(this.file);
	}

	getTemporaryFileURL(file: PublicFile){
		this.fileService.getTemporaryFileURL(file.path).subscribe(
			(response) => {
				let encodedUrl = encodeURIComponent(response.url);
				this.iframeUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodedUrl;
			},
			(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
		);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'flatArray'
})
export class FlatArrayPipe implements PipeTransform {
	constructor() {}

	transform(array: any): any[] {
		if (array) {
			return array.flat();
		}
		return array;
	}
}

import { Directive, OnInit, Renderer2, Input, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Column, TableSettings } from '../../state/table/table.model';


/**
 * Resize Column Directive
 * Allows for resizing table column's widths
 */
@Directive({
	selector: '[resizeColumn]'
})
export class ResizeColumnDirective implements OnInit, OnChanges {
	@Input('resizeColumn') resizable: boolean;
	@Input('columnWidth') columnWidth: { column: Column, settings?: TableSettings};
	@Output() resized: EventEmitter<any> = new EventEmitter();

	@Input() index: number;

	private startX: number;

	private startWidth: number;

	private column: HTMLElement;

	private table: HTMLElement;

	private pressed: boolean;

	constructor(private renderer: Renderer2, private el: ElementRef) {
		this.column = this.el.nativeElement;
	}

	async getWidth(column: any): Promise<string> {
		let val = column?.extra?.width;

		if (this.columnWidth.settings?.columnWidths) {
			for (let item of this.columnWidth.settings.columnWidths) {
				if (item.name === column.name) {
					val = item.width;
					break;
				}
			}
		}

		if (column?.type === 'badges') {
			const width = await new Promise<number>((resolve) => {
				setTimeout(() => {
					let maxComputedWidth = 150;
					const tableRows = Array.from(this.table?.querySelectorAll('.mat-row'));
					tableRows.forEach((row: any) => {
						const tds = row.querySelectorAll('.mat-cell');
						const td = tds.item(this.index);
						if(td){
							const computedWidth = td.querySelector('.cell-container span')?.clientWidth || 0;
							if (computedWidth > maxComputedWidth) {
								maxComputedWidth = computedWidth;
							}
						}
					});
					resolve(maxComputedWidth);
				}, 100);
			});

			return (width + 15 + 15) + 'px'; // 15px is padding left, 15px is padding right
		}

		return val ? (val + 'px') : '150px';
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if(this.columnWidth && changes.columnWidth){
			const width = await this.getWidth(this.columnWidth.column);
			this.renderer.setStyle(this.column, 'width', width);
		}
	}

	ngOnInit() {
		if (this.resizable) {
			const row = this.renderer.parentNode(this.column);
			const thead = this.renderer.parentNode(row);
			this.table = this.renderer.parentNode(thead);

			const resizer = this.renderer.createElement('span');
			this.renderer.addClass(resizer, 'resize-holder');
			this.renderer.appendChild(this.column, resizer);
			this.renderer.listen(resizer, 'mousedown', this.onMouseDown);
			this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
			this.renderer.listen('document', 'mouseup', this.onMouseUp);

		}
	}

	onMouseDown = (event: MouseEvent) => {
		this.pressed = true;
		this.startX = event.pageX;
		this.startWidth = this.column.offsetWidth;
	};

	onMouseMove = (event: MouseEvent) => {
		const offset = 35;
		if (this.pressed && event.buttons) {
			this.renderer.addClass(this.table, 'resizing');

			// Calculate width of column
			const width = this.startWidth + (event.pageX - this.startX - offset);

			const tableCells = Array.from(this.table.querySelectorAll('.mat-row')).map((row: any) =>
				row.querySelectorAll('.mat-cell').item(this.index)
			);

			console.log(this.index);

			// Set table header width
			this.renderer.setStyle(this.column, 'width', `${width}px`);
			this.renderer.setStyle(this.column, 'min-width', `${width}px`);

			// Set table cells width
			for (const cell of tableCells) {
				if (cell) {
					this.renderer.setStyle(cell, 'width', `${width}px`);
					this.renderer.setStyle(cell, 'min-width', `${width}px`);
				}
			}
		}
	};

	onMouseUp = (event: MouseEvent) => {
		if (this.pressed) {
			this.pressed = false;
			this.renderer.removeClass(this.table, 'resizing');

			this.resized.emit({
				column: this.column,
				width: this.column.offsetWidth
			});
		}
	};
}

<mat-menu #appMenu="matMenu" class="version-menu">
	<button
		[style.height.px]="80"
		mat-menu-item
		(click)="$event.stopImmediatePropagation(); $event.preventDefault(); $event.stopPropagation()"
	>
		<div class="file-review-type-section">
			<p>Show file review type</p>
			<mat-button-toggle-group [style.height.px]="25" (change)="onSelectFileType($event)">
				<mat-button-toggle [checked]="!selectedFileReviewType">All</mat-button-toggle>
				<mat-button-toggle [value]="FileReviewTypeEnum.INTERNAL_REVIEW">
					Internal
				</mat-button-toggle>
				<mat-button-toggle [value]="FileReviewTypeEnum.CLIENT_REVIEW">
					Client
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</button>

	<button
		mat-menu-item
		[style.background]="
			selectedFileVersion?.id === fileVersion.id || (selectedFileVersion === undefined && last)
				? 'rgba(0, 0, 0, 0.04)'
				: 'transparent'
		"
		[style.height.px]="80"
		[style.overflow]="'visible'"
		(click)="onSelectFileVersion(fileVersion)"
		*ngFor="
			let fileVersion of selectedFileReviewType ? (fileVersions | filterArrayBy: 'reviewType':selectedFileReviewType) : fileVersions;
			let index = index;
			let last = last
		"
	>
		<div class="file-version-item">
			<div class="flex-column flex">
				<span class="version">Version {{ fileVersions.length - index }}</span>

				<app-file-review-status [style.marginLeft.px]="-5" [showWithLabels]="false" [file]="fileVersion"></app-file-review-status>
			</div>
			<div class="flex-column flex">
				<span class="date">
					{{ fileVersion?.created | date: 'shortDate' }}
				</span>
			</div>
		</div>
	</button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="appMenu">
	<ng-container *ngIf="selectedFileVersion && (fileVersions | findIndexByInArray: selectedFileVersion.id:'id') !== -1">
		Version {{ fileVersions?.length - (fileVersions | findIndexByInArray: selectedFileVersion.id:'id') }}/{{ fileVersions?.length }}
	</ng-container>
	<ng-container *ngIf="!selectedFileVersion && fileVersions?.length"> Version 1/{{ fileVersions?.length }}</ng-container>
	<ng-container *ngIf="!selectedFileVersion && !fileVersions?.length"> Versions </ng-container>

	<i class="uil uil-angle-down"></i>
</button>

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
	selector: 'app-strategic-brief-dialog-multiselect',
	templateUrl: 'multiselect.component.html',
	styleUrls: ['multiselect.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategicBriefDialogMultiselectComponent implements OnInit {
	@Input() id: string;
	@Input() placeholder: string;
	@Input() label: string;
	// eslint-disable-next-line @typescript-eslint/ban-types
	@Input() removeCb: Function = () => {};
	// eslint-disable-next-line @typescript-eslint/ban-types
	@Input() selectCb: Function = () => {};
	@Input() selected$: Observable<any> = new Observable();
	@Input() searchCtrl: FormControl = new FormControl();
	@Input() filtered$: Observable<any> = new Observable();
	@Input() required: boolean = false;

	separatorKeyCodes: number[] = [ENTER, COMMA];

	constructor(public readonly elementRef: ElementRef) {}

	ngOnInit(): void {}
}

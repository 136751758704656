<ng-container *ngFor="let file of files">
	<ng-container [ngSwitch]="getViewerType(file)">
		<ng-container *ngSwitchCase="'image'">
			<img *ngIf="file.url" [src]="file.url" />
			<img *ngIf="!file.url && file.path" [src]="file.path | secure | async"/>
		</ng-container>
		<ng-container *ngSwitchCase="'video'">
			<video controls *ngIf="videoPath">
				<source [src]="videoPath" [type]="getMimetype(file.mimeType)"/>
			</video>
		</ng-container>
		<ng-container *ngSwitchCase="'pdf'">
			<app-file-pdf-viewer
				[file]="file"
				[annotationMode]="annotationMode"
				[annotations]="annotations"
				(annotationsChanged)="annotationsChanged.emit($event)"
			></app-file-pdf-viewer>
		</ng-container>
		<ng-container *ngSwitchCase="'google'">
			<app-file-google-docs-viewer [file]="file"></app-file-google-docs-viewer>
		</ng-container>
		<ng-container *ngSwitchCase="'microsoft'">
			<app-file-microsoft-viewer [file]="file" [maxH]="maxH"></app-file-microsoft-viewer>
		</ng-container>
	</ng-container>
</ng-container>

import { FileService } from './../../../../../state/entities/file/file.service';
import { Program } from './../../../../../state/entities/program/program.model';
import { UserService } from './../../../../../state/entities/user/user.service';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Brand } from './../../../../../state/entities/brand/brand.model';
import { User } from './../../../../../state/entities/user/user.model';
import { Deliverable } from '../../../../../state/entities/deliverable/models/deliverable.model';
import { GlobalQuery } from '../../../../../state/global/global.query';
import { forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FileQuery } from '../../../../../state/entities/file/file.query';
import { PublicFile } from '../../../../../../../../api/src/file/file.entity';
import { Observable } from 'rxjs';
import { TableCollection } from '../../../../../state/table/table.model';
import {
	StrategicBriefDefaultDataObject,
	strategicBriefDefaultDataObjects,
} from '../../../strategic-brief-dialog/types/default/strategic-brief-default.data';

@Component({
	selector: 'app-strategic-brief-view-default',
	templateUrl: 'default.component.html',
	styleUrls: ['default.component.scss', '../../../strategic-brief-dialog/common/strategic-brief-dialog-common.scss'],
})
export class StrategicBriefViewDefaultComponent implements OnInit {
	@Input() program: Program;
	@Input() exportView?: boolean;
	@Output() dataSubscribed = new EventEmitter<void>();

	public readonly textAreaDataObjects: StrategicBriefDefaultDataObject[] = strategicBriefDefaultDataObjects;

	showMore = true;

	users: User[];
	clientLeads: string[];
	accountLeads: string[];

	deliverablesList: Deliverable[];
	deliverables: string[];

	brands: Brand[];
	primaryBrands: string[];
	secondaryBrands: string[];

	fileIds: string[];
	files$: Observable<PublicFile[]>;

	filesNames: string[];

	constructor(
		private readonly globalQuery: GlobalQuery,
		private readonly userService: UserService,
		private readonly cdr: ChangeDetectorRef,
		private readonly fileQuery: FileQuery,
		private readonly fileService: FileService
	) {
		this.brands = [];
		this.clientLeads = [];
		this.accountLeads = [];
		this.users = [];
		this.deliverables = [];
		this.deliverablesList = [];
		this.primaryBrands = [];
		this.secondaryBrands = [];
		this.fileIds = [];
		this.files$ = this.fileQuery.selectAll().pipe(map((files) => files.filter((file) => this.fileIds.includes(file.id))));
		this.filesNames = [];
	}

	ngOnInit(): void {
		if (!this.exportView) {
			this.showMore = false;
		}
		this._getLists();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.program && !changes?.program?.firstChange) {
			this._findLeads();
			this._findDeliverables();
		}
	}

	private _getLists(): void {
		const settings$ = this.globalQuery.authenticatedSettings$.pipe(first());
		const users$ = this.userService.getAll();
		const files$ = this._getProgramFiles();

		forkJoin([settings$, users$, files$]).subscribe(([settings, users, files]) => {
			this.users = users;
			this.brands = settings?.brands || [];
			this.deliverablesList = (this.program?.tactics || []).map((t) => ({
				id: t.id,
				name: t.name,
				deleted: false,
				organization: this.program.organization,
			})); //settings?.deliverables || [];
			this.fileIds = this.program?.programStrategicBrief?.data?.files || [];
			this.filesNames = files.items.filter((file) => this.fileIds.includes(file.id)).map((file) => file.name);
			this._findLeads();
			this._findBrands();
			this._findDeliverables();
			setTimeout(() => {
				this.cdr.detectChanges();
			});
			this.dataSubscribed.emit();
		});
	}

	private _findLeads(): void {
		const clientLeadIds = this.program?.programStrategicBrief?.data?.clientLeads || [];
		const accountLeadIds = this.program?.programStrategicBrief?.data?.accountLeads || [];

		this.clientLeads = this.users
			.filter((user) => clientLeadIds.includes(user.id))
			.map((user) => `${user.profile.nameFirst} ${user.profile.nameLast}`);
		this.accountLeads = this.users
			.filter((user) => accountLeadIds.includes(user.id))
			.map((user) => `${user.profile.nameFirst} ${user.profile.nameLast}`);
	}

	private _findDeliverables(): void {
		const deliverableIds = this.program?.programStrategicBrief?.data?.deliverables || [];
		this.deliverables = this.deliverablesList
			.filter((deliverable) => deliverableIds.includes(deliverable.id))
			.map((deliverable) => deliverable.name);
	}

	private _findBrands(): void {
		const primaryBranIds = this.program?.programStrategicBrief?.data?.primaryBrands || [];
		const secondaryBrandIds = this.program?.programStrategicBrief?.data?.secondaryBrands || [];

		this.primaryBrands = this.brands.filter((brand) => primaryBranIds.includes(brand.id)).map((brand) => brand.name);
		this.secondaryBrands = this.brands.filter((brand) => secondaryBrandIds.includes(brand.id)).map((brand) => brand.name);
	}

	private _getProgramFiles(): Observable<TableCollection<any>> {
		const filters = {
			...this.fileQuery.getValue().filters,
			category: { id: 'strategyDocument', name: 'Strategy Document' },
			programId: this.program.id,
			limit: 50,
		};
		return this.fileService.get(filters).pipe(first());
	}
}

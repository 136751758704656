import {
  BeforeUpdate,
  Column,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  RelationId,
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';
import { SortHelper } from '../_core/decorators/sort-helper.decorator';
import { BudgetCacheFragmentGenerator } from '../budget-cache/utils/budget-cache-fragment-generator';
import { BrandInitiativeFragmentGenerator } from '../brand-initiative/utils/brand-initiative-fragment-generator';
import { RetailerFragmentGenerator } from '../retailer/utils/retailer-fragment-generator';

import { BudgetPeriod, PublicBudgetPeriod } from '../budget-period/budget-period.entity';
import { BudgetAllocation, PublicBudgetAllocation } from '../budget-allocation/budget-allocation.entity';
import { Plan, PublicPlan } from '../plan/plan.entity';
import { PublicTactic, Tactic } from '../tactic/tactic.entity';
import { PublicRetailer, Retailer } from '../retailer/retailer.entity';
import { Category, PublicCategory } from '../category/category.entity';
import { Brand, PublicBrand } from '../brand/brand.entity';
import { Note, PublicNote } from '../note/note.entity';
import { PublicUser, User } from '../user/user.entity';
import { ProgramPhase, PublicProgramPhase } from '../program-phase/program-phase.entity';
import { ProgramSector, PublicProgramSector } from '../program-sector/program-sector.entity';
import { ProgramType, PublicProgramType } from '../program-type/program-type.entity';
import { PublicTag, Tag } from '../tag/tag.entity';
import { Product, PublicProduct } from '../product/product.entity';
import { BudgetCache, PublicBudgetCache } from '../budget-cache/budget-cache.entity';
import { BrandInitiative, PublicBrandInitiative } from '../brand-initiative/brand-initiative.entity';
import { PublicWarning, Warning } from '../warning/warning.entity';
import { File, PublicFile } from '../file/file.entity';
import { ExternalId, PublicExternalId } from '../external-id/external-id.entity';
import { CacheResultItem } from '../budget-cache/models/budget-cache.models';
import { Investment, PublicInvestment } from '../investment/investment.entity';
import { BrandStrategy, BrandStrategyFreeform } from '../brand/dtos/set-brand-strategy.dto';
import { WorkflowOption } from '../integrations/vyc-commerce-platform/models';

import { Map as MapUtils } from './utils/map.utils';
import { Agency, PublicAgency } from '../agency/agency.entity';
import { AgencyFragmentGenerator } from '../agency/utils/agency-fragment-generator';
import { Location, PublicLocation } from '../location/location.entity';
import { ProgramStrategicBriefEntity } from '../program-strategic-brief/program-strategic-brief.entity';
import { ProgramUtilization, PublicProgramUtilization } from '../program-utilization/program-utilization.entity';
import { PublicTacticGroup, TacticGroup } from '../tactic-group/tactic-group.entity';

export class Objective {
  public id: string;
  public label: string;
  public name: string;
  public value?: any;
  public caption: string;
  public category?: string;
  public description?: string;
  public option?: ObjectiveOption[];
  public selected?: boolean;

  constructor(value?: Partial<Objective>) {
    if (value) {
      value = JSON.parse(JSON.stringify(value));
    }
    for (const k in value) {
      this[k] = value[k];
    }
  }

  public toMacroObjectivePublic(): PublicMacroObjective {
    return {
      id: this.id,
      name: this.label,
    };
  }
}
export class ObjectiveOption {
  public title: string;
  public optionValues: {
    title: string;
  }[];
  public value?: string;
}
export enum ProgramStatus {
  Draft = 'draft',
  Published = 'published',
  Approved = 'approved',
}

export enum ProgramClassificationStatus {
  Draft = 'draft',
  Approved = 'approved',
}

export enum ProgramClassification {
  Program = 'program',
  MediaPlan = 'mediaPlan',
}

export type PublicMacroObjective = Pick<Objective, 'id' | 'name'>;

export type PublicProgram = Pick<
  Program,
  | 'id'
  | 'status'
  | 'name'
  | 'start'
  | 'end'
  | 'retailerId'
  | 'agencyId'
  | 'locationId'
  | 'investmentRecap'
  | 'atRisk'
  | 'objectives'
  | 'budgetRecommendation'
  | 'brandStrategy'
  | 'customerStrategy'
  | 'programSectorId'
  | 'brandInitiativeId'
  | 'description'
  | 'goal'
  | 'keyLearnings'
  | 'recommendations'
  | 'map'
  | 'commercePlatformWorkflow'
  | 'created'
  | 'updated'
  | 'classification'
  | 'classificationStatus'
  | 'programPhaseId'
  | 'programStrategicBrief'
> & {
  plan?: PublicPlan;
  retailer?: PublicRetailer;
  agency?: PublicAgency;
  location?: PublicLocation<any>;
  programPhase: PublicProgramPhase;
  programSector: PublicProgramSector;
  programType: PublicProgramType;
  programUtilization: PublicProgramUtilization;
  brandInitiative?: PublicBrandInitiative;
  budgetPeriod: PublicBudgetPeriod;
  budgetAllocations: PublicBudgetAllocation[];
  budgetCache?: PublicBudgetCache;
  brandCaches?: PublicBudgetCache[];
  budgetCacheBrand?: CacheResultItem;
  planIds?: string[];
  plans?: PublicPlan[];
  previousProgramId?: string;
  previousProgram?: PublicProgram;
  products?: PublicProduct[];
  investments?: PublicInvestment[];
  tactics?: PublicTactic[];
  tacticsGroups?: PublicTacticGroup[];
  categories?: PublicCategory[];
  brands?: PublicBrand[];
  notes?: PublicNote[];
  tags?: PublicTag[];
  owners?: PublicUser[];
  author: PublicUser;
  warnings?: PublicWarning[];
  files?: PublicFile[];
  externalIds: PublicExternalId[];
  readOnly: boolean;
  programStrategicBrief?: ProgramStrategicBriefEntity;
};

@Entity('programs')
@Index(['budgetPeriodId'])
export class Program {
  @PrimaryGeneratedColumn('uuid')
  @Sortable
  id: string;
  @Column('text', { nullable: false })
  budgetPeriodId: string;
  @ManyToOne(() => BudgetPeriod, (budgetPeriod) => budgetPeriod.programs, {
    onDelete: 'CASCADE',
  })
  @JoinColumn({ name: 'budgetPeriodId' })
  budgetPeriod: BudgetPeriod | Partial<BudgetPeriod>;
  @Column({
    type: 'enum',
    enum: ProgramStatus,
    default: ProgramStatus.Draft,
  })
  @Sortable
  status: ProgramStatus;
  @Column('text', { nullable: false })
  @Sortable
  name: string;
  @Column({ type: 'timestamptz', nullable: false })
  @Sortable
  start: string;
  @Column({ type: 'timestamptz', nullable: false })
  @Sortable
  end: string;
  @Column('boolean', { default: false, nullable: false })
  atRisk: boolean;
  @Column('jsonb', {
    nullable: true,
  })
  objectives?: Record<string, any>;
  @Column('jsonb', {
    nullable: true,
  })
  budgetRecommendation?: Record<string, unknown>;
  @Column('jsonb', { nullable: true })
  brandStrategy?: BrandStrategy[] | BrandStrategyFreeform;
  @Column('text', { nullable: true })
  customerStrategy?: string;
  @Column('text', { nullable: true })
  @Sortable
  description?: string;
  @Column('text', { nullable: true })
  @Sortable
  goal?: string;
  @Column('text', { nullable: true })
  @Sortable
  keyLearnings?: string;
  @Column('text', { nullable: true })
  @Sortable
  recommendations?: string;

  @Column({
    type: 'enum',
    enum: ProgramClassification,
    default: ProgramClassification.Program,
    nullable: true,
  })
  classification: ProgramClassification;

  @Column({
    type: 'enum',
    enum: ProgramClassificationStatus,
    nullable: true,
  })
  @Sortable
  classificationStatus: ProgramClassificationStatus;

  @Column('text', { nullable: true })
  retailerId?: string;
  @ManyToOne(() => Retailer, {
    eager: true,
    onDelete: 'CASCADE',
  })
  @JoinColumn({ name: 'retailerId' })
  @Sortable
  @SortHelper(new RetailerFragmentGenerator({ programMatch: true }))
  retailer?: Retailer | Partial<Retailer>;
  @Column('text', { nullable: true })
  agencyId?: string;
  @ManyToOne(() => Agency, {
    eager: true,
    onDelete: 'CASCADE',
  })
  @JoinColumn({ name: 'agencyId' })
  @Sortable
  @SortHelper(new AgencyFragmentGenerator({ programMatch: true }))
  agency?: Agency | Partial<Agency>;
  @Column('text', { nullable: true })
  locationId?: string;
  @ManyToOne(() => Location, {
    eager: true,
    onDelete: 'SET NULL',
  })
  @JoinColumn({ name: 'locationId' })
  location?: Location<any>;
  @Column('text', { nullable: false })
  programPhaseId: string;
  @ManyToOne(() => ProgramPhase, {
    eager: true,
    onDelete: 'CASCADE',
  })
  @JoinColumn({ name: 'programPhaseId' })
  programPhase: ProgramPhase | Partial<ProgramPhase>;
  @Column('text', { nullable: false })
  programSectorId: string;
  @ManyToOne(() => ProgramSector, {
    eager: true,
    onDelete: 'CASCADE',
  })
  @JoinColumn({ name: 'programSectorId' })
  programSector: ProgramSector | Partial<ProgramSector>;
  @Column('text', { nullable: true })
  programTypeId: string;
  @ManyToOne(() => ProgramType, {
    eager: true,
    nullable: true,
    onDelete: 'SET NULL',
  })
  @JoinColumn({ name: 'programTypeId' })
  programType: ProgramType | Partial<ProgramType>;
  @Column('text', { nullable: true })
  programUtilizationId: string;
  @ManyToOne(() => ProgramUtilization, {
    eager: true,
    nullable: true,
    onDelete: 'SET NULL',
  })
  @JoinColumn({ name: 'programUtilizationId' })
  programUtilization: ProgramUtilization | Partial<ProgramUtilization>;
  @Column('text', { nullable: true })
  brandInitiativeId: string;
  @ManyToOne(() => BrandInitiative, {
    eager: true,
    nullable: true,
    onDelete: 'CASCADE',
  })
  @JoinColumn({ name: 'brandInitiativeId' })
  @Sortable
  @SortHelper(new BrandInitiativeFragmentGenerator())
  brandInitiative: BrandInitiative | Partial<BrandInitiative>;
  @Column('boolean', { nullable: false, default: false })
  investmentRecap?: boolean;

  @OneToMany(() => BudgetAllocation, (budgetAllocation) => budgetAllocation.program, {
    //eager: true,
    cascade: ['insert', 'update'],
  })
  budgetAllocations: BudgetAllocation[] | Partial<BudgetAllocation>[];
  @Sortable
  @SortHelper(new BudgetCacheFragmentGenerator({ programMatch: true }))
  budgetCache?: BudgetCache;
  brandCaches?: CacheResultItem[];
  budgetCacheBrand?: CacheResultItem;
  @RelationId((program: Program) => program.plans)
  planIds?: string[];

  @ManyToMany(() => Plan, (plan) => plan.programs, {
    nullable: true,
  })
  @JoinTable({ name: 'programPlans' })
  plans?: Plan[] | Partial<Plan>[];

  @OneToMany(() => Investment, (investment) => investment.program, {
    eager: true,
    cascade: true,
  })
  investments?: Investment[] | Partial<Investment>[];

  @OneToMany(() => Tactic, (tactic) => tactic.program, {
    eager: true,
    cascade: true,
  })
  tactics?: Tactic[] | Partial<Tactic>[];

  @OneToMany(() => TacticGroup, (tacticGroup) => tacticGroup.program, {
    eager: true,
    cascade: true,
  })
  tacticsGroups?: TacticGroup[] | Partial<TacticGroup>[];

  @ManyToMany(() => Category, {
    eager: true,
    nullable: true,
    cascade: true,
  })
  @JoinTable({ name: 'programCategories' })
  categories?: Category[] | Partial<Category>[];
  @ManyToMany(() => Brand, {
    eager: true,
    nullable: true,
    cascade: true,
  })
  @JoinTable({ name: 'programBrands' })
  brands?: Brand[] | Partial<Brand>[];

  @OneToMany(() => ExternalId, (externalId) => externalId.program, {
    eager: false,
    cascade: true,
  })
  externalIds?: ExternalId[] | Partial<ExternalId>[];
  @ManyToMany(() => Tag, {
    eager: true,
    nullable: false,
    cascade: true,
  })
  @JoinTable({ name: 'programTags' })
  tags: Tag[] | Partial<Tag>[];
  @ManyToMany(() => Product, {
    eager: true,
    nullable: false,
    cascade: true,
    onDelete: 'CASCADE',
  })
  @JoinTable({ name: 'programProducts' })
  products: Product[] | Partial<Product>[];
  @Column('jsonb', { nullable: true })
  commercePlatformWorkflow?: WorkflowOption;
  @ManyToMany(() => Note, {
    eager: true,
    nullable: false,
    cascade: true,
    onDelete: 'CASCADE',
  })
  @JoinTable({ name: 'programNotes' })
  notes: Note[] | Partial<Note>[];
  @Column('ltree', { nullable: true })
  map?: string;
  @ManyToMany(() => User, {
    eager: true,
    nullable: true,
    cascade: true,
    onDelete: 'CASCADE',
  })
  @JoinTable({ name: 'programOwners' })
  owners?: User[] | Partial<User>[];
  @Column('text', { nullable: false })
  authorId: string;
  @ManyToOne(() => User, {
    eager: true,
    onDelete: 'CASCADE',
  })
  author: User;
  @Column('boolean', { nullable: false, default: false })
  deleted: boolean;
  @Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
  @Sortable
  created: string;
  @Column({ type: 'timestamptz', nullable: true })
  updated: Date;
  warnings?: Warning[];
  files?: File[];
  type: 'Program';
  previousProgram?: Partial<Program>;

  @OneToMany(() => ProgramStrategicBriefEntity, (programStrategicBrief) => programStrategicBrief.program)
  programStrategicBrief?: ProgramStrategicBriefEntity;

  constructor(value?: Partial<Program>) {
    if (value) {
      value = JSON.parse(JSON.stringify(value));
    }
    for (const k in value) {
      this[k] = value[k];
    }
  }

  @BeforeUpdate()
  updateTimestamp(): void {
    this.updated = new Date();
  }

  public toPublic(include: string[] = [], exclude: Array<keyof PublicProgram> = [], readOnly: boolean = false): PublicProgram {
    const pub: Partial<PublicProgram> = {
      id: this.id,
      status: this.status,
      name: this.name,
      start: this.start,
      end: this.end,
      retailerId: this.retailerId,
      agencyId: this.agencyId,
      locationId: this.locationId,
      programSectorId: this.programSectorId,
      investmentRecap: this.investmentRecap,
      brandInitiativeId: this.brandInitiativeId,
      atRisk: this.atRisk,
      objectives: this.objectives,
      budgetRecommendation: this.budgetRecommendation,
      brandStrategy: this.brandStrategy,
      customerStrategy: this.customerStrategy,
      description: this.description,
      goal: this.goal,
      keyLearnings: this.keyLearnings,
      recommendations: this.recommendations,
      map: this.map,
      commercePlatformWorkflow: this.commercePlatformWorkflow,
      created: this.created,
      readOnly,
      programStrategicBrief: this.programStrategicBrief,
      updated: this.updated,
      classification: this.classification,
      classificationStatus: this.classificationStatus,
      programPhaseId: this.programPhaseId,
    };

    if (exclude) {
      if (exclude.includes('readOnly')) {
        delete pub.readOnly;
      }
      if (exclude.includes('objectives')) {
        delete pub.objectives;
      }
    }

    if (this.planIds?.length) {
      pub.planIds = this.planIds;
    }

    if (this.plans?.length && !exclude.includes('plans')) {
      pub.plans = (this.plans as Partial<Plan>[]).map((p) => new Plan(p).toPublic());
    }

    if (this.retailer) {
      pub.retailer = new Retailer(this.retailer).toPublic();
    }

    if (this.agency) {
      pub.agency = new Agency(this.agency).toPublic();
    }

    if (this.location) {
      pub.location = new Location<any>(this.location).toPublic();
    }

    if (this.programPhase) {
      pub.programPhase = new ProgramPhase(this.programPhase).toPublic();
    }

    if (this.programSector) {
      pub.programSector = new ProgramSector(this.programSector).toPublic();
    }

    if (this.programType || this.programType === null) {
      pub.programType = this.programType ? new ProgramType(this.programType).toPublic() : null;
    }

    if (this.programUtilization || this.programUtilization === null) {
      pub.programUtilization = this.programUtilization ? new ProgramUtilization(this.programUtilization).toPublic() : null;
    }

    if (this.brandInitiative) {
      pub.brandInitiative = new BrandInitiative(this.brandInitiative).toPublic();
    }

    if (this.budgetPeriod) {
      pub.budgetPeriod = new BudgetPeriod(this.budgetPeriod).toPublic();
    }

    if (this.budgetAllocations?.length) {
      pub.budgetAllocations = (this.budgetAllocations as Partial<BudgetAllocation>[])
        .filter((b) => !b.deleted)
        .map((b) => {
          return new BudgetAllocation(b).toPublic();
        });
    }

    if (this.budgetCache) {
      pub.budgetCache = new BudgetCache(this.budgetCache).toPublic();
    }

    if (this.brandCaches) {
      pub.brandCaches = this.brandCaches.map((bc) => new BudgetCache(bc).toPublic());
    }

    if (this.budgetCacheBrand) {
      pub.budgetCacheBrand = this.budgetCacheBrand;
    }

    if (this.investments?.length && !exclude?.includes('investments')) {
      pub.investments = (this.investments as Partial<Investment>[]).map((i) => {
        return new Investment(i).toPublic();
      });
    }

    if (this.tactics?.length && !exclude?.includes('tactics')) {
      pub.tactics = (this.tactics as Partial<Tactic>[]).map((t) => {
        return new Tactic(t).toPublic();
      });
    }

    if (this.tacticsGroups?.length && !exclude?.includes('tacticsGroups')) {
      pub.tacticsGroups = (this.tacticsGroups as Partial<TacticGroup>[]).map((t) => {
        return new TacticGroup(t).toPublic();
      });
    }

    if (this.categories?.length) {
      pub.categories = (this.categories as Partial<Category>[]).map((c) => {
        return new Category(c).toPublic();
      });
    }

    if (this.brands?.length) {
      pub.brands = (this.brands as Partial<Brand>[]).map((b) => {
        return new Brand(b).toPublic();
      });
    }

    if (this.externalIds) {
      pub.externalIds = (this.externalIds as Partial<ExternalId>[]).map((i) => {
        return new ExternalId(i).toPublic();
      });
    }

    if (this.map?.length) {
      pub.previousProgramId = MapUtils.getParentId(this.id, this.map);
    }

    if (this.previousProgram) {
      pub.previousProgram = new Program(this.previousProgram).toPublic();
    }

    if (this.notes?.length) {
      pub.notes = (this.notes as Partial<Note>[]).map((n) => {
        return new Note(n).toPublic();
      });
    }

    if (this.tags?.length) {
      pub.tags = (this.tags as Partial<Tag>[]).map((t) => {
        return new Tag(t).toPublic();
      });
    }

    if (this.products?.length) {
      pub.products = (this.products as Partial<Product>[]).map((p) => {
        return new Product(p).toPublic();
      });
    }

    if (this.owners?.length) {
      pub.owners = (this.owners as Partial<User>[]).map((o) => {
        return new User(o).toPublic();
      });
    }

    if (this.author) {
      pub.author = new User(this.author).toPublic();
    }

    if (this.warnings?.length) {
      pub.warnings = (this.warnings as Partial<Warning>[]).map((w) => {
        return new Warning(w).toPublic();
      });
    }

    if (this.files?.length) {
      pub.files = (this.files as Partial<File>[]).map((f) => {
        return new File(f).toPublic();
      });
    }

    return pub as PublicProgram;
  }
}

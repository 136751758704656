import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isEmptyObject'
})
export class IsEmptyObjectPipe implements PipeTransform {
	transform(value: any): boolean {
		const isObject = typeof value === 'object' && value !== null && !Array.isArray(value);
		return isObject && Object.keys(value).length === 0;
	}
}

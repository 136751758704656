<a class="details" (click)="clicked.emit(milestone)">
	<div class="name-container">
		<h4 class="name">Milestone {{ milestone.name }}</h4>
		<span class="status" [style.background]="getMilestonesStatusColor(milestone.status)">{{ milestone?.status }}</span>
	</div>
	<div class="metadata">
		<div class="group left">
			<ul>
				<li *ngIf="milestone?.status">
					<span class="cat">Status:</span>
					<span class="value">{{ milestone?.status | titlecase }}</span>
				</li>
				<li *ngIf="milestone?.start">
					<span class="cat">Start Date:</span>
					<span class="value">{{ milestone?.start | date }}</span>
				</li>
				<li *ngIf="milestone?.end">
					<span class="cat">End Date:</span>
					<span class="value">{{ milestone?.end | date }}</span>
				</li>
				<li *ngIf="milestone?.description">
					<span class="cat">Description:</span>
					<span class="value">{{ milestone?.description }}</span>
				</li>
			</ul>
		</div>
	</div>
</a>

<div class="actions" *ngIf="!viewOnly">
	<div class="btn" [matMenuTriggerFor]="menu">
		<i class="uil-ellipsis-v"></i>
	</div>
</div>

<mat-menu #menu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item (click)="removeModal()">
			<i class="icon icon-delete"></i>
			Remove
		</button>
	</ng-template>
</mat-menu>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { DataCache } from '../../../_core/utils/cache.utils';
import { environment } from '../../../../environments/environment';
import { User } from './user.model';
import { UserStore } from './user.store';
import { UserAddDto } from './models/user-add.dto';
import { UserUpdateDto } from './models/user-update.dto';
import { saveAs } from 'file-saver';

/**
 * User Service
 * This service is reponsible for user logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class UserService {
	public cache: DataCache = new DataCache();

	constructor(private http: HttpClient, private readonly userStore: UserStore) {}

	/**
	 * Return a list of users based on a search query.
	 */
	suggest(text: string, avoidCache?: boolean): Observable<User[]> {
		// Check if we've already made this API call in this session.  Use the cached result if so.
		if (this.cache.get(text) && !avoidCache) {
			return of(this.cache.get(text));
		}

		return this.http.get<User[]>(`${environment.apiUrl}/user/organization/${environment.organizationId}/suggest/?query=${text}`).pipe(
			tap((response) => {
				this.cache.set(text, response);
			})
		);
	}

	/**
	 * Return a list of reviewers based on a search query.
	 */
	suggestReviewers(text: string, fileId?: string, avoidCache?: boolean) {
		if (!fileId) return this.suggest(text, avoidCache);

		// Check if we've already made this API call in this session.  Use the cached result if so.
		if (this.cache.get(text) && !avoidCache) {
			return of(this.cache.get(text));
		}

		return this.http
			.get<User[]>(`${environment.apiUrl}/organization/${environment.organizationId}/file/${fileId}/suggest-reviewers/?query=${text}`)
			.pipe(
				tap((response) => {
					this.cache.set(text, response);
				})
			);
	}

	/**
	 * Set the list of brands in Akita
	 */
	set(users: User[]): void {
		this.userStore.set(users.map((user) => user));
	}

	add(user: User): void {
		this.userStore.add(user);
	}

	setLoading(state: boolean): void {
		this.userStore.setLoading(state);
	}

	/**
	 * Return a list of users
	 */
	getAll(): Observable<User[]> {
		return this.http.get<User[]>(`${environment.apiUrl}/user/organization/${environment.organizationId}`);
	}

	store(dto: UserAddDto): Observable<User> {
		this.setLoading(true);
		return this.http.post<User>(`${environment.apiUrl}/user/organization/${environment.organizationId}`, dto).pipe(
			tap((val: User) => {
				this.setLoading(false);
				this.userStore.add(val);
			})
		);
	}

	update(userId: string, dto: UserUpdateDto): Observable<User> {
		this.setLoading(true);
		return this.http.put<User>(`${environment.apiUrl}/user/organization/${environment.organizationId}/${userId}`, dto).pipe(
			tap((val: User) => {
				this.setLoading(false);
				this.userStore.update(userId, val);
			})
		);
	}

	remove(userId: string): Observable<User> {
		return this.http.delete<User>(`${environment.apiUrl}/user/organization/${environment.organizationId}/${userId}`);
	}

	/**
	 * Exports all users to a CSV file.
	 *
	 * @param filename The filename to use for the CSV export.
	 */
	exportToCsv(filename: string): void {
		this.getAll()
			.pipe(first())
			.subscribe((users: User[]) => {
				let content = 'First name,Last name,Email,Agency/Normal,Role,Status' + '\r\n' + '\r\n';

				users.forEach((user: User) => {
					content +=
						`${user.profile.nameFirst},${user.profile.nameLast},${user.email},${user.agencyUser ? 'Agency' : 'Normal'},${
							user.role
						},${user.deactivated ? 'Deactivated' : 'Activated'}` + '\r\n';
				});

				const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
				saveAs(blob, filename);
			});
	}
}

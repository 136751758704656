<ngx-extended-pdf-viewer
	*ngIf="encodedUrl"
     [src]="encodedUrl"
     [height]="'65vh'"
	 [showHandToolButton]="true"
	 [listenToURL]="true"
	 [showToolbar]="true"
	 [showBorders]="true"
	[showSidebarButton]="false"
	[showFindButton]="true"
	[showPagingButtons]="true"
	[showDrawEditor]="annotationMode"
	[showStampEditor]="false"
	[showTextEditor]="false"
	[showZoomButtons]="true"
	[showPresentationModeButton]="true"
	[showOpenFileButton]="false"
	[showPrintButton]="false"
	[showDownloadButton]="false"
	[showSecondaryToolbarButton]="false"
	[showRotateButton]="false"
	[showHandToolButton]="false"
	[showScrollingButton]="false"
	[showSpreadButton]="false"
	[showPropertiesButton]="false"
	(annotationLayerRendered)="onAnnotationLayerRendered($event)"
></ngx-extended-pdf-viewer>

import { Pipe, PipeTransform } from '@angular/core';
import { PublicFile, PublicFileVersion } from '../../../../../api/src/file/file.entity';

@Pipe({
  name: 'unwindLastFileVersion',
})
export class UnwindLastFileVersionPipe implements PipeTransform {
  constructor() {}

  transform(file: PublicFile): PublicFile | PublicFileVersion {
    if (!file) {
      return;
    }
    if (file?.versions?.length > 0) {
      const parentFile = file;
      file = file.versions[0] as PublicFile;
      file = {
        ...file,
        approvers: parentFile.approvers,
        reviewers: parentFile.reviewers,
      };
      return file;
    }
    return file;
  }
}

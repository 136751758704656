import { v4 as uuidv4 } from 'uuid';
import { Entity } from '../entities.model';
import { Offer } from '../offer/offer.model';

/**
 * Measurement model
 * A measurement entity is a type and value that represents a performance metric of some kind for a tactic.
 * (eg. Impressions, CPC, ROAS, etc.)
 */
export interface Measurement {
	id: number | string;
	measurementType: MeasurementType;
	tacticId: string;
	benchmark?: string;
	value: string;
	created: string;
	offer?: Offer;
}

/**
 * Measurements are grouped by type.  Types are provided by the settings endpoint and configured in the CMS.
 */
export interface MeasurementType extends Entity {
	unit: MeasurementUnit;
	aggregations?: any;
}

/**
 * Each measurement type has a unit.  Units describe how to mask the values provided.
 * (eg. '%', '$', etc.)
 */
export type MeasurementUnit = 'currency' | 'integer' | 'float';

/**
 * Create a blank measurement.  Insert any default values here.
 */
export function createMeasurement(params: Partial<Measurement>) {
	return {
		id: uuidv4(),
		measurementTypeId: undefined,
		tacticId: undefined,
		benchmark: undefined,
		value: undefined,
		...params
	} as Measurement;
}

export enum MeasurementAggregateType {
	Actual = 'actual',
	Benchmark = 'benchmark'
}

<div [formGroup]="formGroup" *ngIf="formGroup">
	<p class="toggle-title mt-2">{{ filter?.name }}</p>
	<mat-button-toggle-group [formControlName]="filter.slug" class="mb-2">
		<mat-button-toggle [value]="entity.value" *ngFor="let entity of filter.options"
		(click)="showMultiSelect(entity.value)">{{ entity.name }}</mat-button-toggle>
	</mat-button-toggle-group>

	<angular2-multiselect
		class="mt-2 mb-4"
		*ngIf="showSelect"
		[formControlName]="controlSelectlName"
		[data]="dropdownList"
		[(ngModel)]="selectedItems"
		[settings]="dropdownSettings"
		(onSelect)="onItemSelect($event)"
		(onDeSelect)="OnItemDeSelect($event)"
		(onSelectAll)="onSelectAll($event)"
		(onDeSelectAll)="onDeSelectAll($event)">
	</angular2-multiselect>
</div>



import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Cost } from './cost.model';

export interface CostState extends EntityState<Cost> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cost' })
export class CostStore extends EntityStore<CostState> {
	constructor() {
		super();
	}
}

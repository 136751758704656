import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalSettings } from '../../../../state/global/global.model';
import { GlobalQuery } from '../../../../state/global/global.query';
import { Milestones } from '../milestones.model';
import { Observable } from 'rxjs';
import { Vendor } from '../../vendor/vendor.model';
import { Tactic } from '../../tactic/tactic.model';
import { Program } from '../../program/program.model';

@Component({
  selector: 'app-milestone-list',
  templateUrl: './milestone-list.component.html',
  styleUrls: ['./milestone-list.component.scss']
})
export class MilestoneListComponent implements OnInit {
	@Input() milestones: Milestones[];
	@Input() tactic: Tactic;
	@Input() program: Program;
	@Input() plannedCostTotal: number;
	@Input() vendors: Vendor[];
	@Input() viewOnly?: boolean;

	@Output() clicked: EventEmitter<Milestones> = new EventEmitter();
	@Output() updated: EventEmitter<Partial<Milestones>> = new EventEmitter();
	@Output() removed: EventEmitter<Milestones> = new EventEmitter();
	@Output() created: EventEmitter<Milestones> = new EventEmitter();

	public settings$: Observable<GlobalSettings>;

	constructor(private readonly globalQuery: GlobalQuery) {
		this.settings$ = this.globalQuery.select('settings');
	}

	ngOnInit() {
		console.warn("MILESTONES LIST", this.milestones)
	}

	create(milestone: Milestones) {
		this.created.emit(milestone);
	}

	update(milestone: Partial<Milestones>) {
		this.updated.emit(milestone);
	}

	remove(milestone: Milestones) {
		this.removed.emit(milestone);
	}
}

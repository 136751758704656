import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { arrayRemove, filterNilValue } from '@datorama/akita';
import { sortBy, uniqBy } from 'lodash';
import { resolveDotNotationPath } from '../../../_core/utils/object.utils';
import { AppSection, GlobalSettings } from '../../global/global.model';
import { GlobalQuery } from '../../global/global.query';
import { InvoiceFilterCollection } from '../invoice/invoice.model';
import { PlanFilterCollection } from '../plan/plan.model';
import { Category, ProgramFilterCollection } from '../program/program.model';
import { TacticFilterCollection } from '../tactic/tactic.model';
import {
	ActivateFilterCollection,
	Filter,
	FilterParameters,
	KimberlyClarkFilterCollection,
	MediaPlanningFilterCollection,
	OverallFilterCollection,
	PlanningFilterCollection,
} from './filter.model';
import { FilterQuery } from './filter.query';
import { FilterStore } from './filter.store';
import { take } from 'rxjs/operators';
import { FileCategories } from '../file/file.model';
import { PanelCategories } from '../../../_core/config/panels.data';
import { isArray } from 'class-validator';
import { getSectionConfig } from '../../overview/overview.utils';

/**
 * Filter Service
 * This service is responsible for managing the filter state in Akita.
 */
@Injectable({ providedIn: 'root' })
export class FilterService {
	public filterValues = {};
	constructor(private filterStore: FilterStore, private filterQuery: FilterQuery, private globalQuery: GlobalQuery) {
		// Set up the main collections in Akita from the beginning.
		this.globalQuery.authenticatedSettings$.pipe(filterNilValue(), take(1)).subscribe((settings) => {
			filterStore.set(
				this.maskFilterLabels([
					...OverallFilterCollection,
					...PlanningFilterCollection,
					...ActivateFilterCollection,
					...MediaPlanningFilterCollection,
					...PlanFilterCollection,
					...ProgramFilterCollection,
					...TacticFilterCollection,
					...InvoiceFilterCollection,
					...KimberlyClarkFilterCollection,
					// ...OverallFilterCollection.filter(filter => ['retailers', 'brands', 'budgetPeriod'].includes(filter.slug)),
					// ...ProgramFilterCollection
				] as Filter[])
			);
		});
	}

	getFiltersByAppSection(filters: Filter[], section: AppSection) {
		return filters.filter((f) => {
			return getSectionConfig(section)
				.filters.map((f) => f.id)
				.includes(f.id);
		});
	}

	/**
	 * Return a form builder object with controls for all fo the filters in Akita
	 */
	getFilterFormObject(controlOverrides: any = {}, filters?: Filter[]) {
		let filterFields = {};
		if (!filters) {
			filters = this.filterQuery.getAll();
		}

		filters.forEach((f) => {
			// Each filter type can have a different configuration for the form.
			filterFields = {
				...filterFields,
				...this.getFilterValue(f),
			};
		});

		// Filter out any undefined values or empty arrays from overrides.
		Object.keys(controlOverrides).forEach((key) => {
			if (controlOverrides[key] !== null || (isArray(controlOverrides[key]) && controlOverrides[key].length > 0)) {
				// If the override is an array, add an extra array around it so the form builder can handle it.
				if (isArray(controlOverrides[key])) {
					filterFields[key] = [controlOverrides[key]];
				} else {
					filterFields[key] = controlOverrides[key];
				}
			}
		});

		return {
			...filterFields,
			sort: [{}],
		};
	}

	getFilterValue(filter: Filter) {
		const filterFields = {};

		// There is no default option because we want form types to be present all of the time.
		switch (filter.type) {
			// String based inputs
			case 'search':
			case 'text':
			case 'textarea':
			case 'percentage':
				filterFields[filter.slug] = [filter.value || ''];
				break;

			// Array based inputs
			case 'multi-select':
			case 'single-select':
			case 'single-select-with-groups':
				filterFields[filter.slug] = [filter.value ? [filter.value] : []];
				break;

			// Date based inputs
			case 'date-range':
				filterFields[`${filter.extra?.prepend || ''}start${filter.extra?.append || ''}`] = [];
				filterFields[`${filter.extra?.prepend || ''}end${filter.extra?.append || ''}`] = [];
				break;
			case 'date':
				filterFields[filter.slug] = filter.value || undefined;
				break;

			// Boolean based inputs
			case 'toggle':
				filterFields[filter.slug] = filter.value || false;
				break;

			case 'toggle-chip':
				filterFields[filter.slug] = filter.value || undefined;
				break;

			case 'toggle-multi-select':
				filterFields[filter.slug] = filter.value || undefined;
				filterFields[filter.extra.controlSelectlName] = this.getFilterCategory(filter.extra.category) || [];
				break;
		}

		return filterFields;
	}

	getFilterCategory(category: string) {
		switch (category) {
			case Category.FileCategory:
				return FileCategories;

			case Category.PanelLocationLayoutCodes:
				return PanelCategories;
		}
	}

	/**
	 * Return an object of filters and default values that have been extracted from a url params object
	 */
	getFilterParametersFromURLParams(params: Params, leaveUnparsed: boolean = false) {
		const obj = {};
		const filters = this.filterQuery.getAll();
		// console.log('getFilterParametersFromURLParams', filters);

		Object.entries(params).forEach(([key, val]) => {
			if (val) {
				const filter = filters.find((f) => f.slug === key);
				// console.log('getFilterParametersFromURLParams: filter', filter);

				// Get all the possible options (including if there are duplicate filters for this slug)
				const possibleOptions = filters
					.filter((f) => f.slug === key)
					.map((f) => f.options)
					.reduce((acc, val) => acc.concat(val), []);
				let ids: any[];

				// console.log('getFilterParametersFromURLParams: possibleOptions', possibleOptions);

				if (filter) {
					if (filter.type === 'single-select') {
						ids = [val];
						obj[key] = possibleOptions?.find((option) => ids.indexOf(option?.id) > -1);
						// console.log('getFilterParametersFromURLParams: single-select', ids, obj[key]);
					} else if (filter.type === 'multi-select') {
						switch (key) {
							case 'tags':
							case 'authors':
							case 'owners':
								if (!Array.isArray(val)) {
									//To allow old tags to be shown and not break the application
									//it will allow the user to remove them, and then add them again with the new structure
									obj[key] = [...val.split(',')];
								} else {
									obj[key] = val;
								}
								break;

							default:
								ids = val.split(',');
								obj[key] = ids?.map((id) => possibleOptions?.find((option) => option?.id === id));
								break;
						}

						// console.log('getFilterParametersFromURLParams: multi-select', obj[key]);
					} else {
						// console.log('getFilterParametersFromURLParams: other', obj[key]);
						obj[key] = val;
					}
				} else {
					if (leaveUnparsed) {
						obj[key] = val;
					} else {
						console.warn(`getFilterParametersFromURLParams: Couldn't find a filter for ${key}: ${val}`);
					}
				}
			}
		});

		return obj;
	}

	/**
	 * Set which filters are currently 'active' in the system.
	 */
	setActive(ids: Filter['id'][]) {
		this.filterStore.update({
			active: ids,
		});
	}

	/**
	 * Set all available filters as 'active' in the system.
	 */
	setAllActive() {
		this.filterStore.update({
			active: this.filterQuery.getAll()?.map((f) => f.id) || [],
		});
	}

	/**
	 * Remove a set of filters from being 'active' in the system.
	 */
	removeActive(ids: Filter['id'][]) {
		this.filterStore.update({
			active: arrayRemove(this.filterQuery.getValue().active, ids),
		});
	}

	/**
	 * Inject the default options for a bunch of filtesr based on the settings object
	 * that we receive from the API.
	 */
	setFilterOptionsFromSettings(settings: GlobalSettings) {
		// console.log('Set Filter Options', settings);
		if (settings) {
			const noneOption = {
				id: undefined,
				name: '(None)',
				value: undefined,
			};

			this.setFilterOptions('workflowStatus', [
				noneOption,
				{ id: 'draft', name: 'Draft', value: 'draft' },
				{ id: 'approved', name: 'Approved', value: 'approved' },
			]);
			this.setFilterOptions('workflowStatuses', [
				noneOption,
				{ id: 'draft', name: 'Draft', value: 'draft' },
				{ id: 'approved', name: 'Approved', value: 'approved' },
			]);
			this.setFilterOptions('retailers', settings.retailers);
			this.setFilterOptions('agencies', settings.agencies);
			this.setFilterOptions('budgetPeriod', sortBy(settings.budgetPeriods, 'name'));
			this.setFilterOptions('budgetPeriods', sortBy(settings.budgetPeriods, 'name'));
			this.setFilterOptions('brands', settings.brands);
			this.setFilterOptions('vendors', settings.vendor);
			this.setFilterOptions('brandInitiatives', settings.brandInitiatives);
			this.setFilterOptions('programSector', [noneOption, ...settings.programSectors]);
			this.setFilterOptions('programSectors', [noneOption, ...settings.programSectors]);
			this.setFilterOptions('programPhase', [noneOption, ...settings.programPhases]);
			this.setFilterOptions('programPhases', [...settings.programPhases]);
			this.setFilterOptions('tacticType', [noneOption, ...settings.tacticTypes]);
			this.setFilterOptions('tacticTypes', [...settings.tacticTypes]);
			this.setFilterOptions('fundingSource', [noneOption, ...settings.fundingSources]);
			this.setFilterOptions('fundingSources', [noneOption, ...settings.fundingSources]);
			this.setFilterOptions('tacticCategory', [
				noneOption,
				...sortBy(
					uniqBy(
						settings.tacticTypes.map((type) => type.tacticCategory),
						'name'
					),
					'name'
				),
			]);
			this.setFilterOptions('tacticCategories', [
				...sortBy(
					uniqBy(
						settings.tacticTypes.map((type) => type.tacticCategory),
						'name'
					),
					'name'
				),
			]);
			this.setFilterOptions('costTypes', [noneOption, ...settings.costTypes]);

			this.setFilterOptions('mediaType', [
				noneOption,
				...sortBy(uniqBy(Object.entries(settings.mediaTypes).map(([name, value]) => ({ name, value })))),
			]);
			this.setFilterOptions('tacticPhase', [noneOption, ...settings.tacticPhases]);
			this.setFilterOptions('programTypes', [noneOption, ...settings.programTypes]);
		}
	}

	/**
	 * Find a filter by slug and update it's options in the Akita store.
	 */
	setFilterOptions(slug: Filter['slug'], options: Filter['options']) {
		const filters = this.filterQuery.getAll().filter((filter) => filter.slug === slug);

		filters.forEach((filter) => {
			this.filterStore.update(filter.id, {
				...filter,
				options: [...options],
			});
		});
	}

	/**
	 * Generate URL parameters from the inputted filter parameters.
	 */
	getURLParamsFromFilterParameters(params: FilterParameters): Params {
		const obj = {};
		console.log('Params to URLParams', params);

		Object.entries(params).forEach(([key, value]) => {
			if (value !== null) {
				if (Array.isArray(value)) {
					console.log('Array', value);
					value = value.map((v) => v.id).join(',');
				} else if (typeof value === 'object') {
					console.log('Object', value);
					value = value.id;
				} else {
					console.log('Other', value);
				}

				obj[key] = value;
			}
		});

		return obj;
	}

	/**
	 * Iterate through filters and mask any filters or options with a mask path property
	 * @param filters
	 * @returns
	 */
	maskFilterLabels(filters: Filter[]): Filter[] {
		return filters.map((filter) => {
			const _filter = {
				...filter,
			};

			// Mask names for any filter with a mask path
			if (filter.extra?.maskPath) {
				_filter.name =
					resolveDotNotationPath(filter.extra.maskPath, this.globalQuery.getValue().settings?.settings?.entities) || filter.name;
			}

			// Mask option names for any filter with a mask path
			if (filter.options) {
				_filter.options = filter.options.map((option) => {
					if (option.extra?.maskPath) {
						return {
							...option,
							name:
								resolveDotNotationPath(option.extra.maskPath, this.globalQuery.getValue().settings?.settings?.entities) ||
								option.name,
						};
					}

					return option;
				});
			}

			return _filter;
		});
	}

	setFilterOptionsAsSelected(slug: string, ids: any[]) {
		console.log(ids);
		ids = ids.filter((a) => a).map((a) => a.id);

		console.log('setFilterOptionsAsSelected', ids);
		const filter = this.filterQuery.getAll().find((f) => f.slug === slug);

		if (filter) {
			const resetOptions = filter.options.map((option) => ({
				...option,
				extra: {
					...option.extra,
					selected: false,
				},
			}));

			const updatedOptions = resetOptions.map((option) => {
				if (ids.includes(option.id)) {
					return {
						...option,
						extra: {
							...option.extra,
							selected: true,
						},
					};
				} else {
					return option;
				}
			});

			this.filterStore.update(filter.id, { options: updatedOptions });
		}

		const filterUpdated = this.filterQuery.getAll().find((f) => f.slug === slug);
	}
}

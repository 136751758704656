import { groupBy } from 'lodash';
import { resolveDotNotationPath } from './object.utils';

/**
 * Move an item in an array to a new position
 * @param arr
 * @param oldIndex
 * @param newIndex
 */
export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

/**
 * Pluck the first item from an array
 * @param arr
 */
export function pluckFirst(arr: any[]) {
  if (arr && arr.length > 0) {
    return arr[0];
  } else {
    return undefined;
  }
}

export enum ReduceOperation {
  JOIN = 'join',
  COUNT = 'count',
  SUM = 'sum',
  AVERAGE = 'average',
  FIRST = 'first',
}

export function pluckFromArray(
  input: any[],
  aggregatePath: string,
  aggregateFunction: string,
  filterPath?: string,
  filterValue?: string
): unknown {
  if (input?.length) {
    let inputArray = input;

    if (!!filterPath && !!filterValue) {
      inputArray = input.filter((item) => resolveDotNotationPath(filterPath, item) === filterValue);

      // If we've found no items, return undefined
      if (inputArray?.length < 1) {
        return undefined;
      }
    }

    switch (aggregateFunction) {
      case ReduceOperation.JOIN: {
        return inputArray.map((d) => d[aggregatePath]).join(', ');
      }
      case ReduceOperation.COUNT: {
        return inputArray?.length;
      }
      case ReduceOperation.SUM: {
        return inputArray.reduce((sum, curr) => sum + +curr[aggregatePath], 0);
      }
      case ReduceOperation.AVERAGE: {
        const sum = inputArray.reduce((sum, curr) => sum + +curr[aggregatePath], 0);
        return sum / inputArray?.length || 0;
      }
      case ReduceOperation.FIRST: {
        return inputArray?.length > 0 ? inputArray[0][aggregatePath] : [];
      }
      default: {
        return undefined;
      }
    }
  }

  return undefined;
}

/**
 * Groups items into arrays based on an iterator function
 * @param items
 * @param iterator
 */
export function groupEntities(items: any, iterator: ((item: any) => string) | string) {
  const arr = [];
  const obj = groupBy(items, iterator);

  Object.keys(obj).forEach((key) => {
    arr.push({
      name: key,
      items: obj[key],
    });
  });

  return arr;
}

/**
 * Join nested array values together with a custom separator
 */
export function joinWithProp(
  input: any[],
  path: string = 'name',
  seperator: string = ', ',
  limit?: number,
  limitAppendSingle?: string,
  limitAppendPlural?: string
): string {
  if (Array.isArray(input)) {
    if (!limit) {
      return input.map((d) => resolveDotNotationPath(path, d)).join(seperator);
    } else {
      const response = input
        .filter((d, i) => i < limit)
        .map((d) => resolveDotNotationPath(path, d))
        .join(seperator);
      let limitMsg = '';
      let limitAppendMsg = '';

      if (input.length > limit) {
        limitMsg = ` & ${input.length - limit} more`;

        if (input.length - limit > 1) {
          limitAppendMsg = ' ' + (limitAppendPlural || limitAppendSingle || '');
        } else if (limitAppendSingle) {
          limitAppendMsg = ' ' + (limitAppendSingle || '');
        }
      }

      return `${response}${limitMsg}${limitAppendMsg || ''}`;
    }
  } else {
    return '';
  }
}

/**
 * Returns a unique array based on a common property
 * @param arr
 * @param fn
 */
export function uniqWith(arr, fn) {
  return arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index);
}

/**
 * Returns a unique array based on a common property
 * @param array
 * @param key
 */
export function uniqueArray(array, key): any[] {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
}

export function sortArrayBy<T>(array: T[], property: string, type: string = 'asc'): T[] {
  if (type === 'desc') {
    return array.concat().sort((a, b) => b[property] - a[property]);
  } else {
    return array.concat().sort((a, b) => a[property] - b[property]);
  }
}

export function sortArrayByLocaleCompare<T>(array: T[], property: string, type: string = 'asc'): T[] {
  if (type === 'desc') {
    return array.concat().sort((a, b) => b[property].localeCompare(a[property]));
  } else {
    return array.concat().sort((a, b) => a[property].localeCompare(b[property]));
  }
}

export function areArraysEquivalent(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const countMap: { [key: string]: number } = {};

  for (const item of arr1) {
    countMap[item] = (countMap[item] || 0) + 1;
  }

  for (const item of arr2) {
    if (!countMap[item]) return false;
    countMap[item]--;
  }

  return true;
}

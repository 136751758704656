<div class="section-group mb-5">
	<section class="white stretch font-size-medium" fxLayout="column">
		<div class="title-row">
			<div class="group left">
				<h3 class="title">Strategic Brief</h3>
			</div>
			<div class="group right" *ngIf="!exportView">
				<button mat-flat-button class="button-extra-small icon-left" (click)="updateClicked.emit()">
					<i class="uil-document-info"></i> Update brief
				</button>
			</div>
		</div>

		<app-strategic-brief-view-default
			[program]="program"
			[exportView]="exportView"
			*ngIf="!program?.programStrategicBrief?.type || program?.programStrategicBrief?.type === StrategicBriefType.DEFAULT"
			(dataSubscribed)="dataSubscribed.emit()"
		></app-strategic-brief-view-default>
	</section>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { getMilestonesStatusColor, Milestones } from '../milestones.model';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-milestone-card',
	templateUrl: './milestone-card.component.html',
	styleUrls: ['./milestone-card.component.scss'],
})
export class MilestoneCardComponent implements OnInit {
	@Input() milestone: Milestones;
	@Input() viewOnly?: boolean;

	@Output() removed: EventEmitter<Milestones> = new EventEmitter();
	@Output() clicked: EventEmitter<Milestones> = new EventEmitter();

	public getMilestonesStatusColor = getMilestonesStatusColor;

	constructor(private readonly dialog: MatDialog) {}

	ngOnInit(): void {}

	removeModal(): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				entityName: 'Milestone',
				remove: true,
			},
			disableClose: false,
		});

		dialogRef
			.afterClosed()
			.pipe(first())
			.subscribe((result) => {
				if (result) {
					this._remove();
				}
			});
	}

	private _remove(): void {
		this.removed.emit(this.milestone);
	}
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[localVar]'
})
export class LocalVarDirective {

	@Input()set localVar(context: unknown) {
		this.context.$implicit = this.context.localVar = context;

		if (!this.hasView) {
			this.vcRef.createEmbeddedView(this.templateRef, this.context);
			this.hasView = true;
		}
	}

	private context: { $implicit: unknown; localVar: unknown; } = {
		$implicit: null,
		localVar: null,
	};

	private hasView = false;

	constructor(
		private templateRef: TemplateRef<any>,
		private vcRef: ViewContainerRef
	) {}

}

import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Retailer } from './retailer.model';

export interface RetailerState extends EntityState<Retailer> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'retailer' })
export class RetailerStore extends EntityStore<RetailerState> {
	constructor() {
		super();
	}
}

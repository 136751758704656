<div [formGroup]="form">
	<div class="font-size-large mb-5">Brief Details</div>

	<div class="input-group">
		<div class="grid-half full-width">
			<app-strategic-brief-dialog-multiselect
				label="Client Lead(s)"
				placeholder="Select your client lead(s)"
				id="clientLeads"
				[removeCb]="removeUserOrBrand.bind(this)"
				[selectCb]="userOrBrandSelected.bind(this)"
				[selected$]="selectedClientLeads$"
				[searchCtrl]="clientLeadsSearchCtrl"
				[filtered$]="filteredClientLeads$"
				[required]="true"
			></app-strategic-brief-dialog-multiselect>

			<app-strategic-brief-dialog-multiselect
				label="Account Lead(s)"
				placeholder="Select your account lead(s)"
				id="accountLeads"
				[removeCb]="removeUserOrBrand.bind(this)"
				[selectCb]="userOrBrandSelected.bind(this)"
				[selected$]="selectedAccountLeads$"
				[searchCtrl]="accountLeadsSearchCtrl"
				[filtered$]="filteredAccountLeads$"
				[required]="true"
			></app-strategic-brief-dialog-multiselect>
		</div>
	</div>

	<div class="input-group">
		<div class="column" fxLayout="column">
			<div class="font-size-small text-color-primary mb-1">Deliverable*</div>
			<div class="row" fxLayout="row" *ngIf="deliverables.length > 0; else noDeliverables">
				<div class="input mr-4" *ngFor="let deliverable of deliverables">
					<mat-checkbox [value]="deliverable.id" (change)="onDeliverableChange($event)"></mat-checkbox>
					{{ deliverable.name }}
				</div>
			</div>
			<ng-template #noDeliverables>
				<div class="font-size-medium">No deliverables available. Deliverables must be added in the Admin Settings</div>
			</ng-template>
		</div>
	</div>

	<div class="input-group">
		<div class="grid-half full-width">
			<app-strategic-brief-dialog-multiselect
				label="Primary Brand(s)"
				placeholder="Select your primary brand(s)"
				id="primaryBrands"
				[removeCb]="removeUserOrBrand.bind(this)"
				[selectCb]="userOrBrandSelected.bind(this)"
				[selected$]="selectedPrimaryBrands$"
				[searchCtrl]="primaryBrandsSearchCtrl"
				[filtered$]="filteredPrimaryBrands$"
				[required]="true"
			></app-strategic-brief-dialog-multiselect>

			<app-strategic-brief-dialog-multiselect
				label="Secondary Brand(s)"
				placeholder="Select your secondary brand(s)"
				id="secondaryBrands"
				[removeCb]="removeUserOrBrand.bind(this)"
				[selectCb]="userOrBrandSelected.bind(this)"
				[selected$]="selectedSecondaryBrands$"
				[searchCtrl]="secondaryBrandsSearchCtrl"
				[filtered$]="filteredSecondaryBrands$"
			></app-strategic-brief-dialog-multiselect>
		</div>
	</div>

	<div class="input-group">
		<div class="column" fxLayout="column">
			<div class="font-size-small text-color-primary mb-1">Retailer</div>
			<mat-chip style="display: block">{{ program?.retailer?.name }}</mat-chip>
		</div>

		<mat-form-field class="input ml-4">
			<mat-label>Live Dates</mat-label>
			<mat-date-range-input [rangePicker]="rangePicker" required placeholder="Select the live dates">
				<input matStartDate placeholder="Start date" formControlName="liveDatesStartDate" required />
				<input matEndDate placeholder="End date" formControlName="liveDatesEndDate" required />
			</mat-date-range-input>
			<mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
			<mat-date-range-picker #rangePicker>
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>Cancel</button>
					<button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
	</div>

	<div class="input-group">
		<mat-form-field class="input" fxFlex="100%">
			<mat-label>Budget</mat-label>
			<input matInput required autocomplete="off" placeholder="Enter your budget" [imask]="currencyMask" formControlName="budget" />
		</mat-form-field>
	</div>

	<div class="input-group">
		<div class="column" fxLayout="column" fxFlex="100%">
			<div class="font-size-small text-color-primary mb-2">Milestones</div>
		</div>
	</div>
	<app-strategic-brief-dialog-milestones
		[program]="program"
		(milestonesUpdated)="milestonesUpdated.emit($event)"
	></app-strategic-brief-dialog-milestones>

	<div *ngFor="let el of textAreaDataObjects">
		<div class="font-size-large mb-3 mt-5">{{ el.title }}</div>
		<div class="grid-half">
			<!-- <mat-form-field class="full-width" floatLabel="always" *ngFor="let textArea of el.textAreas">
				<mat-label>{{ textArea.label }}</mat-label>
				<textarea
					matInput
					rows="10"
					[placeholder]="textArea.placeholder"
					[formControlName]="textArea.controlName"
					[required]="textArea?.required"
				></textarea>
			</mat-form-field> -->
			<div *ngFor="let textArea of el.textAreas; let i = index">
				<div class="font-size-medium">{{ textArea.label }} {{ textArea?.required ? " *" : "" }}</div>
				<app-strategic-brief-text-editor
					[textValue]="form.get(textArea.controlName).value"
					[placeholder]="textArea.placeholder"
					[formControl]="form.get(textArea.controlName)"
				></app-strategic-brief-text-editor>
			</div>
		</div>
	</div>

	<app-strategic-brief-dialog-file-upload
		*ngIf="!loadingFiles; else showLoader"
		[fileIds]="form.value.files"
		[program]="program"
		(fileAdded)="onFileAdded($event)"
		(fileRemoved)="onFileRemoved($event)"
		[showActions]="true"
	></app-strategic-brief-dialog-file-upload>
</div>

<ng-template #showLoader>
	<div style="height: 200px; position: relative">
		<div class="loading">
			<mat-spinner [diameter]="40"></mat-spinner>
		</div>
	</div>
</ng-template>

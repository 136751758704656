import { Pipe, PipeTransform } from '@angular/core';
import { sortArrayByLocaleCompare } from '../../_core/utils/array.utils';
@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
	transform(records: Array<any>, args?: any): any {
		if (!records || !Array.isArray(records) || !records.length || !args) {
			return records;
		}

		let property;
		let direction;

		if (typeof args === 'string') {
			property = args;
			direction = 'asc';
		}

		if (typeof args === 'object') {
			property = args.property;
			direction = args.direction || 'asc';
		}

		return sortArrayByLocaleCompare(records, property, direction);
	}
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { GlobalService } from '../../../../state/global/global.service';
import { FileService } from '../file.service';
import { EditorAnnotation } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'app-file-viewer',
	templateUrl: './file-viewer.component.html',
	styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
	@Input() files: PublicFile[];
	@Input() maxH: boolean = false;
	@Input() viewerType: 'column' | 'gallery' = 'column';
	@Input() annotationMode: boolean = false;
	@Input() annotations: EditorAnnotation[] = [];
	@Output() annotationsChanged: EventEmitter<EditorAnnotation[]> = new EventEmitter<EditorAnnotation[]>();

	videoPath: string = '';

	constructor(private readonly fileService: FileService, private readonly globalService: GlobalService) {}

	ngOnInit(): void {
		if (this.files.length > 0 && this.isVideo(this.files[0])) {
			this.getTemporaryFileURL(this.files[0]);
		}
	}

	getViewerType(_file: PublicFile) {
		const file = { ..._file };
		file.mimeType = file?.converted?.mimeType || file.mimeType;
		file.path = file?.converted?.path || file.path;
		if (this.isImage(file)) {
			return 'image';
		} else if (this.isVideo(file)) {
			return 'video';
		} else {
			return this.fileService.viewerToUse(file.mimeType);
		}
	}

	isImage(file) {
		return this.fileService.isImage(file.mimeType);
	}

	isVideo(file) {
		return this.fileService.isVideo(file.mimeType);
	}

	getMimetype(mimetype: string): string {
		switch (mimetype) {
			case 'video/quicktime':
				return 'video/mp4';
			default:
				return mimetype;
		}
	}

	getTemporaryFileURL(file: PublicFile) {
		this.fileService.getTemporaryFileURL(file.path).subscribe(
			response => {
				this.videoPath = response.url;
			},
			(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
		);
	}
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PublicFile, PublicFileVersion } from '../../../../../../../api/src/file/file.entity';
import { Profile } from '../../../session/session.model';
import { SessionQuery } from '../../../session/session.query';
import { getInitialsFromString } from '../../../../_core/utils/string.utils';
import { fileApprovalOptions } from '../file.model';
import { Entity } from '../../entities.model';
import { FormControl, FormGroup } from '@angular/forms';
import { FileService } from '../file.service';
import { GlobalService } from '../../../global/global.service';
import { User } from '../../user/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FileReviewTypeEnum } from '../../../../../../../api/src/file/enums/file-review-type.enum';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PublicUser } from '../../../../../../../api/src/user/user.entity';
import { EditorAnnotation } from 'ngx-extended-pdf-viewer';
import { CommentStatus, PublicComment } from '../../../../../../../api/src/comment/comment.entity';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ObjectUtils } from '../../../../../../../api/src/_core/utils/utils.object';
import { UserRole } from '../../../global/global.model';

type PublicUserWithName = PublicUser & { name: string };
@Component({
	selector: 'app-file-review-comments-container',
	templateUrl: './file-review-comments-container.component.html',
	styleUrls: ['./file-review-comments-container.component.scss']
})
export class FileReviewCommentsContainerComponent implements OnInit, OnChanges, OnDestroy {
	@Input() file: PublicFile & PublicFileVersion;
	@Input() annotations: EditorAnnotation[] = [];
	@Output() annotationsCleared: EventEmitter<void> = new EventEmitter<void>();
	@Output() stateChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() activeCommentChanged: EventEmitter<PublicComment> = new EventEmitter<PublicComment>();

	readonly FileReviewTypeEnum = FileReviewTypeEnum;

	public state: 'list' | 'add' = 'list';
	public profile: Profile;
	public initials: string;
	public fileApprovalOptions: Entity[] = fileApprovalOptions;
	public form: FormGroup;
	public files: Array<any>;

	filteredUsers: PublicUserWithName[] = []; // Populate this array with your users

	public activeComment: PublicComment;
	public hoveredComment: PublicComment;

	public commentStatus = CommentStatus;

	private _unsubscribe: Subject<void> = new Subject();

	@ViewChild('commentBox') commentBoxRef: ElementRef;
	public mentionConfig = {
		maxItems: 10,
		labelKey: 'name',
		mentionSelect: (e: User) => '##' + e.nameFirst + ' ' + e.nameLast + '##'
	};
	readonly DEFAULT_TAG_CHAR = '@';

	timeouts: any[] = [];

	readonly UserRole = UserRole;

	constructor(
		private readonly sessionQuery: SessionQuery,
		private readonly fileService: FileService,
		private readonly globalService: GlobalService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.profile = this.sessionQuery.getProfile();

		this.initials = this.profile.email[0].toUpperCase() + this.profile.email[1].toUpperCase();

		this.form = new FormGroup({
			status: new FormControl(this.fileApprovalOptions[0]),
			taggedUsers: new FormControl([]),
			body: new FormControl('')
		});
	}

	getNameorEmail(user: User) {
		if (user.nameFirst) {
			return user.nameFirst + ' ' + user.nameLast;
		} else {
			return user.email;
		}
	}

	getInitials(user: User) {
		if (user.nameFirst) {
			return getInitialsFromString(user.nameFirst + ' ' + user.nameLast);
		} else {
			// Attempt to get initials from email
			return user.email[0].toUpperCase() + user.email[1].toUpperCase();
		}
	}

	getStatusName(status: string) {
		return this.fileApprovalOptions.find(option => option.value === status)?.name;
	}

	isApprover() {
		return this.file.approvers?.find(approver => approver.id === this.profile.id);
	}

	stateChange(state: 'list' | 'add') {
		this.state = state;
		this.activeComment = undefined;
		this.stateChanged.emit(state);
	}

	statusChange(event: any): void {
		this.form.patchValue({ status: event });
	}

	async create(status: CommentStatus) {
		if (status) {
			this.form.patchValue({ status });
		}

		const comment = await this.fileService
			.addFileComment(this.file as PublicFile, {
				status,
				body: this.form.value.body,
				annotations: this.annotations
			})
			.toPromise();

		const fileUploadRequests = [];
		for (const file of this.files ?? []) {
			file.program = this.file.program;
			fileUploadRequests.push(
				this.fileService
					.upload(file, comment.id, 'comment')
					.pipe(
						takeUntil(this._unsubscribe),
						catchError(err => {
							this.globalService.triggerErrorMessage(err);
							return throwError(err);
						})
					)
					.toPromise()
			);
		}
		const uploadedFileNotes = await Promise.all(fileUploadRequests);
		comment.commentFiles = uploadedFileNotes.map(x => x.body as PublicFile);

		this.fileService.updateFileCommentsState(this.file, comment);
		const taggedUsers = this.form.get('taggedUsers').value;
		this.fileService
			.sendMailForComment(comment.id, taggedUsers)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe(
				() => {},
				err => this.globalService.triggerErrorMessage(err)
			);

		this.form.reset({
			status: this.fileApprovalOptions[0],
			taggedUsers: [],
			body: ''
		});
		this.files = [];
		this.commentBoxRef.nativeElement.textContent = '';
	}

	download() {
		this.fileService
			.download(this.file.path)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe(
				() => {},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	addFileDialog() {
		const dialogRef = this.dialog.open(FileUploadDialogComponent, {
			data: {
				skipUpload: true,
				isCategoryIgnored: true
			},
			disableClose: true,
			panelClass: ['fullscreen', 'max-width-lg']
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this._unsubscribe))
			.subscribe(files => {
				this.files = files;
			});
	}

	onCommentHover(comment: PublicComment) {
		this.hoveredComment = comment;

		// Only bubble the event if we don't have a active comment.
		if (!this.activeComment) {
			this.activeCommentChanged.emit(comment);
		}
	}

	onCommentClick(comment: PublicComment) {
		if (this.activeComment && this.activeComment.id === comment.id) {
			this.activeComment = undefined;
		} else {
			this.activeComment = comment;
		}
		this.activeCommentChanged.emit(this.activeComment);
	}

	downloadCommentNote(path) {
		this.fileService
			.download(path)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe(
				x => console.log(`success`),
				err => {}
			);
	}

	unsetAnnotationMode() {
		// HACK: This is a hack to get the annotation mode to unset with pdf.js
		const el = document.getElementById('editorInk');
		if (el?.classList?.contains('toggled')) {
			el.click();
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
		this.timeouts.forEach(x => clearTimeout(x));
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.filteredUsers = ObjectUtils.uniqueArray(
			[...(this.file.approvers ?? []), ...(this.file.reviewers ?? [])].map((user: PublicUser) => {
				if (!user?.nameFirst || !user?.nameLast) {
					return { ...user, name: `${user.email}` };
				}
				return { ...user, name: `${user.nameFirst} ${user.nameLast}` };
			}),
			'id'
		) as PublicUserWithName[];
	}

	onTagUser(user: PublicUser): void {
		const existingTaggedUsers = this.form.get('taggedUsers')?.value;
		this.form.get('taggedUsers').patchValue(ObjectUtils.uniqueArray([...existingTaggedUsers, user], 'id'));
		this._makeTaggedUsersBold(user);
	}

	onCommentInputChange(event: any): void {
		this.timeouts.push(
			setTimeout(() => {
				const commentBoxContent = this.commentBoxRef.nativeElement.innerText;
				// Add/remove placeholder
				if (commentBoxContent.length > 0) {
					event.target.classList.remove('editable-placeholder');
				} else {
					event.target.classList.add('editable-placeholder');
				}

				if (commentBoxContent.length === 0) {
					this.form.get('taggedUsers').patchValue([]);
				}
				// Remove tagged users if not in comment box
				(this.form.get('taggedUsers').value as PublicUser[]).forEach(val => {
					if (!commentBoxContent.includes(val.nameFirst + ' ' + val.nameLast)) {
						this.form.get('taggedUsers').patchValue(
							(this.form.get('taggedUsers').value as PublicUser[]).filter(user => {
								return user.id !== val.id;
							})
						);
					}
				});

				this.form.get('body').patchValue(commentBoxContent);
			}, 10)
		);
	}

	isAlreadyMentioned = (user: PublicUser, taggedUsers: PublicUser[]) => !taggedUsers.some(value => value.id === user.id);

	private _makeTaggedUsersBold(e: PublicUser): void {
		this.timeouts.push(
			setTimeout(() => {
				const htmlDoc = this.commentBoxRef.nativeElement;
				htmlDoc.innerHTML = htmlDoc.innerHTML.replace(
					'##' + e.nameFirst + ' ' + e.nameLast + '##',
					' <b>' + this.DEFAULT_TAG_CHAR + e.nameFirst + ' ' + e.nameLast + '</b>&nbsp;'
				);

				// Now, directly implementing the logic of selectEnd here
				const range = document.createRange();
				range.selectNodeContents(htmlDoc);
				range.collapse(false);
				const selection = window.getSelection();
				selection.removeAllRanges();
				selection.addRange(range);
			}, 10)
		);
	}
}

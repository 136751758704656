<div mat-dialog-title fxLayout="row">
	<h1 *ngIf="!data.createMode">Milestone {{ data?.milestone?.name }}</h1>
	<h1 *ngIf="data.createMode">Create an Milestone</h1>

	<div class="actions margin-left-auto">
		<button mat-mini-fab aria-label="Add" class="button-extra-small" color="tertiary" (click)="dismiss()">
			<i class="uil-multiply"></i>
		</button>
	</div>
</div>

<div mat-dialog-content>
	<div class="section-group" fxLayout="column" fxFlexFill fxLayoutGap="30px">
		<section class="white content-row" fxFlex="100%" fxLayout="column">
			<div class="input-group">
				<mat-form-field class="input" fxFlex="50%">
					<mat-label>Milestone Name</mat-label>
					<input
						matInput
						placeholder="milestone name"
						name="milestone-name"
						[value]="data.milestone.name"
						(change)="update('name', $event)"
					/>
				</mat-form-field>
				<mat-form-field class="input" fxFlex="28%">
					<mat-label>Type</mat-label>
					<mat-select 
						required 
						[value]="data.milestone.type" 
						(selectionChange)="update('type', $event)"
						>
						<mat-option *ngFor="let type of types" [value]="type">{{ type | titlecase }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="input-group">
				
				<mat-form-field class="input" fxFlex="33%">
					<mat-label>Status</mat-label>
					<mat-select 
						required 
						[value]="data.milestone.status" 
						(selectionChange)="update('status', $event)"
						>
						<mat-option *ngFor="let status of statuses" [value]="status">{{ status | titlecase }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="input" fxFlex="28%">
					<mat-label>Start</mat-label>
					<input
						matInput
						[matDatepicker]="start"
						[value]="data.milestone.start"
						(dateChange)="update('start', $event)"
					/>
					<mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
					<mat-datepicker #start></mat-datepicker>
				</mat-form-field>
				<mat-form-field class="input" fxFlex="28%">
					<mat-label>End</mat-label>
					<input
						matInput
						[matDatepicker]="end"
						[value]="data.milestone.end"
						(dateChange)="update('end', $event)"
					/>
					<mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
					<mat-datepicker #end></mat-datepicker>
				</mat-form-field>

			</div>

			<div class="input-group">
				<mat-form-field class="input" fxFlex="100%">
					<mat-label>Milestone description</mat-label>
					<input
						matInput
						placeholder="milestone description"
						name="milestone-description"
						[value]="data.milestone.description"
						(change)="update('description', $event)"
					/>
				</mat-form-field>
			</div>

			<div class="content-row" *ngIf="data.createMode">
				<div class="group right">
					<button
						mat-flat-button
						class="button-small"
						[class.loading-spinner]="loading$ | async"
						(click)="create()"
						[disabled]="
							!isValid(data.milestone)"
					>
						Create
					</button>
				</div>
			</div>
		</section>

	</div>

import { PanelCategory, PanelContentModule } from '../../state/entities/panel/panel.model';

export const PanelTypes = [
	{
		id: 'frameURL',
		name: 'Iframe URL',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'powerBI',
		name: 'Power BI',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'looker',
		name: 'Looker Studio',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'tableau',
		name: 'Tableau',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'smartSheet',
		name: 'SmartSheet',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'smartSheetReport',
		name: 'SmartSheet',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'smartsheet',
		name: 'SmartSheet Report',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'embedCode',
		name: 'Embed Code',
		steps: [PanelContentModule.Metadata, PanelContentModule.EmbedCode],
	},
	{
		id: 'file',
		name: 'File',
		steps: [PanelContentModule.Metadata, PanelContentModule.FilePicker],
	},
	{
		id: 'group',
		name: 'Panel Group',
		steps: [PanelContentModule.Metadata],
	},
	{
		id: 'ccp',
		name: 'CCP Module',
		steps: [PanelContentModule.Metadata, PanelContentModule.CCP],
	},
];

/**
 * Panel category list of possible values.
 */
export const PanelCategories: PanelCategory[] = [
	{
		id: undefined,
		name: 'All',
	},
	{
		name: 'Details',
		id: 'programDetailsTab',
	},
	{
		name: 'Budget',
		id: 'programBudgetTab',
	},
	{
		name: 'Reporting',
		id: 'programReportingTab',
	},
	{
		name: 'Objectives',
		id: 'programObjectivesTab',
	},
];

import { Pipe, PipeTransform } from '@angular/core';
import { resolveDotNotationPath } from '../../_core/utils/object.utils';

@Pipe({
	name: 'resolveDotNotationPath',
})
export class ResolveDotNotationPathPipe implements PipeTransform {
	transform(path: string, obj: Object): any {
		return resolveDotNotationPath(path, obj);
	}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Program } from './../../../state/entities/program/program.model';
import { StrategicBriefTypeEnum } from '../../../../../../api/src/organization/data/strategic-brief.data';

@Component({
	selector: 'app-strategic-brief-view',
	templateUrl: 'strategic-brief-view.component.html',
	styleUrls: ['strategic-brief-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategicBriefViewComponent implements OnInit {
	@Input() program: Program;
	@Input() exportView?: boolean;
	@Output() updateClicked = new EventEmitter<void>();
	@Output() dataSubscribed = new EventEmitter<void>();

	public readonly StrategicBriefType = StrategicBriefTypeEnum;

	constructor() {}

	ngOnInit(): void {}
}

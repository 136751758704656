import { Query as ProgramQuery, ProgramSelect } from '../../program/utils/query.utils';

export enum InvestmentSelect {
	InvestmentType = 'investmentType',
	Program = 'program',
	Author = 'author',
}

export class Query {
	private static readonly INVESTMENT_QUERY_ALIAS: string = 'inv';

	public static getSelects(
		alias: string = this.INVESTMENT_QUERY_ALIAS,
		targets: InvestmentSelect[] = Object.values(InvestmentSelect),
		asJsonSelect: boolean = false,
		excludes: InvestmentSelect[] = []
	) {
		if (!targets) {
			targets = [];
		}
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'amount', ${alias}.amount` : `${alias}.amount`}
		`);
		selects.push(`
			${json ? `'programId', ${alias}."programId"` : `${alias}."programId"`}
		`);
		selects.push(`
			${json ? `'investmentTypeId', ${alias}."investmentTypeId"` : `${alias}."investmentTypeId"`}
		`);
		selects.push(`
			${json ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);

		if (targets.includes(InvestmentSelect.InvestmentType) && !excludes.includes(InvestmentSelect.InvestmentType)) {
			if (json) {
				selects.push(`
					'investmentType', ${alias}_investmenttype."investmentType"
				`);
			} else {
				selects.push(`
					${alias}_investmenttype."investmentType" AS "investmentType"
				`);
			}
		}

		if (targets.includes(InvestmentSelect.Author) && !excludes.includes(InvestmentSelect.Author)) {
			if (json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		if (targets.includes(InvestmentSelect.Program) && !excludes.includes(InvestmentSelect.Program)) {
			if (json) {
				selects.push(`
					'program', ${alias}_program.program
				`);
			} else {
				selects.push(`
					${alias}_program.program AS program
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.INVESTMENT_QUERY_ALIAS,
		targets: InvestmentSelect[] = Object.values(InvestmentSelect),
		excludes: InvestmentSelect[] = []
	) {
		if (!targets?.length) {
			return '';
		}

		const subQueries: string[] = [];

		if (targets.includes(InvestmentSelect.Program) && !excludes.includes(InvestmentSelect.Program)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSONB_BUILD_OBJECT (
							${ProgramQuery.getSelects('p2', [ProgramSelect.BudgetPeriod], true)}
						) AS "program"
					FROM
						"programs" AS p2
						${ProgramQuery.getSubqueries('p2', [ProgramSelect.BudgetPeriod])}
					WHERE
						p2.id = ${alias}."programId"
				) AS ${alias}_program
			`);
		}

		if (targets.includes(InvestmentSelect.InvestmentType) && !excludes.includes(InvestmentSelect.InvestmentType)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', invt.id,
								'name', invt.name,
								'tacticTypes', "tacticTypes"."tacticTypeArr"
							)
						FROM
							"investmentTypes" AS invt,
							LATERAL (
								SELECT ARRAY (
									SELECT
										JSONB_BUILD_OBJECT (
											'id', tt.id,
											'name', tt.name,
											'tacticCategoryId', tt."tacticCategoryId"
										) AS "tacticType"
									FROM
										"investmentTypeTacticTypes" AS invtt
									INNER JOIN
										"tacticTypes" AS tt
										ON
											tt.id = invtt."tacticTypesId"
									WHERE
										invtt."investmentTypesId" = invt.id
								) AS "tacticTypeArr"
							) AS "tacticTypes"
						WHERE
							invt.id = ${alias}."investmentTypeId"
					) AS "investmentType"
				) AS ${alias}_investmenttype
			`);
		}

		if (targets.includes(InvestmentSelect.Author) && !excludes.includes(InvestmentSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSONB_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}

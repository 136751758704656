import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDefined'
})
export class IsDefinedPipe implements PipeTransform {

  transform(value: any): boolean {
    return this.isDefined(value);
  }

  private isDefined(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string' && value.trim() === '') {
      return false;
    }
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }
    return true;
  }
}

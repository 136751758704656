import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataCache } from '../../../_core/utils/cache.utils';
import { environment } from '../../../../environments/environment';
import { Program } from '../program/program.model';
import { Tactic } from '../tactic/tactic.model';
import { PublicLocation } from '../../../../../../api/src/location/location.entity';

/**
 * Location Service
 * This service is responsible for location logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class LocationService {
	public cache: DataCache = new DataCache();

	constructor(private http: HttpClient) {}

	/**
	 * Return a list of tags based on a search query and an entity type.
	 */
	suggest(text: string, avoidCache?: boolean) {
		console.log('suggest', text);
		// Check if we've already made this API call in this session.  Use the cached result if so.
		if (this.cache.get(`location-${text}`) && !avoidCache) {
			return of(this.cache.get(`location-${text}`));
		}

		const endpoint = `${environment.apiUrl}/organization/${environment.organizationId}/location/suggest/?query=${text}`;

		return this.http.get<PublicLocation<any>[]>(endpoint).pipe(
			tap((response) => {
				this.cache.set(`location-${text}`, response);
			})
		);
	}
}

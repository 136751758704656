import { FileService } from './../../../../../state/entities/file/file.service';
import { UserService } from './../../../../../state/entities/user/user.service';
import { Deliverable } from './../../../../../state/entities/deliverable/models/deliverable.model';
import { Brand } from './../../../../../state/entities/brand/brand.model';
import { Program } from './../../../../../state/entities/program/program.model';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { currencyMask } from '../../../../../_core/utils/input-mask.utils';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { GlobalQuery } from '../../../../../state/global/global.query';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { first, map, startWith, switchMap } from 'rxjs/operators';
import { StrategicBriefDialogMultiselectComponent } from '../../common/multiselect/multiselect.component';
import { User } from './../../../../../state/entities/user/user.model';
import { FileQuery } from '../../../../../state/entities/file/file.query';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../../../../state/global/global.service';
import { StrategicBriefDefaultDataObject, strategicBriefDefaultDataObjects } from './strategic-brief-default.data';
import { Milestones } from '../../../../../state/entities/milestone/milestones.model';

type LeadControlName = 'clientLeads' | 'accountLeads';

type BrandControlName = 'primaryBrands' | 'secondaryBrands';

@Component({
	selector: 'app-strategic-brief-dialog-default',
	templateUrl: 'default.component.html',
	styleUrls: ['default.component.scss', '../../common/strategic-brief-dialog-common.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategicBriefDialogDefaultComponent implements OnInit {
	@Input() program: Program;

	@Output() milestonesUpdated = new EventEmitter<Milestones[]>();

	form: FormGroup;

	public readonly textAreaDataObjects: StrategicBriefDefaultDataObject[] = strategicBriefDefaultDataObjects;

	public readonly currencyMask = { ...currencyMask, lazy: true }; // placeholder will be visible
	brands: Brand[];
	users: User[];
	deliverables: Deliverable[];

	selectedClientLeads = new BehaviorSubject<User[]>([]);
	selectedClientLeads$: Observable<User[]> = this.selectedClientLeads.asObservable();
	clientLeadsSearchCtrl = new FormControl();
	filteredClientLeads$: Observable<User[]>;

	selectedAccountLeads = new BehaviorSubject<User[]>([]);
	selectedAccountLeads$: Observable<User[]> = this.selectedAccountLeads.asObservable();
	accountLeadsSearchCtrl = new FormControl();
	filteredAccountLeads$: Observable<User[]>;

	selectedPrimaryBrands = new BehaviorSubject<Brand[]>([]);
	selectedPrimaryBrands$: Observable<Brand[]> = this.selectedPrimaryBrands.asObservable();
	primaryBrandsSearchCtrl = new FormControl();
	filteredPrimaryBrands$: Observable<Brand[]>;

	selectedSecondaryBrands = new BehaviorSubject<Brand[]>([]);
	selectedSecondaryBrands$: Observable<Brand[]> = this.selectedSecondaryBrands.asObservable();
	secondaryBrandsSearchCtrl = new FormControl();
	filteredSecondaryBrands$: Observable<Brand[]>;

	@ViewChildren(StrategicBriefDialogMultiselectComponent) multiselectInputRefs: QueryList<StrategicBriefDialogMultiselectComponent>;
	@ViewChildren(MatCheckbox) deliverablesRef: QueryList<MatCheckbox>;

	loadingFiles = true;

	constructor(
		private readonly fb: FormBuilder,
		private readonly globalQuery: GlobalQuery,
		private readonly cdr: ChangeDetectorRef,
		private readonly userService: UserService,
		private readonly fileQuery: FileQuery,
		private readonly fileService: FileService,
		private readonly globalService: GlobalService
	) {
		this.brands = [];
		this.users = [];
		this.deliverables = [];
	}

	ngOnInit(): void {
		this._setUpForm();
		this._getLists();
		this._getProgramFiles();
	}

	get keyMilestones(): FormArray {
		return this.form.controls['keyMilestones'] as FormArray;
	}

	get formValid(): boolean {
		const requiredArrays = ['clientLeads', 'accountLeads', 'deliverables'];
		return this.form.valid && requiredArrays.every((array) => this.form.get(array).value.length > 0);
	}

	// Files

	onFileAdded(fileId: string): void {
		const files = [...this.form.value.files, fileId];
		this.form.get('files').setValue(files);
	}

	onFileRemoved(fileId: string): void {
		const files = [...this.form.value.files].filter((id) => id !== fileId);
		this.form.get('files').setValue(files);
	}

	// Milestones

	onRemoveMilestone(index: number): void {
		this.keyMilestones.removeAt(index);
	}

	onAddMilestone(): void {
		this.keyMilestones.push(this._milestoneFormGroup());
	}

	// Deliverables

	onDeliverableChange(event: MatCheckboxChange): void {
		const id = event.source.value;
		let current = [...this.form.get('deliverables').value];
		const index = current.indexOf(id);
		if (index >= 0) {
			current.splice(index, 1);
		} else {
			current = [...current, id];
		}
		this.form.get('deliverables').setValue(current);
	}

	// Users, Brands

	userOrBrandSelected(event: MatAutocompleteSelectedEvent, controlName: LeadControlName | BrandControlName): void {
		const id = event.option.value.id;
		let current = [...this.form.get(controlName).value];
		if (!current.includes(id)) {
			current = [...current, id];
			this.form.get(controlName).setValue(current);
			controlName === 'clientLeads' || controlName === 'accountLeads'
				? this._updateUsers(controlName)
				: this._updateBrands(controlName);
			this._resetSearch(controlName);
		}
	}

	removeUserOrBrand(item: User | Brand, controlName: LeadControlName | BrandControlName): void {
		const items = [...this.form.get(controlName).value];
		const index = items.indexOf(item.id);
		if (index >= 0) {
			items.splice(index, 1);
			this.form.get(controlName).setValue(items);
			controlName === 'clientLeads' || controlName === 'accountLeads'
				? this._updateUsers(controlName)
				: this._updateBrands(controlName);
		}
	}

	private _setUpForm(): void {
		const data = this.program?.programStrategicBrief?.data;
		console.log('%c data', 'color: #cc0033', data);
		/* const milestonesArray = this.fb.array(
      (data?.keyMilestones || [{}]).map((milestone) => this._milestoneFormGroup(milestone?.name, milestone?.date))
    ); */
		const form = this.fb.group({
			clientLeads: [data?.clientLeads || [], Validators.required],
			accountLeads: [data?.accountLeads || [], Validators.required],
			deliverables: [data?.deliverables || [], Validators.required],
			primaryBrands: [data?.primaryBrands || [], Validators.required],
			secondaryBrands: [data?.secondaryBrands || []],
			liveDatesStartDate: [data?.liveDatesStartDate, Validators.required],
			liveDatesEndDate: [data?.liveDatesEndDate, Validators.required],
			budget: [data?.budget, Validators.required],
			// keyMilestones: milestonesArray,
			files: [data?.files || []],
		});

		this.textAreaDataObjects.forEach((textareaObject) => {
			textareaObject.textAreas.forEach((textArea) => {
				form.addControl(
					textArea.controlName,
					this.fb.control(data?.[textArea.controlName], textArea?.required ? Validators.required : null)
				);
			});
		});

		this.form = form;
	}

	private _getLists(): void {
		const settings$ = this.globalQuery.authenticatedSettings$.pipe(first());
		const users$ = this.userService.getAll();

		forkJoin([settings$, users$]).subscribe(([settings, users]) => {
			this.users = users;
			this.brands = settings?.brands || [];
			this._setFilteredUsers();
			this._setFilteredBrands();
			this.deliverables = settings?.deliverables || [];
			setTimeout(() => {
				this.deliverablesRef.toArray().forEach((checkbox) => {
					const id = checkbox.value;
					checkbox.checked = this.form.value.deliverables.includes(id);
				});
			});
			this.cdr.markForCheck();
		});
	}

	private _milestoneFormGroup(name?: string, date?: string): FormGroup {
		return this.fb.group({
			name: [name, Validators.required],
			date: [date, Validators.required],
		});
	}

	// Users

	private _updateUsers(controlName: LeadControlName): void {
		const subject = controlName === 'clientLeads' ? this.selectedClientLeads : this.selectedAccountLeads;
		const users = this.form.get(controlName).value;
		const selected = this.users.filter((user) => users.includes(user.id));
		subject.next(selected);
	}

	private _filterUsers(term: string, controlName: LeadControlName): User[] {
		return this.users.filter((user) => {
			if (!user?.profile?.nameFirst || !user?.profile?.nameLast) return false;

			const firstName = user.profile.nameFirst.toLowerCase();
			const lastName = user.profile.nameLast.toLowerCase();
			const agencyCond = controlName === 'accountLeads' ? user.agencyUser : !user.agencyUser;
			const searchTerm = !term || typeof term !== 'string' ? '' : term.toLowerCase();
			return `${firstName} ${lastName}`.includes(searchTerm) && agencyCond;
		});
	}

	private _setFilteredUsers(): void {
		this.filteredClientLeads$ = this.clientLeadsSearchCtrl.valueChanges.pipe(
			startWith(''),
			map((term: string) => this._filterUsers(term, 'clientLeads')),
			switchMap((filtered) => this.selectedClientLeads$.pipe(map((selected) => filtered.filter((user) => !selected.includes(user)))))
		);
		this.filteredAccountLeads$ = this.accountLeadsSearchCtrl.valueChanges.pipe(
			startWith(''),
			map((term: string) => this._filterUsers(term, 'accountLeads')),
			switchMap((filtered) => this.selectedAccountLeads$.pipe(map((selected) => filtered.filter((user) => !selected.includes(user)))))
		);
		(this.form.value?.clientLeads || []).forEach((id) => {
			const user = this.users.find((u) => u.id === id);
			if (user) this.selectedClientLeads.next([...this.selectedClientLeads.value, user]);
		});
		(this.form.value?.accountLeads || []).forEach((id) => {
			const user = this.users.find((u) => u.id === id);
			if (user) this.selectedAccountLeads.next([...this.selectedAccountLeads.value, user]);
		});
	}

	// Brands

	private _updateBrands(controlName: BrandControlName): void {
		const subject = controlName === 'primaryBrands' ? this.selectedPrimaryBrands : this.selectedSecondaryBrands;
		const brands = this.form.get(controlName).value;
		const selected = this.brands.filter((brand) => brands.includes(brand.id));
		subject.next(selected);
	}

	private _setFilteredBrands(): void {
		this.filteredPrimaryBrands$ = this.primaryBrandsSearchCtrl.valueChanges.pipe(
			startWith(''),
			map((term: string) => this._filterBrands(term, 'primaryBrands')),
			switchMap((filtered) =>
				combineLatest([this.selectedPrimaryBrands$, this.selectedSecondaryBrands$]).pipe(
					map(([selectedPrimaryBrands, selectedSecondaryBrands]) =>
						filtered.filter((brand) => !selectedPrimaryBrands.includes(brand) && !selectedSecondaryBrands.includes(brand))
					)
				)
			)
		);
		this.filteredSecondaryBrands$ = this.secondaryBrandsSearchCtrl.valueChanges.pipe(
			startWith(''),
			map((term: string) => this._filterBrands(term, 'secondaryBrands')),
			switchMap((filtered) =>
				combineLatest([this.selectedSecondaryBrands$, this.selectedPrimaryBrands$]).pipe(
					map(([selectedSecondaryBrands, selectedPrimaryBrands]) =>
						filtered.filter((brand) => !selectedSecondaryBrands.includes(brand) && !selectedPrimaryBrands.includes(brand))
					)
				)
			)
		);
		(this.form.value?.primaryBrands || []).forEach((id) => {
			const brand = this.brands.find((u) => u.id === id);
			if (brand) this.selectedPrimaryBrands.next([...this.selectedPrimaryBrands.value, brand]);
		});
		(this.form.value?.secondaryBrands || []).forEach((id) => {
			const brand = this.brands.find((u) => u.id === id);
			if (brand) this.selectedSecondaryBrands.next([...this.selectedSecondaryBrands.value, brand]);
		});
	}

	private _filterBrands(term: string, controlName: BrandControlName): Brand[] {
		return this.brands.filter((brand) => {
			const searchTerm = !term || typeof term !== 'string' ? '' : term.toLowerCase();
			const name = brand.name.toLowerCase();
			const selectedBrands = this.form.value[controlName];
			return name.includes(searchTerm) && !selectedBrands.includes(brand.id);
		});
	}

	// Search

	private _resetSearch(controlName: LeadControlName | BrandControlName): void {
		const multiselectComp = this.multiselectInputRefs.find((comp) => {
			const inputEl = comp.elementRef.nativeElement.querySelector('input');
			return inputEl && inputEl.id === controlName;
		});
		if (!multiselectComp) return;
		multiselectComp.elementRef.nativeElement.querySelector('input').value = '';
	}

	private _getProgramFiles(): void {
		const filters = {
			...this.fileQuery.getValue().filters,
			category: { id: 'strategyDocument', name: 'Strategy Document' },
			programId: this.program.id,
			limit: 50,
		};
		this.fileService
			.get(filters)
			.pipe(first())
			.subscribe(
				() => {
					this.loadingFiles = false;
					this.cdr.markForCheck();
				},
				(err: HttpErrorResponse) => {
					this.globalService.triggerErrorMessage(err);
				}
			);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { uniqWith } from '../../_core/utils/array.utils';

@Pipe({
	name: 'uniqueArrayBy'
})
export class UniqueArrayByPipe implements PipeTransform {
	constructor() {}

	transform(array: any[], key: string): any {
		if (array) {
			return uniqWith(array, (a, b) => a[key] === b[key]);
		}
		return array;
	}
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileVersion, PublicFile, PublicFileVersion } from '../../../../../../../api/src/file/file.entity';
import { FileReviewTypeEnum } from '../../../../../../../api/src/file/enums/file-review-type.enum';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
	selector: 'app-file-version-list',
	templateUrl: './file-version-list.component.html',
	styleUrls: ['./file-version-list.component.scss']
})
export class FileVersionListComponent implements OnInit, OnChanges {
	@Input() selectedFileVersion: PublicFileVersion;
	@Input() file: PublicFile;
	@Input() fileVersions: PublicFileVersion[];
	@Output() selectFileVersion = new EventEmitter<PublicFileVersion>();

	readonly FileReviewTypeEnum = FileReviewTypeEnum;
	selectedFileReviewType: FileReviewTypeEnum;

	constructor() {}

	ngOnInit(): void {}

	onSelectFileVersion(fileVersion: PublicFileVersion): void {
		this.selectFileVersion.emit(fileVersion);
	}

	onSelectFileType($event: MatButtonToggleChange) {
		this.selectedFileReviewType = $event.value;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.fileVersions.length && Object.keys(changes)?.length === 3) {
			// Always show last version
			this.selectFileVersion.emit(this.fileVersions[0]);
		}
	}
}

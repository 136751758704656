import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mergeArrays'
})
export class MergeArraysPipe implements PipeTransform {
	transform(...arrays: any[]): any {
		let result: any = [];
		for (const arr of arrays) {
			result = [...(result ?? []), ...(arr ?? [])];
		}
		return result;
	}
}

import { Directive, OnInit, Renderer2, Input, ElementRef, SimpleChanges } from '@angular/core';

/**
 * Fill Height Directive
 * This directive will make a component's height extend down to the bottom of the page based on where it is located
 */
@Directive({
	selector: '[fillHeight]'
})
export class FillHeightDirective implements OnInit {
	@Input() paddingBottom = 0;
	@Input() disableFillHeight: boolean;
	@Input() elementClass: string;
	@Input() fitParentClass?: string;

	tryCount = 0;

	public domElement;

	constructor(private renderer: Renderer2, private el: ElementRef) {}

	ngOnInit() {
		let element = this.el?.nativeElement;

		if (this.elementClass) {
			element = document.getElementsByClassName(this.elementClass)[0];
		}

		if (element) {
			this.domElement = element;
			this.renderer.listen(window, 'resize', () => {
				this.fillHeight();
			});

			setTimeout(() => this.fillHeight(), 100);
			setTimeout(() => this.fillHeight(), 1000);
			setTimeout(() => this.fillHeight(), 2000);
			setTimeout(() => this.fillHeight(), 3000);
			setTimeout(() => this.fillHeight(), 5000);
		}
	}

	fillHeight() {
		if (this.disableFillHeight) {
			return;
		}

		const top = this.domElement.getBoundingClientRect().top;
		const calculatedHeight = window.innerHeight - top - this.paddingBottom;

		if(calculatedHeight < 100 && this.tryCount < 10) {
			setTimeout(() => {
				this.tryCount++;
				this.fillHeight();
			}, 1000);
			return;
		}

		let parentHeight;
		if(this.fitParentClass){
			const parent = document.querySelector(`.${this.fitParentClass}`);
			parentHeight = parent?.clientHeight;
		}

		const height = parentHeight ? (parentHeight - this.paddingBottom) : calculatedHeight;

		this.renderer.setStyle(this.el.nativeElement, 'overflow', `auto`);
		this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
		this.renderer.setStyle(this.el.nativeElement, 'max-height', `${height}px`);
		this.renderer.setStyle(this.el.nativeElement, 'min-height', `${height}px`);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SessionQuery } from '../../state/session/session.query';
import { CacheService } from '../../state/cache/cache.service';

/**
 * Secure Request Pipe
 * Takes a SIMPL API endpoint and attaches a secure token to the request
 * Used mostly to pull in images from the SIMPL API
 */
@Pipe({
	name: 'secure',
})
export class SecureRequestPipe implements PipeTransform {
	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private sessionQuery: SessionQuery,
		private readonly cacheService: CacheService
	) {}

	transform(path: string, resource: boolean = false): Observable<SafeUrl> {
		const url = `${environment.apiUrl}${path}`;
		const cacheResult = this.cacheService.get('GET', url, null) as Blob;
		if (cacheResult) {
			console.log('CACHE: Hit', path);
			return of(this._getResourceUrl(resource, cacheResult as Blob));
		}
		return this.http
			.get(url, {
				responseType: 'blob',
				headers: {
					Authorization: `Bearer ${this.sessionQuery.getToken()}`,
				},
			})
			.pipe(
				map((val) => {
					this.cacheService.put('GET', url, null, val as Blob);
					return this._getResourceUrl(resource, val);
				})
			);
	}

	private _getResourceUrl = (isResource: boolean, val: Blob): SafeUrl =>
		!isResource
			? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))
			: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val));
}

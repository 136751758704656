import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Milestones } from './milestones.model';

export interface MilestonesState extends EntityState<Milestones> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'milestones' })
export class MilestonesStore extends EntityStore<MilestonesState> {
	constructor() {
		super();
	}
}

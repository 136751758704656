<div [formGroup]="formGroup" *ngIf="formGroup">
	<mat-form-field class="full-width" [floatLabel]="floatLabel">
		<mat-label>{{ label }}</mat-label>
		<mat-icon matSuffix class="arrow-icon">keyboard_arrow_down</mat-icon>
		<ng-container *ngIf="mode === 'multiple'">
			<mat-chip-list #chipList aria-label="Item selection" [required]="required" [formControlName]="controlName">
				<mat-chip
					*ngFor="let item of formGroup.value[controlName]"
					[selectable]="selectable"
					[removable]="removable"
					(removed)="remove(item)"
					[matTooltip]="item | mapByCustom: getTooltip:tooltipOptions"
				>
					{{ item?.name || item?.nameFirst + " " + item?.nameLast }}
					<i class="icon icon-close" matChipRemove *ngIf="removable"></i>
				</mat-chip>
				<input
					[placeholder]="addDescription"
					#itemInput
					[required]="required"
					[formControl]="itemCtrl"
					[matAutocomplete]="auto"
					[matChipInputFor]="chipList"
					[matChipInputSeparatorKeyCodes]="seperatorKeyCodes"
					(matChipInputTokenEnd)="add($event)"
					(focus)="getFilteredItems(currentQuery)"
					(blur)="handleClose($event)"
				/>
				<i class="uil-info-circle" *ngIf="tooltip" [matTooltip]="tooltip"></i>
			</mat-chip-list>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayWithName">
				<mat-option *ngFor="let item of filteredItems" [value]="item" [class.bold]="item.bold">
					{{ item?.name || item?.nameFirst + " " + item?.nameLast }}
				</mat-option>
				<mat-option *ngIf="showSelectAll && filteredItems?.length > 1" value="selectAll">Select All</mat-option>
			</mat-autocomplete>
		</ng-container>

		<ng-container *ngIf="mode === 'single'">
			<div class="flex">
				<input
					#itemInput
					type="text"
					[placeholder]="addDescription"
					[required]="required"
					matInput
					[matAutocomplete]="auto"
					[formControl]="itemCtrl"
					(focus)="getFilteredItems(currentQuery)"
					(blur)="handleClose($event)"
					[readonly]="readonly"
				/>
				<i class="uil-times pointer" *ngIf="showClearInput && itemCtrl?.value" (click)="onClearInput()"></i>
			</div>
			<mat-autocomplete
				#auto="matAutocomplete"
				autoActiveFirstOption
				[displayWith]="displayWithName"
				(optionSelected)="selected($event)"
			>
				<mat-option
					[style.marginLeft.px]="getParentLevels(item, filteredItems) * 10"
					*ngFor="let item of filteredItems"
					[value]="item"
					[class.bold]="item.bold || (itemParentKey && !item[itemParentKey])"
				>
					{{ item?.name || item?.nameFirst + " " + item?.nameLast }}
				</mat-option>
			</mat-autocomplete>
		</ng-container>
	</mat-form-field>
</div>

<ng-container *ngIf="isVersionMode">
	<p class="section-name">Review type</p>
	<div class="version-review-type-toggle">
		<mat-button-toggle-group (change)="onSetFileReviewType($event)" [value]="formGroup.get('reviewType')?.value">
			<mat-button-toggle *ngIf="!settings?.hideInternalReview" [value]="FileReviewTypeEnum.INTERNAL_REVIEW"
				>Internal Review</mat-button-toggle
			>
			<mat-button-toggle [value]="FileReviewTypeEnum.CLIENT_REVIEW">Client Review</mat-button-toggle>
		</mat-button-toggle-group>
		<button (click)="onOpenManageReviewersDialog(file)" class="icon-left" mat-button>
			<i class="uil uil-users-alt"></i>Manage reviewers
		</button>
	</div>
	<p *ngIf="formGroup.get('reviewType')?.value === FileReviewTypeEnum.INTERNAL_REVIEW" class="notification-caption">
		Internal review will send an email notification to all Agency users assigned as reviewer or approvers.
	</p>
	<p *ngIf="formGroup.get('reviewType')?.value === FileReviewTypeEnum.CLIENT_REVIEW" class="notification-caption">
		Client Review will send an email notification to all users assigned reviewer or approver.
	</p>
	<span class="approvers"
		>Approvers:
		<div class="username-list">
			<ng-container
				*ngFor="
					let user of mergeExistingAndPendingReviewers(file?.approvers, filePendingReviewers, ReviewerRole.Approver);
					let isLast = last
				"
			>
				<ng-container *ngIf="formGroup.get('reviewType')?.value === FileReviewTypeEnum.INTERNAL_REVIEW">
					<i *ngIf="user?.agencyUser" class="uil-bell"></i>
					<i *ngIf="!user?.agencyUser" [ngClass]="{ silent: !user?.agencyUser }" class="uil-bell-slash"></i>
				</ng-container>
				<ng-container *ngIf="formGroup.get('reviewType')?.value === FileReviewTypeEnum.CLIENT_REVIEW">
					<i class="uil-bell"></i>
				</ng-container>
				<div
					[ngClass]="{ silent: !user?.agencyUser && formGroup.get('reviewType')?.value === FileReviewTypeEnum.INTERNAL_REVIEW }"
					class="username"
				>
					{{ user?.nameFirst }} {{ user?.nameLast }} {{ isLast ? '' : ',' }}
				</div>
			</ng-container>
		</div>
	</span>
	<span class="approvers"
		>Reviewers:
		<div class="username-list">
			<ng-container
				*ngFor="
					let user of mergeExistingAndPendingReviewers(file?.reviewers, filePendingReviewers, ReviewerRole.Reviewer);
					let isLast = last
				"
				><ng-container *ngIf="formGroup.get('reviewType')?.value === FileReviewTypeEnum.INTERNAL_REVIEW">
					<i *ngIf="user?.agencyUser" class="uil-bell"></i>
					<i *ngIf="!user?.agencyUser" [ngClass]="{ silent: !user?.agencyUser }" class="uil-bell-slash"></i>
				</ng-container>
				<ng-container *ngIf="formGroup.get('reviewType')?.value === FileReviewTypeEnum.CLIENT_REVIEW">
					<i class="uil-bell"></i>
				</ng-container>
				<div
					[ngClass]="{ silent: !user?.agencyUser && formGroup.get('reviewType')?.value === FileReviewTypeEnum.INTERNAL_REVIEW }"
					class="username"
				>
					{{ user?.nameFirst }} {{ user?.nameLast }} {{ isLast ? '' : ',' }}
				</div>
			</ng-container>
		</div>
	</span>
</ng-container>
<div class="section-name">File Upload</div>
<div (click)="fileInput.click()" (filesDropped)="addFile($event)" [class.mini]="files?.length > 0" appDropFile class="upload-container">
	<p *ngIf="!isUpdate">Click to upload or drop files here</p>
	<p *ngIf="isUpdate">Click to update or drop files here</p>
	<input #fileInput (change)="addFile($event.target.files)" hidden multiple type="file" />
</div>

<div class="entities-container" fxLayout="column" fxLayoutGap="10px">
	<!-- File Entity -->
	<div
		*ngFor="let file of files; let i = index"
		[class.error]="progressInfos[i]?.error"
		class="file entity"
		fxLayout="row"
		fxLayoutGap="10px"
	>
		<!-- <div *ngIf="state === 'uploading'" [style.width]="progressInfos[i].progress + '%'" class="progress"></div> -->

		<div *ngIf="!(isVersionMode && !fileUploadRequest)" class="thumb" fxFlex="10%" fxLayoutAlign="center center">
			<ng-container *ngIf="isVersionMode ? isImage(fileUploadRequest) : isImage(file)">
				<!-- If we're adding, pull the image data from the file state -->
				<img
					*ngIf="previews[i] && !this.isUpdate && !fileUploadRequest"
					[alt]="file.data.name"
					[src]="previews[i]"
					[title]="file.data.name"
				/>

				<!-- If we're updating current file -->
				<img *ngIf="fileUploadRequest" [alt]="fileUploadRequest?.name" [src]="previews[0]" [title]="fileUploadRequest?.name" />

				<!-- If we're updating, pull from secure url -->
				<img *ngIf="this.isUpdate && !fileUploadRequest" [src]="file.data.path | secure | async" loading="lazy" masonryLazy />
				<!--					<i class="uil-times" style="position: absolute;top: -5px; right: 5px" (click)="isUpdate=false"></i>-->
			</ng-container>

			<ng-container *ngIf="isVersionMode ? !isImage(fileUploadRequest) : !isImage(file)">
				<div class="thumb-icon">
					<i *ngIf="!isVersionMode" [ngClass]="getFileTypeIcon(file.data.type)"></i>
					<i *ngIf="isVersionMode" [ngClass]="getFileTypeIcon(fileUploadRequest.data.type)"></i>
				</div>
			</ng-container>
		</div>

		<div
			[formGroup]="formGroup"
			class="inputs"
			[fxFlex]="isVersionMode ? '100%' : '80%'"
			fxLayout="row wrap"
			fxLayoutAlign="center center"
			fxLayoutGap="10px"
		>
			<mat-form-field *ngIf="!isVersionMode" class="input" fxFlex="35%">
				<mat-label>Name</mat-label>
				<input
					(change)="update(i, 'name', $event)"
					(mouseup)="$event.target.select()"
					*ngIf="!this.isUpdate"
					[value]="files[i].name || ''"
					matInput
					name="file-name"
					required
				/>
				<input
					(change)="update(i, 'name', $event)"
					(mouseup)="$event.target.select()"
					*ngIf="this.isUpdate"
					[value]="files[i].name || ''"
					formControlName="name"
					matInput
					name="file-name"
					required
				/>
			</mat-form-field>

			<mat-form-field
				*ngIf="!isVersionMode && !isCategoryIgnored"
				class="input"
				fxFlex="15%"
				style="margin-left: 15px !important; margin-right: 15px !important"
			>
				<mat-label>Category</mat-label>
				<mat-select
					(selectionChange)="update(i, 'category', $event)"
					*ngIf="!this.isUpdate"
					[compareWith]="compareWithId"
					[value]="files[i].category"
					required
				>
					<mat-option *ngFor="let cat of categories | orderBy: 'name'" [value]="cat">{{ cat.name }}</mat-option>
				</mat-select>
				<mat-select
					(selectionChange)="update(i, 'category', $event)"
					*ngIf="this.isUpdate"
					[compareWith]="compareWithId"
					[formControlName]="'category'"
					[value]="files[i].category"
					required
				>
					<mat-option *ngFor="let cat of categories" [value]="cat">{{ cat.name }}</mat-option>
				</mat-select>
			</mat-form-field>

			<div *ngIf="isVersionMode" class="version-inputs">
				<div class="input-group">
					<mat-form-field class="input">
						<mat-label>Feedback Due Date</mat-label>
						<input
							(dateChange)="update(i, 'feedbackDueDate', $event)"
							[matDatepicker]="dueDate"
							[min]="minDate"
							formControlName="feedbackDueDate"
							matInput
							placeholder="Enter the feedback due date"
						/>
						<mat-datepicker-toggle [for]="dueDate" matSuffix></mat-datepicker-toggle>
						<mat-datepicker #dueDate></mat-datepicker>
					</mat-form-field>
				</div>

				<angular-editor
					(blur)="onToggleDescriptionEditorFocus()"
					(focus)="onToggleDescriptionEditorFocus()"
					(focusout)="update(i, 'description', { value: formGroup?.get('description')?.value })"
					[config]="editorConfig"
					class="input"
					formControlName="description"
					fxFlex="100%"
				></angular-editor>
			</div>
			<mat-form-field *ngIf="referenceOptions && referencePath" class="input" fxFlex="45%">
				<mat-label
					><span class="capitalize">{{ referencePath }}</span> (Optional)</mat-label
				>
				<mat-select
					(selectionChange)="update(i, referencePath, $event)"
					[compareWith]="compareWithId"
					[value]="files[i][referencePath]"
				>
					<mat-option *ngFor="let ref of referenceOptions" [value]="ref">{{ ref.name }}</mat-option>
				</mat-select>
			</mat-form-field>

			<p *ngIf="progressInfos[i]?.error" class="error">{{ progressInfos[i]?.error }}</p>
		</div>

		<div class="actions" fxFlex="10%" *ngIf="!isVersionMode">
			<!-- Delete -->
			<a (click)="deleteAttachment(i)" *ngIf="!this.isUpdate && state === 'adding'" class="flex btn remove">
				<i class="uil-file-times"></i>
			</a>

			<!-- Spinner -->
			<a *ngIf="state === 'uploading' && progressInfos[i]?.progress < 100 && !progressInfos[i]?.error" class="flex btn">
				<mat-spinner [diameter]="20"></mat-spinner>
			</a>

			<!-- Check -->
			<a *ngIf="state === 'complete' || (state === 'uploading' && progressInfos[i]?.progress === 100)" class="flex btn">
				<i class="uil-check-circle"></i>
			</a>

			<!-- Error -->
			<a
				*ngIf="(state === 'complete' && progressInfos[i]?.error) || (state === 'uploading' && progressInfos[i]?.error)"
				class="flex btn"
			>
				<i class="uil-exclamation-octagon text-danger"></i>
			</a>
		</div>
	</div>
</div>

<div
	class="circle"
	[style.background-color]="backgroundColor"
>
	<p
		class="circle-inner"
		[style.width]="scale"
		[style.height]="scale"
		[style.color]="textColor"
		[style.fontSize]="fontSize"
	>{{ initials }}</p>
</div>

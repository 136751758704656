<div class="title-row">
	<div class="left">
		<div class="title m-0">
			<h3>{{ file.name }}</h3>
			<ng-container *ngIf="(showProgram && file.program) || (showTactic && file.tactic)">
				<span class="parent-metadata ml-2">
					<span *ngIf="showProgram && file.program">({{ file.program?.name }}<ng-container *ngIf="!showTactic || !file.tactic">)</ng-container></span>
					<span *ngIf="showTactic && file.tactic"><ng-container *ngIf="showProgram"> - </ng-container>{{ file.tactic?.name }})</span>
				</span>
			</ng-container>
		</div>
		<app-file-review-status [file]="fileVersion ?? file"></app-file-review-status>
	</div>
	<div class="right align-items-center">
		<app-file-version-list
			*ngIf="file.versions && file.versions.length >= 1"
			(selectFileVersion)="onSelectFileVersion($event)"
			[selectedFileVersion]="fileVersion"
			[file]="file"
			[fileVersions]="file.versions | mergeArrays: [file]"
		></app-file-version-list>
		<button *ngIf="!isGuestUser" mat-flat-button class="button-extra-small" (click)="onOpenFileVersionUploadDialog()">New version</button>
		<!-- More -->
		<button
			mat-mini-fab
			aria-label="Add"
			class="button-extra-small ml-4"
			color="tertiary"
			[matMenuTriggerFor]="actionMenu"
			[matMenuTriggerData]="{ file: fileVersion ?? file }"
		>
			<i class="uil-ellipsis-v"></i>
		</button>

		<mat-menu #actionMenu="matMenu">
			<ng-template matMenuContent let-file="file">
				<button mat-menu-item (click)="download()">
					<i class="uil-save"></i>
					<span *ngIf="!fileVersion">Download</span>
					<span *ngIf="fileVersion">Download version</span>
				</button>
				<button *ngIf="!isGuestUser" mat-menu-item (click)="reviewersModal()">
					<i class="uil-users-alt"></i>
					<span>Manage Reviewers</span>
				</button>
				<button mat-menu-item (click)="copyLink()">
					<i class="uil-copy"></i>
					<span>Copy Share Link</span>
				</button>
				<button mat-menu-item (click)="onDownloadCommentsCsv()">
					<i class="uil-file-export"></i>
					<span>Export Comments</span>
				</button>
				<button *ngIf="showGoToProgram && !isGuestUser" mat-menu-item (click)="goToProgram()">
					<i class="uil-external-link-alt"></i>
					<span>Go To Program</span>
				</button>
			</ng-template>
		</mat-menu>

		<button mat-flat-button class="button-extra-small tertiary ml-4" (click)="closeParent.emit()">Close</button>
	</div>
</div>

<div class="split-container">
	<app-file-viewer
		[files]="[fileVersion ?? file]"
		[maxH]="true"
		[annotationMode]="annotationMode"
		[annotations]="annotationMode ? annotations : activeComment?.annotations"
		(annotationsChanged)="onAnnotationsChanged($event)"
	></app-file-viewer>
	<app-file-review-comments-container
		[file]="fileVersion ?? file"
		[annotations]="annotations"
		(annotationsCleared)="annotations = []"
		(stateChanged)="onCommentStateChange($event)"
		(activeCommentChanged)="activeComment = $event"
	></app-file-review-comments-container>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'findIndexByInArray'
})
export class FindIndexByInArrayPipe implements PipeTransform {
	transform(value: any[], search: any, objKey: string): number {
		if (value && objKey) {
			return value.findIndex(val => val[objKey] === search);
		} else if (value) {
			return value.findIndex(val => val === search);
		}
		return -1;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
	name: 'enumTitle'
})
export class EnumTitlePipe implements PipeTransform {
	constructor(private readonly titleCasePipe: TitleCasePipe) {}

	transform(value: any, args?: any): any {
		// Take the string and add a space before each capital letter.
		return value?.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
	}
}

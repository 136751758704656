import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SessionQuery } from '../../state/session/session.query';
import { SessionState } from '../../state/session/session.model';
import { Utils } from '../../../../../api/src/permission/permission.utils';
import { PermissionLevel, PermissionType } from '../../../../../api/src/permission/permission.entity';
import { ProgramQuery } from '../../state/entities/program/program.query';
import { GlobalQuery } from '../../state/global/global.query';
import { EntityPermission } from '../../../../../api/src/permission/entity-permission.entity';
import { AgencyRoles } from '../../../../../api/src/user/models';

@Directive({
	selector: '[markAsViewOnlyIfMissingPermissions]'
})
export class MarkAsViewOnlyIfMissingPermissionsDirective implements OnDestroy {
	private subs: Subscription;

	private authUser$: Observable<SessionState>;

	constructor(
		private renderer: Renderer2,
		private el: ElementRef,
		private readonly sessionQuery: SessionQuery,
		private readonly programQuery: ProgramQuery,
		private readonly globalQuery: GlobalQuery
	) {
		this.authUser$ = this.sessionQuery.select();
	}

	@Input() set markAsViewOnlyIfMissingPermissions({ form }) {
		this.subs = this.authUser$.subscribe(sessionState => {
			if (!sessionState?.profile?.entityPermissions) {
				this._setFormDisabled(form);
				this._setElementNoneEvents();
				return;
			}

			const settings = this.globalQuery.getValue().settings;
			let guardsEnabled = [...settings?.settings?.guardsEnabled] ?? [];
			if (AgencyRoles.includes(sessionState.profile.role) && !guardsEnabled.includes(PermissionType.Agency)) {
				guardsEnabled.push(PermissionType.Agency);
				guardsEnabled = guardsEnabled.filter(val => val === PermissionType.Agency);
			}

			const hasPermissionToModify = Object.values(PermissionType)
				// Check only those permissions that are enabled in the settings by guards
				.filter(permissionType => guardsEnabled.includes(permissionType))
				.every(permissionType => this.hasPermissionToModify(sessionState.profile.entityPermissions, permissionType));

			if (!hasPermissionToModify) {
				this._setFormDisabled(form);
				this._setElementNoneEvents();
			} else {
				this._setFormEnabled(form);
				this._removeElementNoneEvents();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subs) {
			this.subs.unsubscribe();
		}
	}

	private _setFormDisabled(form): void {
		form?.disable();
	}

	private _setFormEnabled(form): void {
		form?.enable();
	}

	private _setElementNoneEvents(): void {
		this.renderer?.setAttribute(this?.el?.nativeElement, 'style', 'cursor:initial!important;pointer-events:none!important');
	}

	private _removeElementNoneEvents(): void {
		this.renderer.removeAttribute(this.el.nativeElement, 'style');
	}

	private _setViewOnlyClass(): void {
		this.renderer?.addClass(this.el.nativeElement, 'view-only');
	}

	private _removeViewOnlyClass(): void {
		this.renderer?.removeClass(this.el.nativeElement, 'view-only');
	}

	private _setHiddenClass(): void {
		this.renderer?.addClass(this.el.nativeElement, 'hidden');
	}

	private _removeHiddenClass(): void {
		this.renderer?.removeClass(this.el.nativeElement, 'hidden');
	}

	private hasPermissionToModify(entityPermissions: EntityPermission[], permissionType: PermissionType): boolean {
		const currentActiveProgram = this.programQuery.getActive();
		if (!currentActiveProgram?.created) {
			return true;
		}
		const entityIdToCheck = currentActiveProgram?.[`${permissionType.toLowerCase()}Id`];

		const filteredPermissions = [
			...Utils.filterPermissions(entityPermissions, PermissionLevel.Maintain),
			...Utils.filterPermissions(entityPermissions, PermissionLevel.Write)
		];

		const permissionIds = [...new Set(Utils.extractIdsByType(filteredPermissions, permissionType))];
		return permissionIds.includes(entityIdToCheck);
	}
}

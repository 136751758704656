import { v4 as uuidv4 } from 'uuid';
import { ColumnCollection } from '../../table/table.model';
import { defaultStyleOptions } from '../../global/chart.model';
import { Filter } from '../filter/filter.model';

/**
 * Milestones Model
 * Milestones are entities that keep track of final amounts paid out to vendors for a tactic.
 * Milestones are children of Tactics.
 * Milestones are tracked with Costs and Budget Allocations to compare the estimated spends with the milestones actual amount.
 */
export type MilestoneType = 'default' | 'task';
export const MilestoneTypes: MilestoneType[] = ['default', 'task'];

export interface Milestones {
	id: string,
	name: string,
	description?: string,
	start: Date,
	end: Date,
	type: MilestoneType,
	status: MilestonesStatus,
	planId?: string,
	programId?: string,
	tacticId?: string,
	modified?: string,
	modifiedById?: string,
	created: string,
	authorId: string,
}

/**
 * Milestones have set a set 'status' to represent at what stage of the payment process the milestones is in.
 */
export type MilestonesStatus = 'open' | 'completed' ;
export const MilestonesStatuses: MilestonesStatus[] = ['open', 'completed'];

/**
 * Return a particular color for the milestones status.
 *
 */
// TODO: Need to find a way to make this work closer to how other entities are colorized in the settings object.
// With this setup, it could be very easy to have colors change accidentally based on the array length of the colors.
export function getMilestonesStatusColor(status: MilestonesStatus) {
	switch (status) {
		case 'open':
			return defaultStyleOptions.colors[4];

		case 'completed':
			return defaultStyleOptions.colors[1];
	}
}

/**
 * Create a blank milestones. Default property values are set here.
 */
export function createMilestones(params: Partial<Milestones>) {
	return {
		id: uuidv4(),
		name: '',
		description: '',
		status: 'Open',
		start: '',
		end: '',
		type: '',
		planId: '',
		programId: '',
		tacticId: '',
		modified: '',
		modifiedById: '',
		created: '',
		...params
	} as Milestones;
}

/**
 * The columns that are unique to milestones.
 */
export const MilestonesColumnCollection: ColumnCollection = {
	id: 'milestones',
	name: 'Milestones',
	items: [
		{
			id: 'tactic',
			name: 'Tactic',
			path: 'tactic',
			exportPath: 'tactic.name',
			type: 'entityName',
			category: 'Milestone',
			entityTypes: ['milestones'],
		},
		{
			id: 'program',
			name: 'program',
			path: 'program',
			exportPath: 'program.name',
			type: 'entityName',
			category: 'Milestone',
			entityTypes: ['milestones']
		},
		{
			id: 'description',
			name: 'Description',
			path: 'description',
			type: 'field',
			category: 'Milestone',
			entityTypes: ['milestones'],
		},
		{
			id: 'status',
			name: 'Status',
			path: 'status',
			type: 'field',
			category: 'Milestone',
			entityTypes: ['milestones'],
		},
		{
			id: 'type',
			name: 'Type',
			path: 'type',
			type: 'field',
			category: 'Milestone',
			entityTypes: ['milestones'],
		},
		{
			id: 'author',
			name: 'Author',
			path: 'author',
			exportPath: 'author.name',
			type: 'entityName',
			category: 'Milestone',
			entityTypes: ['milestones'],
		},
		{
			id: 'start',
			name: 'Start',
			path: 'start',
			type: 'date',
			category: 'Milestone',
			entityTypes: ['milestones'],
			editable: {
				type: 'date'
			},
		},
		{
			id: 'end',
			name: 'End',
			path: 'end',
			type: 'date',
			category: 'Milestone',
			entityTypes: ['milestones'],
			editable: {
				type: 'date'
			},
		},
		{
			id: 'created',
			name: 'Created',
			path: 'created',
			type: 'date',
			category: 'Milestone',
			entityTypes: ['milestones'],
			editable: {
				type: 'date'
			},
		},
	]
};

/**
 * The default columns that should be active on any table showing milestones.
 */
export const MilestonesDefaultActiveColumns = ['Name', 'Tactic', 'Vendor Name', 'Actual Spend', 'Status', 'Milestones Amount', 'Due Date', 'shim', 'action'];

/**
 * The filters that are unique to milestones.
 */
export const MilestonesFilterCollection: Filter[] = [
	{
		id: 'milestones-group-by',
		name: 'Grouped By',
		category: 'overall',
		matchEndpoint: 'milestones',
		slug: 'groups',
		type: 'single-select',
		order: 2,
		options: [
			{
				id: undefined,
				name: '(None)',
				value: undefined
			},
			{
				id: 'milestonesStatus',
				name: 'Milestones Status',
				entityName: 'Status',
				value: 'statuses'
			},
			{
				id: 'program',
				name: 'Program',
				entityName: 'Program',
				value: 'programs'
			},
			{
				id: 'tactic',
				name: 'Tactic',
				entityName: 'Tactic',
				value: 'tactics'
			}
		]
	},
	{
		id: 'milestones-name',
		name: 'Milestones Name',
		category: 'overall',
		matchEndpoint: 'milestones',
		slug: 'name',
		type: 'search',
	},
	{
		id: 'milestones-status',
		name: 'Milestones Status',
		category: 'milestones',
		matchEndpoint: 'milestones',
		slug: 'status',
		type: 'single-select',
		options: [{ id: undefined, name: '(None)' }, ...MilestonesStatuses.map(status => ({ id: status, name: status }))]
	},
	{
		id: 'milestones-start',
		name: 'Start',
		category: 'milestones',
		matchEndpoint: 'milestones',
		slug: 'start',
		type: 'search'
	},
	{
		id: 'milestones-end',
		name: 'End',
		category: 'milestones',
		matchEndpoint: 'milestones',
		slug: 'end',
		type: 'search'
	},
];

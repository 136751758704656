import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionState } from '../../state/session/session.model';
import { PermissionLevel, PermissionType, UserRole } from '../../state/global/global.model';
import { Observable, Subscription } from 'rxjs';
import { OrganizationSettings } from '../../../../../api/src/organization/organization.settings';
import { SessionQuery } from '../../state/session/session.query';
import { GlobalQuery } from '../../state/global/global.query';
import { UserRolePermissionUtils } from '../../_core/utils/user-role-permission.utils';
import { ProgramQuery } from '../../state/entities/program/program.query';

type PermissionRequirementManualCheckMap = {
	[role in UserRole]?: boolean;
};

export class PermissionRequirement {
	type?: PermissionType = PermissionType.Retailer;
	minLevel: PermissionLevel;
	manualGuestCheck?: boolean;
	manualCheck?: PermissionRequirementManualCheckMap;
}

@Directive({
	selector: '[hasPermissionRequirements]'
})
export class HasPermissionRequirementsDirective implements OnDestroy {
	subs: Subscription[];
	hasView = false;
	authUser$: Observable<SessionState>;
	settings: OrganizationSettings;
	permissionReqs: PermissionRequirement[];

	// New template ref for the "else" case
	elseTemplateRef: TemplateRef<any> | null = null;

	constructor(
		readonly templateRef: TemplateRef<any>,
		readonly viewContainer: ViewContainerRef,
		readonly sessionQuery: SessionQuery,
		readonly globalQuery: GlobalQuery,
		readonly programQuery: ProgramQuery,
		private cdr: ChangeDetectorRef
	) {
		this.subs = [];
		this.authUser$ = this.sessionQuery.select();
		this.settings = this.globalQuery.getValue().settings?.settings;
	}

	@Input() set hasPermissionRequirementsShowIfUnauthorized(show: boolean) {
		if (show) {
			this.showView();
		}
	}

	@Input() set hasPermissionRequirements(value: PermissionRequirement[] | PermissionRequirement) {
		this.permissionReqs = Array.isArray(value) ? value : [value];
		this.initialize();
	}

	// Input for the "else" template
	@Input() set hasPermissionRequirementsElse(templateRef: TemplateRef<any>) {
		if (templateRef) {
			this.elseTemplateRef = templateRef;
		}
	}
	initialize(): void {
		this.subs.push(
			this.authUser$.subscribe(sessionState => {
				// If the user is a guest or analyst and this route is checked manually, let the request through.
				if (
					[UserRole.Guest, UserRole.Analyst].includes(sessionState.profile.role) &&
					this.permissionReqs.some(p => !!p.manualCheck?.[sessionState.profile.role])
				) {
					this.showView();
					return;
				}

				const shouldDisplay = UserRolePermissionUtils.checkPermissions(
					this.settings,
					sessionState,
					this.permissionReqs,
					this.programQuery.getActive()
				);

				if (shouldDisplay) {
					this.showView();
				} else {
					this.cdr.detectChanges();
					this.cdr.markForCheck();
					this.showElseView();
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	private showView(): void {
		if (!this.hasView) {
			this.viewContainer.clear(); // Ensure the container is cleared first
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.hasView = true;
		}
	}

	private showElseView(): void {
		this.viewContainer.clear();
		if (this.hasView) {
			this.hasView = false;
		} else if (this.elseTemplateRef) {
			this.viewContainer.createEmbeddedView(this.elseTemplateRef);
			this.hasView = true;
		}
	}
}

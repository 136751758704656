export interface StrategicBriefDefaultDataObjectTextArea {
	readonly label: string;
	readonly placeholder: string;
 	readonly controlName: string;
	readonly required?: boolean
}

export interface StrategicBriefDefaultDataObject {
	readonly title: string;
	readonly textAreas: StrategicBriefDefaultDataObjectTextArea[];
 }

export const strategicBriefDefaultDataOutTask : StrategicBriefDefaultDataObject = {
	title: 'Our Task',
	textAreas: [
		{
			label: 'The Context',
			placeholder: 'Type the context here',
			controlName: 'theContext'
		},
		{
			label: 'The Enemy (Sourcing growth from?)',
			placeholder: 'Type the enemy here',
			controlName: 'theEnemy'
		},
		{
			label: 'The Business Problem to Solve?',
			placeholder: 'Type the business problem here',
			controlName: 'theBusinessProblem'
		},
		{
			label: 'Behavior to Change',
			placeholder: 'Type the behavior to change here',
			controlName: 'behaviorToChange'
		},
		{
			label: 'Key KPIs',
			placeholder: 'Type the key KPIs here',
			controlName: 'keyKpis'
		},
		{
			label: 'The Shopper/Audience',
			placeholder: 'Type the shopper/audience here',
			controlName: 'theShopper'
		},
		{
			label: 'The Moments',
			placeholder: 'Type the moments here',
			controlName: 'theMoments'
		}
	]
}

export const strategicBriefDefaultDataHumanStory : StrategicBriefDefaultDataObject = {
	title: 'The Human Story',
	textAreas: [
		{
			label: 'The Barrier',
			placeholder: 'Type the barrier here',
			controlName: 'theBarrier'
		},
		{
			label: 'The Culture Truth',
			placeholder: 'Type the culture truth here',
			controlName: 'theCultureTruth'
		},
		{
			label: 'The Human Problem to Solve',
			placeholder: 'Type the human problem to solve here',
			controlName: 'theHumanProblem'
		},
		{
			label: 'The Insight',
			placeholder: 'Type the insight here',
			controlName: 'theInsight'
		},
		{
			label: 'The Commerce Angle',
			placeholder: 'Type the commerce angle here',
			controlName: 'theCommerceAngle'
		},
		{
			label: 'Media Touchpoints',
			placeholder: 'Type the media touchpoints here',
			controlName: 'mediaTouchpointst'
		},
	]
}

export const strategicBriefDefaultDataAdditionalDetails : StrategicBriefDefaultDataObject = {
	title: 'Additional Details',
	textAreas: [
		{
			label: 'One Thing',
			placeholder: 'Type the one thing here',
			controlName: 'oneThing'
		},
		{
			label: 'Tone',
			placeholder: 'Type the tone here',
			controlName: 'tone'
		},
		{
			label: 'Mandatories',
			placeholder: 'Type the mandatories here',
			controlName: 'mandatories',
			required: true
		},
		{
			label: 'Specs',
			placeholder: 'Type the specs here',
			controlName: 'specs'
		},
		{
			label: 'The Fire',
			placeholder: 'Type the fire here',
			controlName: 'theFire'
		}
	]
}

export const strategicBriefDefaultDataObjects : StrategicBriefDefaultDataObject[] = [
	strategicBriefDefaultDataOutTask,
	strategicBriefDefaultDataHumanStory,
	strategicBriefDefaultDataAdditionalDetails
]

import { Pipe, PipeTransform } from '@angular/core';
import { resolveDotNotationPath } from '../../_core/utils/object.utils';
import { Column, TableRow } from '../../state/table/table.model';

@Pipe({
	name: 'getTableCellValue',
})
export class GetTableCellValuePipe implements PipeTransform {
	transform(column: Column, row: TableRow<any>, cellValue: string | any, expectValueProperty: boolean): any {
		let data = '';

		// Skip pagination row
		if (row.type === 'pagination') {
			return '';
		}
		if (expectValueProperty) {
			data = resolveDotNotationPath(column.path, row)?.value;
		} else {
			data = resolveDotNotationPath(column.path, row);
		}

		//FIXME temp fix for Tactic Funding Source
		if (column.id === 'tactic-funding-source' && data === '') {
			data = resolveDotNotationPath(column.path, row)?.rawValue?.name;
		}

		return data || cellValue || '';
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataCache } from '../../../_core/utils/cache.utils';
import { environment } from '../../../../environments/environment';
import { Product } from './product.model';
import { Brand } from '../../../../../../api/src/brand/brand.entity';

/**
 * Product Service
 * A service to manage the product logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class ProductService {
	public cache: DataCache = new DataCache();

	constructor(private http: HttpClient) {}

	/**
	 * Return a list of products based on a query string.
	 */
	suggest(text: string, brands?: Brand[], avoidCache?: boolean) {
		// Check our cache to see if we've already gotten this query from the api this session.
		let brandScope = '';
		if (brands?.length) {
			brandScope = brands.map((b) => b.id).join(',');
		}

		const cacheKey = `${text}:${brandScope}`;
		if (this.cache.get(cacheKey) && !avoidCache) {
			return of(this.cache.get(cacheKey));
		}

		let params = `?query=${text}`;
		if (brandScope?.length) {
			params += `&brands=${brandScope}`;
		}

		return this.http.get<Product[]>(`${environment.apiUrl}/organization/${environment.organizationId}/product/suggest/${params}`).pipe(
			tap((response) => {
				this.cache.set(cacheKey, response);
			})
		);
	}
}

<div class="comments-list">
	<mat-button-toggle-group>
		<mat-button-toggle *ngIf="file?.reviewType === FileReviewTypeEnum.CLIENT_REVIEW" checked>{{
			FileReviewTypeEnum.CLIENT_REVIEW | prettifyTitle
		}}</mat-button-toggle>
		<mat-button-toggle *ngIf="file?.reviewType === FileReviewTypeEnum.INTERNAL_REVIEW" checked>{{
			FileReviewTypeEnum.INTERNAL_REVIEW | prettifyTitle
		}}</mat-button-toggle>
	</mat-button-toggle-group>
	<div *ngIf="file?.feedbackDueDate" class="file-description">
		<p *ngIf="file.feedbackDueDate">Feedback Due Date</p>
		<span>{{ file.feedbackDueDate | date: 'shortDate' }}</span>
	</div>
	<div *ngIf="file?.description" class="file-description">
		<p>Version description</p>
		<span [innerHtml]="file?.description"></span>
	</div>
	<ng-container *ngIf="!file.comments?.length">
		<div class="no-comments text-center pt-5">
			<p>No comments yet</p>
		</div>
	</ng-container>

	<ng-container *ngIf="file.comments?.length > 0">
		<ng-container *ngFor="let comment of file.comments">
			<div
				class="comment content-row"
				[class.active]="comment === activeComment"
				(hover)="onCommentHover(comment)"
				(click)="onCommentClick(comment)"
			>
				<div class="avatar mr-2">
					<app-initial-avatar [initials]="getInitials(comment.author)" fontSize="1.5rem" scale="30px"></app-initial-avatar>
				</div>
				<div class="body">
					<div class="mb-1">
						<strong class="name ellipsis display-block">{{ getNameorEmail(comment.author) }}</strong>
						<div *ngIf="comment.status" class="status {{ comment.status }}">
							{{ getStatusName(comment.status) }}
						</div>
					</div>
					<p class="body" style="white-space: break-spaces" [innerHTML]="comment.body">{{ comment.body }}</p>
					<ng-container *ngIf="comment.commentFiles?.length">
						<div *ngFor="let commentFile of comment.commentFiles">
							<button
								(click)="downloadCommentNote(commentFile.path)"
								[tippyProps]="{ placement: 'left' }"
								class="button-small icon-left"
								color="primary"
								data-tippy-content="Download file"
								mat-flat-button
								ngxTippy
							>
								<i class="uil-file-upload" style="font-size: 1.7em"></i> {{ commentFile.name }}
							</button>
							<br />
						</div>
					</ng-container>
					<div class="actions-row">
						<!-- For Delete and Created-->
					</div>
				</div>
				<div *ngIf="comment.annotations?.length" class="annotations">
					<div
						class="metadata-badge mr-2"
						ngxTippy
						data-tippy-content="Click to see annotations"
						(click)="annotationsCleared.emit()"
					>
						<span class="mr-1">{{ comment.annotations.length }}</span> <i class="uil-comment-alt-edit"></i>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>

<div [formGroup]="form" class="add-comment-container pt-2">
	<ng-container *ngIf="state === 'list'">
		<button (click)="stateChange('add')" class="leave-comment-button button-small icon-left" color="primary" mat-flat-button>
			<i class="uil-comment-alt"></i> Leave Comment
		</button>
	</ng-container>

	<ng-container *ngIf="state === 'add'">
		<div class="status-row title-row">
			<div class="left">
				<!-- <app-initial-avatar [initials]="initials" fontSize="1.5rem" scale="30px"></app-initial-avatar> -->
				<h3>Leave a comment</h3>
			</div>
			<div class="right">
				<button
					mat-mini-fab
					aria-label="Close"
					class="button-extra-small icon-only tertiary mr-2"
					ngxTippy
					data-tippy-content="Close"
					(click)="stateChange('list')"
				>
					<i class="uil-multiply"></i>
				</button>
			</div>
		</div>

		<div
			#commentBox
			id="comment-box"
			class="
				mat-input-element mat-form-field-autofill-control
				cdk-textarea-autosize
				comment-input comment-input
				editable editable-placeholder
			"
			style="white-space: pre-line"
			contenteditable="true"
			[attr.data-placeholder]="'Type comment here.'"
			[mention]="filteredUsers | filterByArrayCustom: form.value.taggedUsers:isAlreadyMentioned"
			(input)="onCommentInputChange($event)"
			(focusout)="onCommentInputChange($event)"
			(itemSelected)="onTagUser($event)"
			[mentionConfig]="mentionConfig"
			(focus)="unsetAnnotationMode()"
		></div>
		<!-- <mat-form-field class="comment-input full-width no-hint mat-form-field-no-padding-bottom">
			<textarea class="comment-input" formControlName="body" matInput cdkTextareaAutosize autocomplete="off" placeholder="Type comment here."></textarea>
		</mat-form-field> -->
		<div class="actions mt-2">
			<div class="left full-width">
				<div class="flex align-items-center mt-3">
					<div class="left" *notAllowedRoles="[UserRole.Guest]">
						<button
							*ngIf="!files?.length"
							mat-mini-fab
							aria-label="Attach File"
							class="button-extra-small icon-only tertiary mr-2"
							ngxTippy
							data-tippy-content="Attach File"
							(click)="addFileDialog()"
						>
							<i class="uil-file-upload"></i>
						</button>
						<div
							*ngIf="files?.length"
							class="attachments metadata-badge mr-2"
							ngxTippy
							data-tippy-content="Click to remove attachments"
							(click)="files = []"
						>
							<span class="mr-1">{{ files?.length }}</span> <i class="uil-file-upload" inline></i>
						</div>
						<div
							*ngIf="annotations?.length"
							class="annotations metadata-badge mr-2"
							ngxTippy
							data-tippy-content="Click to remove annotations"
							(click)="annotationsCleared.emit()"
						>
							<span class="mr-1">{{ annotations.length }}</span> <i class="uil-comment-alt-edit"></i>
						</div>
					</div>
					<div class="right">
						<ng-container *ngIf="isApprover()">
							<button
								(click)="create(commentStatus.Approved)"
								class="mr-2 success bold button-small"
								color="success"
								ngxTippy
								data-tippy-content="Approve"
								mat-mini-fab
							>
								<i class="uil-comment-alt-verify"></i>
							</button>

							<button
								(click)="create(commentStatus.ChangesRequested)"
								class="warning bold mr-2 button-small"
								color="warning"
								ngxTippy
								data-tippy-content="Request Changes"
								mat-mini-fab
							>
								<i class="uil-comment-alt-exclamation"></i>
							</button>

							<button
								(click)="create(undefined)"
								[disabled]="!form.valid || form.value?.body?.length === 0"
								class="bold mr-2 button-small"
								color="primary"
								ngxTippy
								data-tippy-content="Comment"
								mat-mini-fab
							>
								<i class="uil-comment-alt"></i>
							</button>
						</ng-container>
						<ng-container *ngIf="!isApprover()">
							<button
								(click)="create(undefined)"
								[disabled]="!form.valid"
								class="button-small icon-left"
								color="primary"
								mat-flat-button
							>
								<i class="uil-save"></i> Comment
							</button>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

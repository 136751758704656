import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'tryCatch'
})
export class TryCatchPipe implements PipeTransform {
  transform(value: any, pipeFunction?: PipeTransform, args: any[] = [], fallback: any = ''): any {
    if (pipeFunction) {
      try {
        return pipeFunction.transform(value, ...args);
      } catch (e) {
        return fallback;
      }
    }
    return value;
  }
}

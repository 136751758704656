import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  constructor() {}

  transform(
    value: string,
    find: string,
    replaceValue: string
  ): string | undefined {
    if (!value || !find || !replaceValue) {
      return undefined;
    }
    return value.replace(find, replaceValue);
  }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MergedModificationCollection } from './staged-modification.model';
import { StagedModificationStore, StagedModificationState } from './staged-modification.store';
import { getMergedModificationsByEntityId } from './staged-modification.utils';

@Injectable({ providedIn: 'root' })
export class StagedModificationQuery extends QueryEntity<StagedModificationState> {

  constructor(protected store: StagedModificationStore) {
    super(store);
  }

  getMergedModificationByEntityId(entityId: string) {
	const mergedModificationCollection: MergedModificationCollection = getMergedModificationsByEntityId(this.getAll());

	return mergedModificationCollection[entityId]?.body;
  }

}

import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm";
import { Program } from '../program/program.entity';
import { StrategicBriefTypeEnum } from '../organization/data/strategic-brief.data';

@Entity({ name: 'programStrategicBriefs' })
export class ProgramStrategicBriefEntity {
  @PrimaryGeneratedColumn('uuid', { name: 'id' }) id: string;
  @Column({ name: 'data', type: 'json' }) data: any;

  @Column({ name: 'versionNumber', type: 'int' }) versionNumber: number;
  @Column({ name: 'versionDate', type: 'timestamp' }) versionDate: Date;
  @Column({ name: 'type', type: 'enum', enum: StrategicBriefTypeEnum, default: StrategicBriefTypeEnum.DEFAULT }) type: StrategicBriefTypeEnum;

  @ManyToOne(() => Program, (program) => program.programStrategicBrief)
  @JoinColumn({ name: 'program_id' })
  program: Program | string;
}

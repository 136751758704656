<h1 mat-dialog-title *ngIf="!this.data.remove">{{ data.title }}</h1>
<h1 mat-dialog-title *ngIf="this.data.remove">Remove {{ data.entityName }}</h1>

<div mat-dialog-content>
	<p *ngIf="this.data.unlink">
		Are you sure you want to unlink this program from your plan? This will not remove the program for other plans.
	</p>

	<p *ngIf="this.data.message" [innerHTML]="this.data.message | safeHtml"></p>

	<p *ngIf="this.data.remove">Are you sure you want to remove this entity? You will not be able to undo this action.</p>


	<ng-container *ngIf="confirmation && data.confirmWithString">
		<p>
			Please type <strong>{{ data.confirmationString }}</strong> below to confirm.
		</p>
		<br />

		<form [formGroup]="confirmation">
			<mat-form-field class="input" fxFlex="100%">
				<mat-label>Type the name to confirm.</mat-label>
				<input
					matInput
					name="verifyString"
					formControlName="verifyString"
					required
					(input)="match()"
				/>
			</mat-form-field>
		</form>
	</ng-container>
</div>

<div mat-dialog-actions align="start">
	<button class="cancel button-extra-small" mat-button (click)="no()">{{ this.data.cancel || 'Cancel' }}</button>

	<div class="right margin-left-auto">
		<button *ngIf="this.data.secondary" class="secondary button-extra-small" mat-flat-button color="secondary"  (click)="secondary()">{{ this.data.secondary }}</button>
		<button
			*ngIf="confirmation"
			[disabled]="data.confirmWithString && !match()"
			mat-button
			[mat-dialog-close]="data"
			mat-flat-button
			class="remove button-extra-small"
		>
			<span *ngIf="this.data.unlink">Unlink</span>
			<span *ngIf="this.data.confirm">{{this.data.confirm || "Confirm"}}</span>
			<span *ngIf="this.data.remove">Remove</span>
		</button>
	</div>

</div>

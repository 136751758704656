<ng-container *ngIf="data.file?.requiresApproval && !disableFileReview">
	<app-file-review
		[id]="data.file.id"
		[file]="file$ | async"
		[fileVersionId]="selectedFileVersionIdMap.get(data.file.id)"
		(selectedFileVersion)="onSetFileVersion($event, data.file.id)"
		(closeParent)="cancel()"
		[showProgram]="data.showProgram"
		[showTactic]="data.showTactic"
		[showGoToProgram]="data.showGoToProgram"
		class="mb-6"
		elementClass="panel-content-container"
		paddingBottom="20"
	></app-file-review>
</ng-container>

<ng-container *ngIf="!data.file?.requiresApproval">
	<div mat-dialog-title fxLayout="row">
		<h1 class="break">{{ data.file.name }}</h1>

		<div class="actions margin-left-auto">
			<button mat-mini-fab aria-label="Copy File Path" class="button-small margin-right" (click)="copyFilePath()">
				<i class="uil-copy"></i>
			</button>
			<button mat-mini-fab aria-label="Download" class="button-small margin-right" (click)="download()">
				<i class="icon icon-download"></i>
			</button>
			<button mat-flat-button color="secondary" class="button-small" (click)="cancel()">Close</button>
		</div>
	</div>

	<div mat-dialog-content>
		<div *ngIf="isImage(data.file)" class="image">
			<img [src]="data.file.path | secure | async" />
			<mat-spinner [diameter]="40"></mat-spinner>
		</div>

		<app-file-viewer *ngIf="!isImage(data.file)" [files]="[data.file]"></app-file-viewer>
	</div>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArrayBy'
})
export class FilterArrayByPipe implements PipeTransform {
	transform(array: any[], objKey: string, expectedValue: any, operator: string = 'eq'): any[] {
		if (!array || !objKey || !expectedValue) {
			return array;
		}

		if (array && objKey) {
			return array.filter(item => {
				const value = item[objKey];

				switch (operator) {
					case 'eq':
					return value === expectedValue;
					case 'neq':
					return value !== expectedValue;
					case 'gt':
					return value > expectedValue;
					case 'lt':
					return value < expectedValue;
					default:
					return value === expectedValue;
				}
			});
		}
		return array;
	}
}

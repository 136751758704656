import { Pipe, PipeTransform } from '@angular/core';
import { sortArrayByLocaleCompare } from '../../_core/utils/array.utils';
@Pipe({ name: 'locationSort' })
export class LocationSortPipe implements PipeTransform {
	transform(records: Array<any>): any {
		if (!records || !Array.isArray(records) || !records.length) {
			return records;
		}

		// Separate locations by type
		let regions = records.filter(loc => loc.type === 'region');
		let areas = records.filter(loc => loc.type === 'area');
		let countries = records.filter(loc => loc.type === 'country');

		// Sort each level by name
		regions = sortArrayByLocaleCompare(regions, 'name');
		areas = sortArrayByLocaleCompare(areas, 'name');
		countries = sortArrayByLocaleCompare(countries, 'name');

		// Group areas under their regions
		const sortedLocations = [];
		const addedAreas = new Set();
		const addedCountries = new Set();

		// Add regions and their related areas and countries
		regions.forEach(region => {
			sortedLocations.push(region);

			// Find areas belonging to this region
			const regionAreas = areas.filter(area => area.parent.id === region.id);

			regionAreas.forEach(area => {
				sortedLocations.push(area);
				addedAreas.add(area);

				// Find countries belonging to this area
				const areaCountries = countries.filter(country => country.parent.id === area.id);
				sortedLocations.push(...areaCountries);
				areaCountries.forEach(addedCountries.add, addedCountries);
			});
		});

		areas.forEach(area => {
			if (!addedAreas.has(area)) {
				sortedLocations.push(area);

				// Find countries belonging to this area
				const areaCountries = countries.filter(country => country.parent.id === area.id);
				sortedLocations.push(...areaCountries);
				areaCountries.forEach(addedCountries.add, addedCountries);
			}
		});

		countries.forEach(country => {
			if (!addedCountries.has(country)) {
				sortedLocations.push(country);
			}
		});

		return sortedLocations;
	}
}

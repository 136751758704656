import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entities } from '../../../../state/global/global.model';
import { Plan } from '../../plan/plan.model';
import { Program } from '../../program/program.model';
import { Tactic } from '../../tactic/tactic.model';
import { FileUploadContainerComponent } from '../file-upload-container/file-upload-container.component';

@Component({
	selector: 'app-file-upload-dialog',
	templateUrl: './file-upload-dialog.component.html',
	styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {

	@ViewChild(FileUploadContainerComponent) fileUploadContainer: FileUploadContainerComponent;

	public state: 'adding' | 'uploading' | 'complete' = 'adding';
	public isUpdate: boolean = false;
	public canUpload: boolean = false;
	public canUpdate: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<FileUploadDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			id: Program['id'] | Plan['id'];
			entityType: Entities;
			referencePath: string;
			tactics: Tactic[];
			programs: Program[];
			file?: any;
			skipUpload?: boolean,
			isCategoryIgnored?: boolean
		}
	) {
	}

	ngOnInit(): void {
		if (this.data.file) {
			this.isUpdate = true;
		}
	}

	setState(state: 'adding' | 'uploading' | 'complete') {
		this.state = state;

		if (state === 'complete') {
			this.dialogRef.close();
		}
	}

	setCanUpload(canUpload: boolean) {
		this.canUpload = canUpload;
	}

	setCanUpdate(canUpdate: boolean) {
		this.canUpdate = canUpdate;
	}

	upload() {
		if (this.data.skipUpload){
			this.dialogRef.close(this.fileUploadContainer.files);
			return;
		}

		this.fileUploadContainer.uploadFiles();
	}

	update() {
		this.fileUploadContainer.updateFile();
	}

	cancel() {
		this.dialogRef.close();
	}

}

<div class="status-container">
	<h3 class="name" *ngIf="showWithLabels">Pending</h3>
	<span class="badge" [ngxTippy]="pendingTemplate" [tippyProps]="{ interactive: true, placement: 'bottom', zIndex: 99999999 }">
		<span class="badge-text">{{ pending.length }}</span>
	</span>
</div>

<div class="status-container" [ngClass]="{ 'ml-5': showWithLabels, 'ml-0': !showWithLabels }">
	<h3 class="name" *ngIf="showWithLabels">Requesting Changes</h3>
	<span
		class="badge warning"
		[ngxTippy]="requestingChangesTemplate"
		[tippyProps]="{ interactive: true, placement: 'bottom', zIndex: 99999999 }"
	>
		<span class="badge-text">{{ requestingChanges.length }}</span>
	</span>
</div>

<div class="status-container" [ngClass]="{ 'ml-5': showWithLabels, 'ml-0': !showWithLabels }">
	<h3 class="name" *ngIf="showWithLabels">Approved</h3>
	<span class="badge success" [ngxTippy]="approvedTemplate" [tippyProps]="{ interactive: true, placement: 'auto-end', zIndex: 99999999 }">
		<span class="badge-text">{{ approved.length }}</span>
	</span>
</div>

<!-- Linked Tooltip -->
<div class="pending-popover" #pendingTemplate>
	<ng-container *ngIf="pending?.length">
		<ng-container *ngFor="let user of pending">
			<div class="user">
				{{ getNameOrEmail(user) }}
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!pending?.length">
		<div class="user">
			No users yet.
		</div>
	</ng-container>
</div>

<div class="requestingChanges-popover" #requestingChangesTemplate>
	<ng-container *ngIf="requestingChanges?.length">
		<ng-container *ngFor="let user of requestingChanges">
			<div class="user">
				{{ getNameOrEmail(user) }}
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!requestingChanges?.length">
		<div class="user">
			No users yet.
		</div>
	</ng-container>
</div>

<div class="approved-popover" #approvedTemplate>
	<ng-container *ngIf="approved?.length">
		<ng-container *ngFor="let user of approved">
			<div class="user">
				{{ getNameOrEmail(user) }}
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!approved?.length">
		<div class="user">
			No users yet.
		</div>
	</ng-container>
</div>

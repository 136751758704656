<h1 mat-dialog-title>Create New Version</h1>
<span class="subtitle">
	{{ data.file.name }}
	<span *ngIf="data.file.program" class="subtitle">{{ data.file.program?.name }}</span>
</span>

<div mat-dialog-content>
	<app-file-upload-container
		(fileUploadRequestChanged)="onSetFileUploadRequest($event)"
		(stateChange)="setState($event)"
		(updated)="cancel()"
		(validFileList)="setCanUpload($event)"
		(validUpdateForm)="setCanUpdate($event)"
		[file]="data.file"
		[isVersionMode]="true"
	></app-file-upload-container>
</div>

<div mat-dialog-actions>
	<button (click)="cancel()" mat-button>Close</button>

	<button *ngIf="state === 'uploading'" class="button-extra-small" mat-button mat-flat-button>Uploading...</button>
	<button
		(click)="update()"
		*ngIf="this.isUpdate && state !== 'uploading'"
		[disabled]="!this.canUpdate || !fileUploadRequest"
		class="button-extra-small"
		mat-button
		mat-flat-button
	>
		Upload new version
	</button>
	<button (click)="cancel()" *ngIf="state === 'complete'" class="button-extra-small margin-left-auto" mat-button mat-flat-button>
		Done
	</button>
</div>

import { ProgramService } from './../../../state/entities/program/program.service';
import { Program, ProgramStrategicBrief } from './../../../state/entities/program/program.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../state/global/global.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StrategicBriefTypeEnum } from '../../../../../../api/src/organization/data/strategic-brief.data';
import { StrategicBriefDialogDefaultComponent } from './types/default/default.component';
import { delay, filter, first, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GlobalQuery } from '../../../state/global/global.query';
import { StrategicBriefViewComponent } from '../strategic-brief-view/strategic-brief-view.component';
import { FileService } from '../../../state/entities/file/file.service';
import { FileUploadRequest } from '../../../state/entities/file/file.model';
import { Observable, of, timer } from 'rxjs';
import { Milestones } from '../../../state/entities/milestone/milestones.model';

@Component({
	selector: 'app-strategic-brief-dialog',
	templateUrl: 'strategic-brief-dialog.component.html',
	styleUrls: ['strategic-brief-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategicBriefDialogComponent implements OnInit {
	program: Program;

	loading?: boolean;

	strategicBriefType: StrategicBriefTypeEnum;

	showInvalidFormError = false;

	@ViewChild(StrategicBriefDialogDefaultComponent) defaultComponent: StrategicBriefDialogDefaultComponent;
	@ViewChild('dynamic', { read: ViewContainerRef }) private viewRef: ViewContainerRef;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private readonly dialogData: {
			readonly program: Program;
		},
		private readonly dialogRef: MatDialogRef<StrategicBriefDialogComponent>,
		private readonly programService: ProgramService,
		private readonly globalService: GlobalService,
		private readonly cdr: ChangeDetectorRef,
		private readonly globalQuery: GlobalQuery,
		private readonly vcr: ViewContainerRef,
		private readonly fileService: FileService
	) {}

	ngOnInit(): void {
		this.program = this.dialogData.program;
		this.globalQuery.authenticatedSettings$.pipe(first()).subscribe((settings) => {
			this.strategicBriefType = settings?.settings?.strategicBriefType;
		});
	}

	onMilestonesUpdate(milestones: Milestones[]): void {
		this.programService.update(this.program.id, { milestones }, true);
		this.program = { ...this.program, milestones };
	}

	onSave() {
		let dto;

		this.showInvalidFormError = false;

		console.log(this.defaultComponent.formValid, this.defaultComponent.form.value, this.defaultComponent.form);

		if (!this.strategicBriefType || this.strategicBriefType === StrategicBriefTypeEnum.DEFAULT) {
			this.defaultComponent.form.markAllAsTouched();
			if (!this.defaultComponent.formValid) {
				this.showInvalidFormError = true;
				return;
			}
			dto = this.defaultComponent.form.value;
		} else {
			this.showInvalidFormError = true;
			return;
		}

		this.loading = true;

		this._preparePdfData({
			...this.program.programStrategicBrief,
			data: dto,
			versionNumber: this.program.programStrategicBrief?.versionNumber,
		})
			.pipe(
				first(),
				switchMap((fileResponse) => {
					return this.programService.updateProgramStrategicBrief(this.program.id, dto, fileResponse.body.id);
				})
			)
			.subscribe(
				(response) => {
					this.globalService.triggerSaveMessage();
					this.dialogRef.close({ ...response });
				},
				(err: HttpErrorResponse) => {
					this.globalService.triggerErrorMessage(err);
				},
				() => {
					this.loading = false;
					this.cdr.markForCheck();
				}
			);
	}

	private _preparePdfData(brief: ProgramStrategicBrief): Observable<any> {
		const type = this.strategicBriefType;
		this.viewRef.clear();

		let obs$ = of(null);

		if (!type || type === StrategicBriefTypeEnum.DEFAULT) {
			obs$ = this._generateDefaultPdf(brief);
		}

		return obs$.pipe(
			tap(() => {
				this.viewRef.clear();
			})
		);
	}

	private _generateDefaultPdf(brief: ProgramStrategicBrief): Observable<any> {
		const compRef = this.vcr.createComponent(StrategicBriefViewComponent);
		compRef.instance.program = this.program;
		compRef.instance.exportView = true;

		return compRef.instance.dataSubscribed.pipe(
			first(),
			tap(() => {
				compRef.changeDetectorRef.markForCheck();
			}),
			delay(0),
			switchMap(() => {
				const html = compRef.location.nativeElement;
				compRef.destroy();
				return this._generatePdf(html, brief);
			})
		);
	}

	private _generatePdf(html: HTMLElement, brief: ProgramStrategicBrief): Observable<any> {
		const name = `Strategic Brief - Version ${brief.versionNumber}`;
		return this.fileService.generatePdf(html, name).pipe(
			first(),
			switchMap((response) => {
				const fileReq: FileUploadRequest = {
					name,
					category: { id: 'strategyDocument', name: 'Strategy Document' },
					program: brief?.program,
					data: response,
				};
				return this.fileService.upload(fileReq, this.program.id, 'program').pipe(
					filter((event) => event instanceof HttpResponse),
					take(1)
				);
			})
		);
	}
}

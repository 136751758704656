import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SecurityContext, SimpleChanges } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { FileService } from '../file.service';
import { GlobalService } from '../../../global/global.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EditorAnnotation, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { unfreeze } from '../../../../_core/utils/object.utils';

@Component({
  selector: 'app-file-pdf-viewer',
  templateUrl: './file-pdf-viewer.component.html',
  styleUrls: ['./file-pdf-viewer.component.scss']
})
export class FilePdfViewerComponent implements OnChanges, OnInit, OnDestroy {
	@Input() file: PublicFile;
	@Input() annotationMode: boolean;
	@Input() annotations: EditorAnnotation[] = [];
	@Output() annotationsChanged: EventEmitter<EditorAnnotation[]> = new EventEmitter<EditorAnnotation[]>();

	public encodedUrl: Blob;
	public timer: any;
	public annotationLayerRendered: boolean = false;

	constructor(
		private readonly fileService: FileService,
		private readonly globalService: GlobalService,
		private readonly pdfViewerService: NgxExtendedPdfViewerService,
		private readonly http: HttpClient,
		private readonly sanitizer: DomSanitizer
	) { }

	ngOnInit(): void {
		this.getTemporaryFileURL(this.file);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.annotations && this.annotationLayerRendered) {
			this.addAnnotations(changes.annotations.currentValue);
		}
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}

	getTemporaryFileURL(file: PublicFile){
		this.fileService.getTemporaryFileURL(file.path).subscribe(
			(response) => {
				this.encodedUrl = response.url;
			},
			(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
		);
	}

	onAnnotationLayerRendered(event: CustomEvent) {
		this.annotationLayerRendered = true;

		// Listen for annotation changes and bubble them up to the parent component.
		this.timer = setInterval(() => {
			try {
				this.annotationsChanged.emit(this.pdfViewerService.getSerializedAnnotations());
				// console.log('Emitting annotations', this.pdfViewerService.getSerializedAnnotations()[0]?.rect);
			} catch (error) {}
		}, 500);
	}

	addAnnotations(annotations: EditorAnnotation[]) {
		if (this.annotations?.length > 0) {
			// Compare the annotations to the current annotations and only add new ones.
			const currentAnnotations = this.pdfViewerService.getSerializedAnnotations();
			try {
				if (JSON.stringify(currentAnnotations) === JSON.stringify(annotations)) {
					console.log('Annotations are the same. Skipping.');
					return;
				}
			} catch(e) { console.log('Couldnt parse annotations') }

			this.pdfViewerService.removeEditorAnnotations();

			for (let annotation of this.annotations) {
				this.pdfViewerService.addEditorAnnotation(unfreeze(annotation));
			}

			console.log('Scrolling to page', this.annotations[0].pageIndex);
			this.pdfViewerService.scrollPageIntoView(this.annotations[0].pageIndex + 1, { top: 0, left: 0});
		} else {
			this.pdfViewerService.removeEditorAnnotations();
		}
	}
}

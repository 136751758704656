import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Vendor } from './vendor.model';

export interface VendorState extends EntityState<Vendor> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vendor' })
export class VendorStore extends EntityStore<VendorState> {
	constructor() {
		super();
	}
}

<ng-container *ngIf="settings$ | async as settings">
	<div class="entities-container" fxLayout="column" fxLayoutGap="10px">
		<app-milestone-card
			class="entity flex-entity"
			*ngFor="let milestone of milestones"
			[milestone]="milestone"
			[viewOnly]="viewOnly"
			(clicked)="clicked.emit($event)"
			(removed)="removed.emit($event)"
			[ngClass]="{ 'error': !milestone.brandAllocations?.length }"
		></app-milestone-card>
	</div>
</ng-container>

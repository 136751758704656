import { SessionState } from '../../state/session/session.model';
import { AgencyRoles, UserRole } from '../../../../../api/src/user/models';
import { PermissionType } from '../../state/global/global.model';
import { Utils } from '../../../../../api/src/permission/permission.utils';
import { PermissionRequirement } from '../../shared/directives/has-permission-requirements.directive';
import { OrganizationSettings } from '../../../../../api/src/organization/organization.settings';
import { Program } from '../../state/entities/program/program.model';

export class UserRolePermissionUtils {
	public static checkPermissions(
		settings: OrganizationSettings,
		sessionState: SessionState | null,
		permissionReqs: PermissionRequirement[],
		currentActiveProgram: Program
	): boolean {
		if (!sessionState || !(sessionState.profile as any).entityPermissions?.length) {
			return false;
		}
		if (!permissionReqs?.length) {
			return true;
		}
		// If the user is a guest and this route is checked manually, let the request through.
		if (sessionState.profile.role === UserRole.Guest && permissionReqs.some((p) => p.manualGuestCheck)) {
			return true;
		}

		const entityIdsToCheck = [];

		let guardsEnabled = [...(settings?.guardsEnabled ?? [])];
		if (AgencyRoles.includes(sessionState.profile.role) && !guardsEnabled.includes(PermissionType.Agency)) {
			guardsEnabled.push(PermissionType.Agency);
			guardsEnabled = guardsEnabled.filter((val) => val === PermissionType.Agency);
		}

		Object.values(PermissionType)
			// Check only those permissions that are enabled in the settings by guards
			.filter((permissionType) => guardsEnabled.includes(permissionType))
			.every((permissionType) => {
				entityIdsToCheck.push(currentActiveProgram?.[`${permissionType.toLowerCase()}Id`]);
				return true;
			});

		return permissionReqs.every((req) => {
			if (req.type === undefined) {
				req.type = PermissionType.Retailer;
			}
			// For agency roles, set the type to agency
			if (AgencyRoles.includes(sessionState.profile.role)) {
				req.type = PermissionType.Agency;
			}
			// Check if the user has the required permission level for the retailer or agency entity
			const entityPermissions = sessionState.profile.entityPermissions;

			if (!entityPermissions?.length) {
				return false;
			}

			// If there are no entityIds to check, return false
			const entityIds = (
				entityPermissions.map((entityPermission) =>
					(entityPermission.entityMap[req.type] ?? []).filter((entityId) => entityIdsToCheck.includes(entityId))
				) as any
			)?.flat();

			return (
				Utils.hasEntityPermission(entityPermissions, req.type ?? PermissionType.Retailer, req.minLevel, entityIds) &&
				Utils.guardEnabled(settings, req.type)
			);
		});
	}
}

import { formatCurrency, formatDate, formatPercent, getCurrencySymbol } from '@angular/common';
import { environment } from '../../../environments/environment';
import { joinWithProp, pluckFromArray } from '../../_core/utils/array.utils';
import { getNumberWithMask } from '../../_core/utils/number.utils';
import { getLastPropertyFromPath, resolveDotNotationPath } from '../../_core/utils/object.utils';
import { capitalize, stripHtml } from '../../_core/utils/string.utils';
import { Entity } from '../entities/entities.model';

import { AppSection, EntityLinkPaths, getChildEntity, GlobalSettings, ValueType } from '../global/global.model';
import {
	Column,
	ColumnCollection,
	DefaultSortCollection,
	TableCollection,
	TableItem,
	TableItemDataProperty,
	TableRow,
} from './table.model';

import { Metadata } from '../entities/metadata/metadata.model';
import { FilterParameters, IncludeOption } from '../entities/filter/filter.model';
import { MediaType } from '../../../../../api/src/base-tactic-type/base-tactic-type.entity';
import { isArray } from '@datorama/akita';
import { isDate, isObject, isString } from '../../_core/utils/types.utils';
import { EntityPlurals } from '../entities/entities.utils';
import { EntitySettings } from '../../../../../api/src/organization/organization.settings';
import { AggregateFunction } from '../../../../../api/src/_core/models/math-operations';
import { MediaPlanningDefaultActiveColumns, ProgramDefaultActiveColumns, ProgramFilterCollection } from '../entities/program/program.model';
import { TacticDefaultActiveColumns, TacticFilterCollection } from '../entities/tactic/tactic.model';
import { InvoiceDefaultActiveColumns, InvoiceFilterCollection } from '../entities/invoice/invoice.model';
import { PlanDefaultActiveColumns, PlanFilterCollection } from '../entities/plan/plan.model';
import { defaultStyleOptions } from '../global/chart.model';
import { GraphMergeOptionsChartConstraints } from '../../../../../api/src/graph/dtos/mega-graph.dto';
import { saveAs } from 'file-saver';
import { ElementRef } from '@angular/core';

import { InvoiceFacets, PlanFacets, ProgramFacets, SortRequest, TacticFacets } from '../../../../../api/src/find/dtos/filter-set.dto';
import { ProgramClassification } from '../../../../../api/src/program/program.entity';

import { BudgetPeriod } from '../../../../../api/src/budget-period/budget-period.entity';
import { findEarliestAndLatestDate } from '../../_core/utils/date.utils';
import { ProgramUtils } from '../entities/program/program.utils';
import { TacticSelect } from '../../../../../api/src/tactic/utils/query.utils';
import { InvoiceSelect } from '../../../../../api/src/invoice/utils/query.utils';
import { ProgramSelect } from '../../../../../api/src/program/utils/query.utils';
import { PlanSelect } from '../../../../../api/src/plan/utils/query.utils';

export function getBudgetCacheTableRowValue(row: TableRow<any>, column: Column): TableItemDataProperty {
	const columnPath = getLastPropertyFromPath(column.exportPath);
	const obj: TableItemDataProperty = {
		difference: undefined,
		tooltip: undefined,
		value: undefined,
	};
	const currencyCode = environment.currencyCode || 'USD';
	const locale = environment.locale || 'en-US';

	// Show nothing if the row doesn't support this column
	if (
		(row.type === 'Tactic' && columnPath === 'amountActual') ||
		(row.type === 'Invoice' && columnPath === 'spendEstimated') ||
		(row.type === 'Tactic' && column.id === 'plan-planned-program-budget')
	) {
		return {} as TableItemDataProperty;
	}

	// We need to show the brand budget if it exists and at least some of the values are different
	// than the other budget.  This protects where some values are zero and therefore show as falsy and
	// trigger the regular template.
	// console.log('Budget Cache', this.tableRow.budgetCacheBrand);
	if (
		row.budgetCacheBrand &&
		((row.budgetCache?.amountEstimated && row.budgetCacheBrand?.amountEstimated !== row.budgetCache?.amountEstimated) ||
			(row.budgetCache?.amountPlanned && row.budgetCacheBrand?.amountPlanned !== row.budgetCache?.amountPlanned) ||
			(row.budgetCache?.amountActual && row.budgetCacheBrand?.amountActual !== row.budgetCache?.amountActual) ||
			(row.budgetCache?.spendEstimated && row.budgetCacheBrand?.spendEstimated !== row.budgetCache?.spendEstimated) ||
			(row.budgetCache?.spendActual && row.budgetCacheBrand?.spendActual !== row.budgetCache?.spendActual))
	) {
		// if (row.budgetCacheBrand[columnPath]) {
		const cacheVal = row.budgetCache[columnPath] || row.budgetCacheBrand[columnPath] || 0;
		obj.value =
			formatCurrency(cacheVal, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.0-2') +
			`<div class="brand-budget-icon mat-icon notranslate really-faded material-icons mat-icon-inline mat-icon-no-color ng-star-inserted" inline>info</div>`;
		obj.tooltip =
			'Total Budget: ' +
			formatCurrency(row.budgetCache[columnPath] || 0, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.0-2');
		obj.rawValue = cacheVal;
		// }

		// Don't show differences if its calculating against something with no value
		if (
			!column.extra?.showDifferenceWith ||
			(columnPath === 'spendEstimated' && !row.budgetCacheBrand.amountActual) ||
			(columnPath === 'spendActual' && !row.budgetCacheBrand.spendEstimated)
		) {
			// Do nothing
		} else {
			obj.difference = {
				number1: row.budgetCacheBrand[columnPath],
				number2: row.budgetCacheBrand[column.extra?.showDifferenceWith],
				invert: !column.extra?.invertDifference,
			};
		}
	} else if (row.amountPlanned) {
		obj.value = formatCurrency(
			row.amountPlanned.rawValue || 0,
			locale,
			getCurrencySymbol(currencyCode, 'narrow'),
			currencyCode,
			'1.0-2'
		);
		obj.rawValue = row.amountPlanned.rawValue || 0;
	} else if (row.amountActual) {
		obj.value = formatCurrency(
			row.amountActual.rawValue || 0,
			locale,
			getCurrencySymbol(currencyCode, 'narrow'),
			currencyCode,
			'1.0-2'
		);
		obj.rawValue = row.amountActual.rawValue || 0;
	} else if (row.type === 'Invoice') {
		obj.value = formatCurrency(row.amount || 0, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, '1.0-2');
		obj.rawValue = row.amount || 0;
	} else {
		if (row.budgetCache?.[columnPath]) {
			obj.value = formatCurrency(
				row.budgetCache[columnPath] || 0,
				locale,
				getCurrencySymbol(currencyCode, 'narrow'),
				currencyCode,
				'1.0-2'
			);
			obj.rawValue = row.budgetCache[columnPath] || 0;
		}

		// Don't show differences if its calculating against something with no value
		if (
			!column.extra?.showDifferenceWith ||
			(columnPath === 'spendEstimated' && !row.budgetCache?.amountActual) ||
			(columnPath === 'spendActual' && !row.budgetCache?.spendEstimated) ||
			(columnPath === 'amountEstimated' && !row.budgetCache?.amountEstimated)
		) {
			// Do nothing
		} else {
			if (obj.rawValue) {
				obj.difference = {
					number1: row.budgetCache?.[columnPath],
					number2: row.budgetCache?.[column.extra?.showDifferenceWith],
					invert: !column.extra?.invertDifference,
				};
			}
		}
	}

	// console.log('Budget Cache', obj.value, row.budgetCache[columnPath]);

	return obj;
}

/**
 * Get the mask value for a particular value type
 */
export function getValueTypeMaskedValue(
	value: any,
	type: ValueType,
	column?: Column | Metadata,
	item?: Entity,
	settings?: GlobalSettings,
	flatten?: boolean // If true, only return the value, not the object
): TableItemDataProperty | TableItemDataProperty['value'] {
	let obj: TableItemDataProperty = {
		difference: undefined,
		tooltip: undefined,
		value,
		rawValue: value,
	};

	let childEntityType;
	if (column?.extra?.useChildEntity) {
		childEntityType = getChildEntity(item['type']);
	}

	// console.log('getValueTypeMaskedValue', value, type);

	if (value) {
		switch (type) {
			case 'entityName':
				obj.value = value?.name;
				break;

			case 'entityNames':
				const mapped = column?.extra?.mapToProperty ? value?.map((v) => v[column.extra.mapToProperty]) : value;
				obj.value = joinWithProp(mapped, 'name');
				break;

			case 'user':
				obj.value = value?.nameFirst + ' ' + value?.nameLast;
				break;

			case 'users':
				obj.value = value?.map((user) => user?.nameFirst + ' ' + user?.nameLast).join(', ');
				break;

			case 'percentage':
				obj.value = formatPercent(value, environment?.locale || 'en-US', '1.0-2');
				break;

			case 'currency':
				obj.value = formatCurrency(value, 'en-US', environment?.currencySymbol || 'USD', 'symbol', '1.0-2');
				break;

			case 'date':
				obj.value = formatDate(new Date(value), `MMM d \''\'yy`, 'en-US');
				break;

			case 'pluckFromArray':
				obj.value = pluckFromArray(
					value,
					column.aggregate.path,
					column.aggregate.function,
					column.filter?.path,
					column.filter?.value
				) as string;

				// Run our value through a mask if it needs one
				if (column?.extra?.mask) {
					if (column.extra.mask === 'currency') {
						obj.value =
							obj.value !== undefined
								? formatCurrency(+(obj.value || 0), 'en-US', environment?.currencySymbol || 'USD', 'symbol', '1.0-2')
								: '-';
					} else {
						obj.value = getNumberWithMask(column.extra.mask, obj.value, environment.currencySymbol, environment.locale) || '-';
					}
				}

				// Add a tooltip if needed
				if (column?.extra?.tooltip) {
					obj.tooltip = obj.value ? stripHtml(obj.value, true) : undefined;
				}

				// Strip out html if needed
				if (column?.extra?.stripHtml) {
					obj.value = obj.value ? stripHtml(obj.value) : undefined;
					// HACK: Need to strip html out for tooltips
					// var div: HTMLElement = document.createElement("div");
					// div.innerHTML = obj.value.replace('<br>', '\n');

					// const text = div.textContent || div.innerText || "";
					// div.remove();
				}

				// console.log('TEST: Pluck from array:', obj, column);
				break;

			case 'brandStrategy':
				if (Array.isArray(value)) {
					obj.value = pluckFromArray(value, column.extra?.property, column.extra?.reduceOperation) as string;
					obj.tooltip = obj.value;
				} else {
					obj.value = value?.body || value;
					obj.tooltip = obj.value;
				}
				break;

			case 'badge':
			case 'badges':
				// if value is object and all properties are null
				if (isObject(value) && Object.values(value).every((v) => v == null)) {
					obj.value = '';
					break;
				}

				if (!Array.isArray(value)) {
					value = [value];
				}

				let MAX_BADGES = 3;
				if (value.length > MAX_BADGES) MAX_BADGES = MAX_BADGES - 1;

				let remainingBadges;
				obj.value = value
					.map((badge, index) => {
						let color;
						let background;
						if (badge?.color) {
							color = badge.color;
						} else {
							const settingsPath = column.extra?.settingsEntity || column.path;
							const entities = settings[settingsPath];
							if (entities) {
								color = entities.find((entity) => entity.id === badge?.id)?.color;
							}
						}

						background = `style="background: ${color};`;

						if (index < MAX_BADGES) {
							return `
								<div class="mat-chip mat-focus-indicator column-badge column-badge-multi mat-primary mat-standard-chip" ${background}">
									${badge?.name || badge}
								</div>
							`;
						} else if (index === MAX_BADGES) {
							background = `style="background: #a7a7a7`;
							remainingBadges = value
								.slice(MAX_BADGES)
								.map((badge) => badge.name)
								.join(', ');
							return `
									<div id="more-button-badges" class="mat-chip mat-focus-indicator column-badge column-badge-multi mat-primary mat-standard-chip" ${background}">
										+ ${value.length - MAX_BADGES} more
									</div>
							`;
						}
					})
					.filter(Boolean) // remove undefined elements
					.join('');
				// add container
				obj.value = `<div class="column-badges-container">${obj.value}</div>`;
				obj.tooltip = remainingBadges;

				break;

			default:
				break;
		}
	}

	// Additional switch for columns that don't depend on the value.  Most of these are for group level items with children.
	switch (type) {
		case 'percentSpentComplete':
			const percentFormat = settings.settings.percentageOfSpendCompleteColumn;
			if (percentFormat === 'off') {
				break;
			}

			if (column.entityTypes && column.entityTypes?.includes(item.type?.toLowerCase())) {
				const start = item.start;
				const end = item.end;
				let val = ProgramUtils.programCompletionPercentage(start, end, new Date(), percentFormat);

				if (val > 1) {
					val = 1;
				}
				if (val < 0) {
					val = 0;
				}

				obj.value = formatPercent(val, environment?.locale || 'en-US', '1.0-0');

				break;
			}
			return {} as TableItemDataProperty;
		case 'budgetCacheValue':
			obj = getBudgetCacheTableRowValue(item, column as Column);
			break;

		case 'date-range':
			if (!item?.start || !item?.end) {
				return null;
			}

			obj = {
				value: `${formatDate(item?.start, `MMM d`, 'en-US')} - ${formatDate(item?.end, `MMM d`, 'en-US')}`,
			};
			break;

		case 'aggregated-date-range':
			// Return null if there are no children.
			if (!item?.children?.items?.length) {
				return null;
			}

			const startDate = item?.children?.items
				?.map((el) => el?.start)
				?.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
			const endDate = item?.children?.items?.map((el) => el?.end)?.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
			obj = {
				value: `${formatDate(startDate, `MMM d \''\'yy`, 'en-US')} - ${formatDate(endDate, `MMM d \''\'yy`, 'en-US')}`,
			};
			break;

		case 'pluckFromChildrenArray':
			const uniqueRetailers = [...new Set(item?.children?.items?.map((el) => el?.retailer) || [])];
			const retailers = pluckFromArray(uniqueRetailers, 'name', AggregateFunction.Join);
			obj = {
				value: retailers as string,
			};
			break;

		case 'childrenCount':
			obj = {
				value: item?.children?.totalResults,
			};
			break;

		case 'button':
			if (!(column as Column).entityTypes?.includes(item.type?.toLowerCase())) {
				return null;
			}

			const c = column as Column;
			const size = c.button?.size || 'small';
			const color = c.button?.color || 'primary';
			const type = c.button?.type || 'mat-flat-button';
			const icon = c.button?.icon ? `<i class="uil ${c.button.icon}"></i>` : '';
			let iconAlignment = '';
			if (c.button.icon) {
				if (type === 'mat-flat-button') {
					iconAlignment = 'icon-left';
				} else {
					iconAlignment = 'icon-only';
				}
			}

			let label = c.button?.label || '';
			if (childEntityType) {
				label = label.replace('##CHILD_ENTITY##', capitalize(childEntityType));
			}

			obj = {
				value: `
					<button class="select-button ${type} ${color} button-${size} ${iconAlignment}">${label}${icon}</button>
				`,
				tooltip: c.button?.tooltip.replace('##CHILD_ENTITY##', capitalize(childEntityType)),
			};
			break;

		case 'pluckFromArray':
			if (column?.extra?.aggregateElementsByParentKey && item && column.extra.aggregateElementsByParentKey === item?.type) {
				const childrenItems = (item?.children?.items || []).map((c) => c[column.path]);
				const childrenCalc = childrenItems.map(
					(array) =>
						pluckFromArray(
							array,
							column.aggregate.path,
							column.aggregate.function,
							column.filter?.path,
							column.filter?.value
						) || 0
				);

				if (column.aggregate.function === AggregateFunction.Sum) {
					obj.value = childrenCalc.reduce((a, b) => a + b, 0);
				}

				if (obj.value && column?.extra?.mask) {
					if (column.extra.mask === 'currency') {
						obj.value = formatCurrency(+(obj.value || 0), 'en-US', environment?.currencySymbol || 'USD', 'symbol', '1.0-2');
					} else {
						obj.value = getNumberWithMask(column.extra.mask, obj.value, environment.currencySymbol, environment.locale) || '-';
					}
				}
			}
			break;

		case 'custom':
			if (column?.extra?.customMapping) {
				const { arrayPath, mapPath, ensureUnique = true, joinSeparator = ', ' } = column.extra;

				const arrayToProcess = resolveDotNotationPath(arrayPath, item) || [];

				const values = arrayToProcess.map((element) => resolveDotNotationPath(mapPath, element)).filter(Boolean);

				if (ensureUnique) {
					const uniqueValues = Array.from(new Set(values));
					obj.value = uniqueValues.length ? uniqueValues.join(joinSeparator) : '';
				} else {
					obj.value = values.length ? values.join(joinSeparator) : '';
				}
			}
			break;
	}

	// console.log(value, obj);

	return flatten ? obj.value || obj.rawValue : obj;
}

/**
 * Provides the deeplink path for each entity type
 * @param row
 * @param type
 * @param parent
 * @param item
 * @param section
 */
export function getDeepLinkPath(
	row: TableItem<Entity>,
	type: string,
	parent?: TableItem<Entity>,
	item?: TableItem<Entity>,
	section?: AppSection
) {
	type = type.toLowerCase();

	switch (type) {
		case 'plan':
		case 'brandinitiative':
		case 'vendor':
			return EntityLinkPaths[type]?.replace(':id', row.id);

		case 'program':
			// Send programs to it's plan if the parent is a plan
			// If the plan is approved, go straight to the program
			const status = parent?.status?.rawValue?.id || parent?.status?.id;
			if (parent?.type === 'Plan' && status !== 'approved') {
				//console.log('Parent is plan', parent?.status, parent);
				return EntityLinkPaths[parent.type.toLowerCase()]?.replace(':id', parent.id) + `/programs`;
			} else {
				let programPath = EntityLinkPaths[type]?.replace(':id', row.id);
				if (item?.classification === ProgramClassification.MediaPlan && (section === 'media-plan' || !section)) {
					programPath = programPath.replace('program', 'media-plan');
				}
				return programPath;
			}

		case 'tactic':
			let parentId = row?.programId || row.program?.id || row?.parentId;
			if (parent?.type === 'Program') {
				parentId = row?.parentId || parent?.id;
			}
			// Handle the case where the parent is a tactic group
			if (parent?.type === 'TacticGroup') {
				return EntityLinkPaths.tacticgrouptactic
					?.replace(':parentId', parent.parentId)
					?.replace(':tacticGroupId', parentId)
					?.replace(':tacticId', row.id);
			}
			// Handle the case where the parent is a program and the tactic is in a tactic group
			if ((section === 'media-plan' && parent?.type === 'Program' && item?.tacticGroupId) || (section === 'media-plan' && !parent)) {
				return EntityLinkPaths.tacticgrouptactic
					?.replace(':parentId', parentId)
					?.replace(':tacticGroupId', item.tacticGroupId)
					?.replace(':tacticId', row.id);
			}

			return EntityLinkPaths[type]?.replace(':id', row.id).replace(':parentId', parentId);

		case 'tacticgroup':
			return EntityLinkPaths[type]?.replace(':id', row.id).replace(':parentId', row.program?.id || row.programId || row?.parentId);

		case 'invoice':
			if (parent?.type === 'Tactic') {
				return parent.deepLink + '/invoices';
			}

			const programId = row.tactic?.program?.id || parent?.parentId;
			const tacticId = row.tactic?.id || row.parentId;
			return EntityLinkPaths[type]?.replace(':id', tacticId).replace(':parentId', programId);
	}
}

const endpointToFacetsMapping = {
	tactics: TacticFacets,
	programs: ProgramFacets,
	plans: PlanFacets,
	invoices: InvoiceFacets,
	// Add more mappings as needed
};

// Mappings for the column select
const endpointToColumnSelectMapping = {
	tactics: TacticSelect,
	programs: ProgramSelect,
	plans: PlanSelect,
	invoices: InvoiceSelect,
	// Add more mappings as needed
};

export function prepareForApi(filters: Partial<FilterParameters>, endpoint?: string) {
	const obj: any = {};
	// console.log('Preparing for API', filters);

	if (filters) {
		Object.keys(filters).forEach((key) => {
			switch (key) {
				case 'budgetPeriod':
				case 'programSector':
				case 'programPhase':
				case 'program':
				case 'tacticCategory':
				case 'tacticPhase':
					// Support passing an array for this and still plucking the first value out
					if (Array.isArray(filters[key])) {
						obj[key + 'Id'] = filters[key]?.[0]?.id;
					} else {
						obj[key + 'Id'] = filters[key]?.id;
					}
					break;

				case 'tacticType':
					if (filters[key]?.id) {
						obj[key + 'Ids'] = [filters[key]?.id];
					}
					break;

				case 'mediaType':
					if (Object.values(MediaType).includes(filters[key]?.value)) {
						obj[key] = filters[key]?.value;
					}
					break;

				case 'brands':
				case 'brandInitiatives':
				case 'programPhases':
				case 'retailers':
				case 'products':
				case 'locations':
				case 'owners':
				case 'authors':
				case 'tags':
				case 'vendors':
				case 'costTypes':
				case 'tacticTypes':
				case 'programTypes':
				case 'programSectors':
				case 'fundingSources':
					// To allow old tags to be shown and not break the application
					// it will allow the user to remove them, and then add them again with the new structure
					if (filters?.[key]?.every((i) => typeof i === 'string')) {
						obj[key.slice(0, key.length - 1) + 'Ids'] = [...(filters?.[key] || [])].filter((v) => !!v);
					} else {
						obj[key.slice(0, key.length - 1) + 'Ids'] = filters?.[key]?.map((v) => v?.id).filter((v) => !!v);
					}
					break;

				case 'programs':
					let newKey = 'programIds';
					// If the endpoint is programs, we need to use 'ids' instead of 'programIds'.
					if (filters.include?.value?.endpoint === 'programs') {
						newKey = 'ids';
					}

					if (filters?.[key]?.every((i) => typeof i === 'string')) {
						obj[newKey] = [...(filters?.[key] || [])];
					} else {
						obj[newKey] = filters?.[key]?.map((v) => v?.id);
					}
					break;

				// This has special singluar/plural handling.
				case 'agencies':
					if (filters?.[key]?.every((i) => typeof i === 'string')) {
						obj['agencyIds'] = [...(filters?.[key] || [])];
					} else {
						obj['agencyIds'] = filters?.[key]?.map((v) => v?.id);
					}
					break;

				// HACK: These come through as plural but need to be singular
				case 'tacticTypeIds':
				case 'tacticCategoryIds':
				case 'tacticPhaseIds':
					obj[key.slice(0, key.length - 1)] = obj[key];
					break;

				case 'tacticCategories':
					obj['tacticCategoryIds'] = filters[key]?.map((v) => v?.id).filter((v) => !!v);
					break;

				case 'programHasFilesInCategory':
				case 'programHasPanelsInCategory':
				case 'tacticHasFilesInCategory':
					// Only send keys if the option isn't set to 'All'
					// HACK: Apparently this is sometimes an array, sometimes an object.
					let hasAll: boolean;
					if (isArray(filters[key])) {
						hasAll = filters[key]?.findIndex((c: any) => c?.name === 'All') ? true : false;
					} else {
						hasAll = filters[key]?.name === 'All' ? true : false;
					}

					if (!hasAll) {
						obj[key] = (filters[key] || [])?.map((v) => v?.id);
					}
					break;

				case 'hasNotes':
				case 'hasFiles':
				case 'hasPanels':
				case 'hasObjectives':
					if ((filters[key] ?? -1) !== -1) {
						obj[key] = filters[key];
					}
					break;
				case 'tacticsGroups':
					obj['tacticGroupIds'] = filters?.[key]?.map((v) => v?.id);
					break;
				case 'tacticGroupStatuses':
					if (filters?.[key]?.every((i) => typeof i === 'string')) {
						obj[key] = [...(filters?.[key] || [])];
					} else {
						obj[key] = filters?.[key]?.map((v) => v?.id);
					}
					break;
				case 'isRmn':
					// FIXME check and remove from default form filters (id: tactic-is-rmn)
					if (endpoint === 'tactics' && (filters[key] ?? -1) !== -1) {
						obj[key] = filters[key];
					}
					break;
				case 'groups':
					if (!filters?.groupBy) {
						obj.groupBy = filters.groups?.value;
					}
					break;

				case 'groupBy':
					// If groupBy is an object, just grab the the value
					if (isObject(filters[key])) {
						obj[key] = filters[key]?.value;
					} else if (isString(filters[key])) {
						obj[key] = filters[key];
						console.warn(
							'groupBy is a string, this can have unintended consequences for table and calendar.  Use the "groups" param and pass an object instead.'
						);
					}
					break;

				case 'include':
					// Just get the include property out of the value
					obj.include = filters.include?.value?.include || filters.include;
					break;

				case 'externalIdQuery':
					if (filters.externalIdQuery?.length > 0) {
						obj.externalIds = this.globalQuery.getValue().settings.externalIdTypes.map((ex) => ({
							externalIdTypeId: ex.id,
							query: filters.externalIdQuery || '',
						}));
					}
					break;

				case 'status':
				case 'workflowStatus':
				case 'classificationStatus':
					obj[key] = filters[key]?.id;
					break;
				case 'workflowStatuses':
					obj[key] = (filters[key] || [])?.map((v) => v?.id);
					break;
				case 'start':
					if (isDate(filters[key])) {
						obj[key] = filters[key].toISOString();
					}
					break;
				case 'end':
					if (isDate(filters[key])) {
						obj[key] = filters[key].toISOString();
						const endOfDay = new Date(obj['end']).setHours(23, 59, 59, 999);
						obj[key] = new Date(endOfDay).toISOString();
					}
					break;

				// Ignore for now
				case 'search':
				case 'perPage':
				case 'page':
				case 'compareTo':
				case 'kimberlyClarkStatus':
					break;

				case 'columnSelect':
					let columnSelect = filters[key] || [];

					// Add in brands explicitly as long as its not invoices.
					// console.log('Column Select', endpoint, columnSelect, columnSelect.find(c => c === 'brands'));
					if (endpoint && endpoint !== 'invoices' && !columnSelect.find((c) => c === 'brands')) {
						// Add 'brands' and remove duplicates
						columnSelect = columnSelect.concat('brands');
					}

					// Make sure we're only sending columns that are in the entity enum select
					if (endpointToColumnSelectMapping[endpoint]) {
						columnSelect = filterOutColumnsNotInEntitySelect(columnSelect, endpointToColumnSelectMapping[endpoint]);
					}

					obj[key] = columnSelect;
					break;

				// Additional selects for invoices
				case 'tacticColumnSelect':
				case 'programColumSelect':
					obj[key] = filters[key];
					break;

				case 'programIds':
					obj[key] = filters[key];
					break;

				case 'budgetPeriods':
					obj['budgetPeriodIds'] = filters?.[key]?.map((v) => v?.id);
					break;

				default:
					// console.log('Unhandled filter key', key, filters[key]);
					if (filters[key]) {
						obj[key] = filters[key];
					}
					break;
			}
		});
	}
	// Look through the object and remove any keys that are empty or empty arrays.
	// This is to prevent the API from sending empty values.
	Object.keys(obj).forEach((key) => {
		if (
			obj[key] === undefined ||
			obj[key] === null ||
			obj[key] === '' ||
			(isArray(obj[key]) && obj[key].filter((v) => !!v).length === 0)
		) {
			delete obj[key];
		}
	});

	const objPropertiesLookup = endpointToFacetsMapping[endpoint];

	if (!objPropertiesLookup) {
		return obj;
	}

	const listOfWhitelistedProperties = getMarkedProperties(objPropertiesLookup);

	Object.keys(obj).forEach((key: string) => {
		if (!listOfWhitelistedProperties.includes(key)) {
			delete obj[key];
		}
	});
	// If we have a budgetPeriodIds, we don't need the budgetPeriodId
	if (obj?.budgetPeriodIds?.length) {
		delete obj?.budgetPeriodId;
	}

	// console.log('Prepared for API', obj);

	return obj;
}

/**
 * Takes a group of entities with start and end dates and generates a calendar date range with a buffer.
 * Meant for use with the `table-calendar` component.
 * @param entities
 * @param monthBuffer Number of months to buffer on either side of the date range
 */
export function getCalendarDateRange(entities: Entity[], monthBuffer?: number) {
	if (!entities || entities.length === 0) {
		return undefined;
	}

	let start = new Date(entities.map((t) => t.start || t.startRaw).sort((a, b) => (Date.parse(a) > Date.parse(b) ? 1 : -1))[0]);
	let end = new Date(
		entities
			.map((t) => t.end || t.endRaw)
			.sort((a, b) => (Date.parse(a) < Date.parse(b) ? 1 : -1))
			.filter((d) => d)[0]
	);

	if (isNaN(start.getTime()) || isNaN(end.getTime())) {
		return undefined;
	}

	// Buffer dates by specified months
	start = new Date(start.setMonth(start.getMonth() - monthBuffer));
	end = new Date(end.setMonth(end.getMonth() + monthBuffer));

	return {
		start: start?.toISOString(),
		end: end?.toISOString(),
	};
}

/**
 * Takes a group of budget periods and generates earliest and latest date range.
 *
 * @param budgetPeriods
 * @param monthBuffer Number of months to buffer on either side of the date range
 */
export function getCalendarDateRangeFromBudgetPeriods(budgetPeriods: BudgetPeriod[] | Partial<BudgetPeriod>[], monthBuffer?: number) {
	const { earliest, latest } = findEarliestAndLatestDate(budgetPeriods);

	// Buffer dates by specified months
	const start = new Date(earliest.setMonth(earliest.getMonth() - monthBuffer));
	const end = new Date(latest.setMonth(latest.getMonth() + monthBuffer));

	return {
		start: start?.toISOString(),
		end: end?.toISOString(),
	};
}

export function getTableRowPaginationParams(row: TableRow<any>, params: FilterParameters, endpoint: string, columns?: Column[]) {
	let rowParams: FilterParameters = {
		...params,
	};
	// Use the default sort if none is provided
	if (!rowParams.sort || Object.keys(rowParams.sort)?.length === 0) {
		rowParams.sort = DefaultSortCollection;
	}

	// If we're changing endpoints, we need to wipe some filters
	// Override this by clearing the currentEndpoint before gettings new params
	// NOTE: Commenting this out as it is not used currently
	// const endpoint = this.form.value.include.value.endpoint;

	// Remove some of the top level filters for nested pagination
	if (row.parentType) {
		const modifiedSort = adjustSortLevels(rowParams.sort);
		// If this is a nested child of the main endpoint type, strip out the details except sort
		if (EntityPlurals[row.listType?.toLowerCase()] !== endpoint) {
			rowParams = {
				...rowParams.sort[`level${row.level}`],
			};
		} else if (
			Object.keys(modifiedSort.level1)?.length ||
			Object.keys(modifiedSort.level2)?.length ||
			Object.keys(modifiedSort.level3)?.length
		) {
			rowParams.sort = modifiedSort;
		}

		// Lowercase just the first letter of the parent type
		// This is only for entities underneath a group so that the proper group filter is applied
		if (row.parentId && row.level < 2) {
			const parentType = getFilterParamByType(row.parentType);

			// Add parent as part of the filters
			rowParams[`${parentType}`] = [{ id: row.parentId }];
		}
	}

	if (columns) {
		rowParams.columnSelect = getColumnDependencies(columns);
	}

	return rowParams;
}

function adjustSortLevels(sortObj: SortRequest): SortRequest {
	const newSortObj = {
		outer: { ...sortObj.level1 }, // Move level1 to outer
		level1: { ...sortObj.level2 }, // Move level2 to level1
		level2: { ...sortObj.level3 }, // Move level3 to level2
		level3: { ...sortObj.level3 }, // Retain level3 (optional, or you can remove this)
	};
	// Remove empty objects
	Object.entries(newSortObj).forEach(([key, value]) => {
		if (Object.keys(value).length === 0) {
			delete newSortObj[key];
		}
	});

	return newSortObj;
}

/**
 * Get the dependencies for all columns
 */
export function getColumnDependencies(columns?: Column[]): string[] {
	const dependencies: string[] = columns.map((column) => column.dependencies || []).reduce((acc: any, val) => acc?.concat(val), []);

	return [...new Set(dependencies)];
}

export function getEntitiesFromInclude(include: IncludeOption) {
	let entities = [];

	if (!include?.value) return entities;

	entities.push(include.value.endpoint);

	if (include.value.include?.length > 0) {
		entities = entities.concat(include.value.include);
	}

	return entities;
}

/**
 * Pass in row types from the table to get the proper parameter name for filtering.
 * Defaults to a lowercased first letter version of the type, but allows for us to make certain
 * properties pluralized.
 * @param type Row type from the API.
 */
export function getFilterParamByType(type: string) {
	const parentTypeLowered = type.charAt(0).toLowerCase() + type.slice(1);

	switch (parentTypeLowered) {
		case 'program':
		case 'brand':
		case 'brandInitiative':
		case 'retailer':
		case 'product':
		case 'owner':
		case 'author':
		case 'tag':
		case 'vendor':
			return parentTypeLowered + 's';

		default:
			return parentTypeLowered;
	}
}

/**
 * Retrieve an entity out of the raw table data.  Requires recursion to find the entity.
 * @param id The id of the entity to retrieve.
 * @param collection The collection of entities to search through
 * @param parent The parent entity of the collection
 * @returns
 */
export function getEntityFromRawData(id: string, collection?: TableCollection<Entity>, parent?: Entity) {
	let match = undefined;

	if (!collection) {
		console.warn('No collection provided to getEntityFromRawData');
		return undefined;
	}

	collection.items?.every((item) => {
		if (item.id === id) {
			// Add the parent but remove parent children
			match = {
				...item,
				parent: { ...parent, children: undefined },
				type: collection.type,
			};
			return false;
		} else {
			if (item.children) {
				// Recurse through the children
				match = getEntityFromRawData(id, item.children, { ...item, type: collection.type });

				if (match) {
					return false;
				}
			}
		}

		return true;
	});

	return match;
}

export function mergeEntityInRawData(id: string, entity: Partial<Entity>, collection?: TableCollection<Entity>, parent?: Entity) {
	if (!collection) {
		console.warn('No collection provided to getEntityFromRawData');
		return undefined;
	}

	return {
		...collection,
		items: collection.items.map((item) => {
			if (item.id === id) {
				// Add the parent but remove parent children
				return {
					...item,
					...entity,
				};
			} else {
				if (item.children) {
					// Recurse through the children
					return {
						...item,
						children: mergeEntityInRawData(id, entity, item.children, item),
					};
				} else {
					return item;
				}
			}
		}),
	};
}

export function getDefaultActiveColumns(entity: string, appSection?: AppSection) {
	switch (entity) {
		case 'plans':
			return PlanDefaultActiveColumns;
		case 'programs':
			if (appSection === 'media-plan') {
				return MediaPlanningDefaultActiveColumns;
			}
			return ProgramDefaultActiveColumns;
		case 'tactics':
			return TacticDefaultActiveColumns;
		case 'invoices':
			return InvoiceDefaultActiveColumns;
	}
}

export function getValidGroupForEndpoint(endpoint: string, groupId?: string) {
	let groupObject;

	switch (endpoint) {
		case 'plans':
			groupObject = PlanFilterCollection.find((group) => group.id === 'plan-group-by');
			break;

		case 'programs':
			groupObject = ProgramFilterCollection.find((group) => group.id === 'program-group-by');
			break;

		case 'tactics':
			groupObject = TacticFilterCollection.find((group) => group.id === 'tactic-group-by');
			break;

		case 'invoices':
			groupObject = InvoiceFilterCollection.find((group) => group.id === 'invoice-group-by');
			break;
	}

	if (!groupObject) {
		console.warn('No group object found for', endpoint);
		return undefined;
	}

	if (groupId) {
		console.log('Looking for group', groupId, 'in', groupObject.options);
		const group = groupObject.options.find((item) => item.id === groupId);
		if (group) {
			console.log('Found group', group);
			return group;
		}
	}

	console.log('Returning default group', groupObject.options[0]);
	return groupObject.options[1];
}

export function getColumnCollectionsWithMasks(collections: ColumnCollection[], entitySettings: EntitySettings): ColumnCollection[] {
	return collections.map((collection) => ({
		...collection,
		items: collection.items.map((item) => ({
			...item,
			name: resolveDotNotationPath(item.extra?.maskPath, entitySettings) || item.name,
		})),
	}));
}

/**
 * Attempt to get the raw value of a table row.  Fallback to the property itself if the row does not have a rawValue.
 * @param row
 */
export function getTableRowPropertyRawValue(property: any) {
	if (isObject(property) && Object.prototype.hasOwnProperty.call(property, 'rawValue')) {
		return property.rawValue;
	} else {
		return property;
	}
}

export function calculateWeekNumber(date, periodStart: Date) {
	const yearStart = new Date(Date.UTC(periodStart.getUTCFullYear(), periodStart.getMonth(), periodStart.getDate()));
	const yearStartPlusOneYar = new Date(Date.UTC(periodStart.getUTCFullYear() + 1, periodStart.getMonth(), periodStart.getDate()));
	if (date.getTime() < yearStart.getTime()) {
		while (date.getTime() < yearStart.getTime()) {
			yearStart.setUTCFullYear(yearStart.getUTCFullYear() - 1);
		}
	} else if (date.getTime() > yearStartPlusOneYar.getTime()) {
		while (date.getTime() > yearStartPlusOneYar.getTime()) {
			yearStartPlusOneYar.setUTCFullYear(yearStartPlusOneYar.getUTCFullYear() + 1);
			yearStart.setUTCFullYear(yearStart.getUTCFullYear() + 1);
		}
	}
	return Math.ceil(((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
}

/**
 * Check chart data and slice it if it's too long.
 * This is to prevent the chart from being too wide.
 * The remaining data is added to the last slice as Other.
 */
export function slicePieChartData(
	data: any[],
	options: GraphMergeOptionsChartConstraints,
	key: string = 'y',
	otherData: any = {},
	sortDesc: boolean = true
): any {
	const maxCount = options?.maxCount;
	const minPercentage = options?.minPercentage;

	if (!maxCount && !minPercentage) return data;

	const sorted = sortDesc ? data.sort((a, b) => b[key] - a[key]) : data;
	const total = sorted.reduce((previousValue, currentValue) => previousValue + currentValue[key], 0);
	const minCount = minPercentage ? total * minPercentage : 0;
	const sliceAt = maxCount
		? maxCount
		: sorted.findIndex((item, index) => {
				const count = sorted.slice(0, index).reduce((previousValue, currentValue) => previousValue + currentValue[key], 0);
				return count > minCount;
		  });
	const sliced = sorted.slice(0, sliceAt);
	if (data.length > sliceAt) {
		const breakout = new Map();
		sorted.slice(sliceAt).forEach((item, i) => {
			const name = item?.name || item?.label;
			if (breakout.has(name)) {
				breakout.set(name, breakout.get(name) + item[key]);
			} else {
				breakout.set(name, item[key]);
			}
		});
		sliced.push({
			name: 'Other',
			selected: false,
			y: sorted.slice(sliceAt).reduce((previousValue, currentValue) => previousValue + currentValue[key], 0),
			color: defaultStyleOptions.colors[sliceAt],
			...otherData,
			breakout,
		});
	}
	return sliced;
}

export function addTableBasicStylesForPreHeaderColumns(
	htmlElement: HTMLElement,
	columnRowWidth: string,
	styleMatPagination = true,
	firstColumnWidth?: string
): void {
	const tableBasicArray = htmlElement?.querySelectorAll('#app-table-basic');

	let tableBasic;

	for (let i = 0; i < tableBasicArray.length; i++) {
		const t = tableBasicArray[i];
		// element classList does not contain "hide-table-header" which is child table
		if (!t.classList.contains('hide-table-header')) {
			tableBasic = t;
			break;
		}
	}

	if (tableBasic) {
		// Table
		tableBasic.style.overflowX = 'visible';

		const tbody = tableBasic.querySelector('tbody');

		tbody.style.borderBottom = 'none';

		// Columns
		const secondTr = tableBasic.querySelector('thead tr:nth-child(2)') as HTMLElement;
		secondTr.style.display = 'flex';
		const ths = secondTr.querySelectorAll('th');
		ths.forEach((th, thIndex) => {
			th.style.display = 'flex';
			th.style.alignItems = 'center';
			th.style.minWidth = thIndex === 0 && firstColumnWidth ? firstColumnWidth : columnRowWidth;
		});

		// Rows
		const trs = tableBasic.querySelectorAll('tbody tr:not(.table-detail-row)');
		trs.forEach((tr: HTMLElement) => {
			const validTr = tr.querySelectorAll('td').length > 1;
			const existingTrStyles = tr.getAttribute('style') || '';
			tr.setAttribute('style', `${existingTrStyles} border-bottom: none !important;`);
			if (validTr) {
				tr.style.display = 'flex';
				tr.style.minHeight = '48px';
				const tds = tr.querySelectorAll('td');
				tds.forEach((td) => {
					const existingStyles = td.getAttribute('style') || '';
					// check if it's a nested table by going up the tree (td > tr > tbody > table > app-table-basic (.inner-table))
					const isNestedTable = td.closest('.inner-table') !== null;
					const tdBorderBottom = isNestedTable ? 'none' : '1px solid var(--color-step-100)';
					td.setAttribute(
						'style',
						`${existingStyles} display: flex; min-width: ${columnRowWidth}; border-bottom: ${tdBorderBottom} !important;`
					);
				});
			}
		});

		if (styleMatPagination) {
			// Mat paginator
			const matPaginator = htmlElement.querySelector('mat-paginator') as HTMLElement;
			matPaginator.style.position = 'relative';
			matPaginator.style.height = '64px';
			const firstDiv = matPaginator.querySelector('div:first-child') as HTMLElement;
			firstDiv.style.position = 'fixed';
			firstDiv.style.right = '20px';
		}
	}
}

export function exportToCsv(elementRef: ElementRef): void {
	const element = elementRef.nativeElement.querySelector('table');
	const rows = element.querySelectorAll('tr');
	let csvContent = '';

	rows.forEach((row) => {
		const cols = row.querySelectorAll('td, th');
		let rowString = '';

		cols.forEach((col) => {
			rowString += '"' + (col.innerText || col.textContent).replace(/"/g, '""') + '",';
		});

		rowString = rowString.slice(0, -1); // Remove trailing comma
		csvContent += rowString + '\r\n';
	});

	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

	saveAs(blob, 'allocated-budget-by-program');
}
// Get marked properties in a class
function getMarkedProperties(target: () => any): string[] {
	return Reflect.getMetadata('properties', target) || [];
}

function filterOutColumnsNotInEntitySelect(
	columns: string[],
	enumObj: PlanSelect | ProgramSelect | TacticSelect | InvoiceSelect
): string[] {
	// Get the values of the enum as an array
	const enumValues = Object.values(enumObj);

	// Filter the columns that exist in the enum values
	return columns.filter((column) => enumValues.includes(column));
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findByInArrayCustom',
})
export class FindByInArrayCustomPipe implements PipeTransform {
  transform<T>(
    values: T[],
    compare: (...args) => boolean,
    ...args
  ): T | undefined {
    if (values) {
      return values.find((val) => compare(val, ...args));
    }
    return undefined;
  }
}

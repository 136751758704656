<h2 mat-dialog-title>Manage Reviewers</h2>

<div mat-dialog-content>
	<div [formGroup]="form" class="add mb-7">
		<app-filter-input [filter]="reviewersFilter" [fileId]="data?.file?.id" [formGroup]="form"></app-filter-input>

		<div class="content-row">
			<div class="left">
				<!-- Role -->
				<app-button-select
					(valueChanged)="form.patchValue({ role: $event })"
					[buttonText]="form.value.role?.name || 'Role'"
					[currentValue]="form?.get('role')?.value"
					[options]="reviewerRoleLabels"
					buttonColor="tertiary"
					buttonText="Role"
					iconName="uil-file-search-alt"
					selectLayout="singles"
				></app-button-select>
			</div>
			<div class="right">
				<!-- Add -->
				<button
					(click)="addReviewers()"
					[disabled]="!form.value.reviewers?.length"
					[matTooltip]="'Add Reviewers above.  Hit enter to add each reviewer.'"
					class="button-small"
					mat-flat-button
				>
					<span>Add</span>
				</button>
			</div>
		</div>
		<ng-container *ngIf="data.isInitialReview">
			<div class="input-group">
				<mat-form-field class="full-width no-hint">
					<mat-label>Add Message</mat-label>
					<textarea formControlName="message" matInput rows="2"></textarea>
				</mat-form-field>
			</div>

			<div class="input-group">
				<mat-form-field class="input">
					<mat-label>Feedback Due Date</mat-label>
					<input [matDatepicker]="dueDate" formControlName="feedbackDueDate" [min]="minDate" matInput />
					<mat-datepicker-toggle [for]="dueDate" matSuffix></mat-datepicker-toggle>
					<mat-datepicker #dueDate></mat-datepicker>
				</mat-form-field>
			</div>
		</ng-container>
	</div>
	<div *ngIf="data.file.approvers || (filePendingReviewers | filterArrayBy: 'role':ReviewerRole.Approver)?.length" class="user-list">
		<div class="title-row">
			<h4>Approvers</h4>
		</div>

		<table>
			<tr *ngFor="let approver of data.file.approvers">
				<td>
					<small>
						{{ approver | userDisplayName: false:true }}
						<a [href]="'mailto:' + approver?.email" target="_blank" rel="noopener noreferrer">
							{{ approver?.email }}
						</a>
					</small>
				</td>
				<td class="text-align-right">
					<button
						(click)="removeReviewer(approver, ReviewerRole.Approver)"
						class="button-extra-small"
						color="danger"
						mat-mini-fab
					>
						<i class="uil-trash-alt"></i>
					</button>
				</td>
			</tr>
			<!--			Pending approver -->
			<ng-container *ngFor="let pendingApprover of filePendingReviewers | filterArrayBy: 'role':ReviewerRole.Approver">
				<tr *ngFor="let pendingApprover of pendingApprover?.reviewers ?? []">
					<td>
						<small>
							{{ pendingApprover | userDisplayName: false:true }}
							<a [href]="'mailto:' + pendingApprover?.email" target="_blank" rel="noopener noreferrer">
								{{ pendingApprover?.email }}
							</a>
						</small>
					</td>
					<td class="text-align-right">
						<button
							(click)="removePendingReviewer(pendingApprover, ReviewerRole.Approver)"
							class="button-extra-small"
							color="danger"
							mat-mini-fab
						>
							<i class="uil-trash-alt"></i>
						</button>
					</td>
				</tr>
			</ng-container>
		</table>
	</div>

	<div *ngIf="data.file.reviewers || (filePendingReviewers | filterArrayBy: 'role':ReviewerRole.Reviewer)?.length" class="user-list">
		<div class="title-row">
			<h4>Reviewers</h4>
		</div>

		<table>
			<tr *ngFor="let reviewer of data.file.reviewers">
				<td>
					<small>
						{{ reviewer | userDisplayName: false:true }}
						<a [href]="'mailto:' + reviewer?.email" target="_blank" rel="noopener noreferrer">
							{{ reviewer?.email }}
						</a>
					</small>
				</td>
				<td class="text-align-right">
					<button
						(click)="removeReviewer(reviewer, ReviewerRole.Reviewer)"
						class="button-extra-small"
						color="danger"
						mat-mini-fab
					>
						<i class="uil-trash-alt"></i>
					</button>
				</td>
			</tr>
			<!--			Pending reviewer -->
			<ng-container *ngFor="let pendingReviewer of filePendingReviewers | filterArrayBy: 'role':ReviewerRole.Reviewer">
				<tr *ngFor="let pendingReviewer of pendingReviewer?.reviewers ?? []">
					<td>
						<small>
							{{ pendingReviewer | userDisplayName: false:true }}
							<a [href]="'mailto:' + pendingReviewer?.email" target="_blank" rel="noopener noreferrer">
								{{ pendingReviewer?.email }}
							</a>
						</small>
					</td>
					<td class="text-align-right">
						<button
							(click)="removePendingReviewer(pendingReviewer, ReviewerRole.Reviewer)"
							class="button-extra-small"
							color="danger"
							mat-mini-fab
						>
							<i class="uil-trash-alt"></i>
						</button>
					</td>
				</tr>
			</ng-container>
		</table>
	</div>
</div>

<div align="start" mat-dialog-actions>
	<div class="right margin-left-auto flex">
		<button
			style="min-width: 120px; padding: 0"
			(click)="dismiss()"
			[mat-dialog-close]="false"
			class="button-extra-small"
			mat-button
			mat-stroked-button
		>
			<span>Close</span>
		</button>
		<button
			*ngIf="data.isInitialReview"
			(click)="dismiss(true)"
			[disabled]="!filePendingReviewers?.length || !form.value.feedbackDueDate"
			[mat-dialog-close]="data"
			class="remove button-extra-small"
			mat-button
			mat-flat-button
		>
			<span>Send</span>
		</button>
		<button
			*ngIf="!data.isInitialReview"
			(click)="dismiss(true)"
			[mat-dialog-close]="data"
			class="remove button-extra-small"
			mat-button
			mat-flat-button
		>
			<span>Done</span>
		</button>
	</div>
</div>

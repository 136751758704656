import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mapArrayBy'
})
export class MapArrayByPipe implements PipeTransform {
	transform(value: any[], objKey: string, prefix?: string): any[] {
		if (value && objKey && prefix) {
			return value.filter(v => !!v).map(val => prefix + val[objKey]);
		} else if (value && objKey) {
			return value.filter(v => !!v).map(val => val[objKey]);
		}
		return value;
	}
}

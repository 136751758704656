import { FileCategory } from './../../../../../state/entities/file/file.model';
import { Program } from './../../../../../state/entities/program/program.model';
import { FileService } from './../../../../../state/entities/file/file.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploadRequest } from '../../../../../state/entities/file/file.model';
import { from, of, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { GlobalService } from '../../../../../state/global/global.service';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PublicFile } from '../../../../../../../../api/src/file/file.entity';
import { FileQuery } from '../../../../../state/entities/file/file.query';

@Component({
	selector: 'app-strategic-brief-dialog-file-upload',
	templateUrl: 'file-upload.component.html',
	styleUrls: ['file-upload.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class StrategicBriefDialogFileUploadComponent implements OnInit {
	@Input() fileIds: string[];
	@Input() program: Program;
	@Input() showActions = true;

	@Output() fileAdded = new EventEmitter<string>();
	@Output() fileRemoved = new EventEmitter<string>();

	loader = 0;

	files$: Observable<PublicFile[]>;

	constructor(
		private readonly fileService: FileService,
		private readonly globalService: GlobalService,
		private readonly cdr: ChangeDetectorRef,
		private readonly fileQuery: FileQuery
	) {
		this.files$ = this.fileQuery.selectAll().pipe(
			map(files => files.filter(file => this.fileIds.includes(file.id)))
		);
	}

	ngOnInit() : void {

	}

	onFileDrop(files: FileList) : void {
		this.loader = files.length;
		from(Array.from(files)).pipe(
			mergeMap(file => this._upload(file)),
		).subscribe((res) => {
			if(res instanceof HttpResponse) {
				this.loader = this.loader - 1;
				this.fileIds = [...this.fileIds, res.body.id];
				this.fileAdded.emit(res.body.id);
				this.fileService.add(res.body);
				if(!this.loader){
					this.cdr.markForCheck();
				}
			}
		});
	}

	onFileRemove(file: PublicFile) : void {
		this.fileRemoved.emit(file.id);
	}

	private _upload(file: File): Observable<HttpEvent<any>> {
		const fileReq : FileUploadRequest = {
			name: file.name,
			category: {
				id: 'strategyDocument',
				name: 'Strategy Document'
			},
			program: this.program,
			data: file
		};

		return this.fileService.upload(fileReq, this.program.id, 'program').pipe(
			catchError((err: HttpErrorResponse) => {
				this.globalService.triggerErrorMessage(err);
				return throwError(err);
			})
		)
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalQuery } from '../../global/global.query';
import { Tactic } from '../tactic/tactic.model';
import { Milestones } from './milestones.model';
import { MilestonesStore } from './milestones.store';

/**
 * Milestones Service
 * This service handles the logic for the milestones and API calls.
 */
@Injectable({ providedIn: 'root' })
export class MilestonesService {
	constructor(private milestonesStore: MilestonesStore, private http: HttpClient, private readonly globalQuery: GlobalQuery) {}

	get(tacticId, obj) {
		this.milestonesStore.setLoading(true);
		return this.http.post<Milestones[]>(`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/milestone/find`, '').pipe(
			tap(response => {
				this.milestonesStore.set(response);
				this.milestonesStore.setLoading(false);
			})
		)}
	/**
	 * Set the list of milestones on the Akita store to be what is passed in.
	 */
	set(milestones: Milestones[]) {
		this.milestonesStore.setLoading(false)
		this.milestonesStore.set(milestones?.map(milestones => this.prepareForAkita(milestones)) || []);
	}

	/**
	 * Create an milestones on the API.
	 */
	create(tacticId: Tactic['id'], milestones: Milestones) {
		this.milestonesStore.setLoading(true);

		return this.http
			.post<Milestones>(
				`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/milestone`,
				this.prepareForApi(milestones)
			)
			.pipe(
				tap(newValue => {
					this.milestonesStore.update(milestones.id, this.prepareForAkita(newValue));
					this.milestonesStore.setLoading(false);
				})
			);
	}

	/**
	 * Add an milestones to the Akita store.
	 */
	add(milestones: Milestones) {
		this.milestonesStore.setLoading(false)
		this.milestonesStore.add(milestones);
	}

	/**
	 * Update an milestones on the API.
	 * If the milestones doesn't have a `created` field, it will only be updated in the Akita store. This is for new milestones before they are saved to the API.
	 */
	update(tacticId: Tactic['id'], id: Milestones['id'], milestones: Partial<Milestones>, skipHTTP?: boolean) {
		this.milestonesStore.setLoading(true);

		if (skipHTTP) {
			this.milestonesStore.update(id, { ...milestones });
			return EMPTY;
		}

		if (milestones.created) {
			return this.http
				.put<Milestones>(
					`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/milestone/${id}`,
					this.prepareForApi(milestones)
				)
				.pipe(
					tap(newValue => {
						this.milestonesStore.setLoading(false);
						this.milestonesStore.update(milestones.id, this.prepareForAkita(newValue));
					}),
					catchError(err => {
						this.milestonesStore.setLoading(false);
						this.milestonesStore.update(milestones.id, this.milestonesStore.getValue().entities[milestones.id]);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita Only', milestones);
			this.milestonesStore.setLoading(false);
			this.milestonesStore.update(milestones.id, this.prepareForAkita(milestones));
			return of(milestones);
		}
	}

	/**
	 * Remove an milestones from the API.
	 */
	remove(tacticId: Tactic['id'], id: Milestones['id']) {
		this.milestonesStore.setLoading(true);

		return this.http
			.delete<Milestones>(`${environment.apiUrl}/organization/${environment.organizationId}/tactic/${tacticId}/milestone/${id}`)
			.pipe(
				tap(newValue => {
					this.milestonesStore.remove(id);
					this.milestonesStore.setLoading(false);
				})
			);
	}

	/**
	 * Change the Akita loading state for this store.
	 */
	setLoading(state: boolean) {
		this.milestonesStore.setLoading(state);
	}

	/**
	 * Normalize an milestones for Akita.
	 */
	prepareForAkita(milestones: Partial<Milestones>): Milestones {
		const obj: Partial<Milestones> = {};
		const settings = this.globalQuery.getValue().settings;

		console.log('Preparing for Akita', milestones);

		if (milestones) {
			Object.keys(milestones).forEach(key => {
				switch (key) {
					default:
						obj[key] = milestones[key];
						break;
				}
			});
		}
		this.setLoading(false)
		console.log('Akita Ready', obj);
		return obj as Milestones;
	}

	/**
	 * Normalize an milestones for the API.
	 */
	prepareForApi(milestones: Partial<Milestones>) {
		const obj = {};

		console.log('Preparing for API', milestones);

		if (milestones) {
			Object.keys(milestones).forEach(key => {
				switch (key) {
					case 'tactic':
						obj[key + 'Id'] = milestones[key];
						break;

					case 'program':
						obj[key + 'Id'] = milestones[key];
						break;

					case 'id':
					case 'created':
					case 'author':
						break;

					case 'brandAllocations':
						obj['brandAllocations'] = milestones[key]?.map(brand => ({
							brandId: brand.id,
							split: String(1 / milestones[key].length)
						}));
						break;

					default:
						obj[key] = milestones[key];
						break;
				}
			});
		}

		this.milestonesStore.setLoading(false)
		return obj;
	}
}

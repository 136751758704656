<div class="font-size-medium">{{ program?.name }}</div>
<div class="font-size-xs">Last submitted: {{ program?.programStrategicBrief?.versionDate | date: 'dd MMMM yyyy \'at\' h:mm a' }}</div>

<div class="row mt-5" fxLayout="row">
	<div class="mr-2">Client Lead{{ clientLeads.length > 1 ? 's' : '' }}: <b>{{ clientLeads | joinWithProp: '' }}</b></div>
	<div class="mr-2">Account Lead{{ accountLeads.length > 1 ? 's' : '' }}: <b>{{ accountLeads | joinWithProp: '' }}</b></div>
	<div>Deliverable{{ deliverables.length > 1 ? 's' : '' }}: <b>{{ deliverables | joinWithProp: '' }}</b></div>
</div>

<div class="row mt-2" fxLayout="row">
	<div class="mr-2">Primary Brand{{ primaryBrands.length > 1 ? 's' : '' }}: <b>{{ primaryBrands | joinWithProp: '' }}</b></div>
	<div class="mr-2">Secondary Brand{{ secondaryBrands.length > 1 ? 's' : '' }}: <b>{{ secondaryBrands | joinWithProp: '' }}</b></div>
	<div>Retailer: <mat-chip>{{ program?.retailer?.name }}</mat-chip></div>
</div>

<div class="row mt-2" fxLayout="row">
	<div class="mr-2">Live Dates: <b>
		{{ program?.programStrategicBrief?.data?.liveDatesStartDate | date: 'longDate'  }} - {{ program?.programStrategicBrief?.data?.liveDatesEndDate | date: 'longDate'  }}
		</b>
	</div>
	<div>Budget: <b>{{ program?.programStrategicBrief?.data?.budget }}</b></div>
</div>

<div class="column mt-4" fxLayout="column">
	<div class="mb-1"><b>Milestones</b></div>
	<app-strategic-brief-dialog-milestones [program]="program" [viewOnly]="true"></app-strategic-brief-dialog-milestones>
</div>

<ng-container *ngIf="showMore">
	<div *ngFor="let el of textAreaDataObjects">
		<div class="font-size-large mb-2 mt-5">{{ el.title }}</div>
		<div class="grid-half">
			<div class="column" fxLayout="column" *ngFor="let textArea of el.textAreas">
				<div class="mb-2"><b>{{ textArea.label }}</b></div>
				<div>
					{{ program?.programStrategicBrief?.data[textArea.controlName] }}
				</div>
			</div>
		</div>
	</div>

	<div class="column mt-5" fxLayout="column">
		<div class="mb-1"><b>Files</b></div>
		<div *ngIf="exportView; else fileUploadRef">
			<div>{{ filesNames | joinWithProp: '' }}</div>
		</div>

		<ng-template #fileUploadRef>
			<app-strategic-brief-dialog-file-upload
			*ngIf="(files$ | async)?.length"
			[fileIds]="fileIds"
			[program]="program"
			[showActions]="false"
		></app-strategic-brief-dialog-file-upload>
		</ng-template>

	</div>
</ng-container>

<div class="border-top pt-4 mt-4" style="border-color: var(--color-step-100)" (click)="showMore = !showMore" *ngIf="!exportView">
	<button mat-button class="button-small icon-left">
		<i [ngClass]="showMore ? 'uil-angle-down' : 'uil-angle-right'"></i>
		Show {{ showMore ? 'less' : 'more' }}
	</button>
</div>

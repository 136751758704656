import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberToCurrency'
})

export class NumberToCurrencyPipe implements PipeTransform {
	transform(
		value: number,
		currency: string = '$',
		locales: string | string[] = 'en-US',
		options: Intl.NumberFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
	): string {
		return currency + value.toLocaleString(locales, options);
	}
}

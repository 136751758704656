import { PlanSelect, Query as PlanQuery } from '../../plan/utils/query.utils';

export enum BudgetAllocationSelect {
	FundingSource = 'fundingSource',
	FundingType = 'fundingType',
	BrandAllocations = 'brandAllocations',
	BudgetDistributionGroups = 'budgetDistributionGroups',
	Author = 'author',
	Plan = 'plan',
	ChildBudgetAllocations = 'childBudgetAllocations',
}

export class Query {
	private static readonly BUDGET_ALLOCATION_QUERY_ALIAS = 'ba';
	private static defaultSelects: BudgetAllocationSelect[] = Object.values(BudgetAllocationSelect).filter(
		(v) => ![BudgetAllocationSelect.Plan, BudgetAllocationSelect.ChildBudgetAllocations].includes(v)
	);

	public static getSelects(
		alias: string = this.BUDGET_ALLOCATION_QUERY_ALIAS,
		targets: BudgetAllocationSelect[] = this.defaultSelects,
		asJsonSelect: boolean = false
	) {
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'fundingSourceId', ${alias}."fundingSourceId"` : `${alias}."fundingSourceId"`}
		`);
		selects.push(`
			${json ? `'fundingTypeId', ${alias}."fundingTypeId"` : `${alias}."fundingTypeId"`}
		`);
		selects.push(`
			${json ? `'planId', ${alias}."planId"` : `${alias}."planId"`}
		`);
		selects.push(`
			${json ? `'programId', ${alias}."programId"` : `${alias}."programId"`}
		`);
		selects.push(`
			${json ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${json ? `'amountPlanned', ${alias}."amountPlanned"` : `${alias}."amountPlanned"`}
		`);
		selects.push(`
			${json ? `'amountActual', ${alias}."amountActual"` : `${alias}."amountActual"`}
		`);
		selects.push(`
			${json ? `'deleted', ${alias}.deleted` : `${alias}.deleted`}
		`);
		selects.push(`
			${json ? `'eventId', ${alias}."eventId"` : `${alias}."eventId"`}
		`);
		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);

		if (targets.includes(BudgetAllocationSelect.FundingSource)) {
			if (json) {
				selects.push(`
					'fundingSource', ${alias}_fundingsource."fundingSource"
				`);
			} else {
				selects.push(`
					${alias}_fundingsource."fundingSource" AS "fundingSource"
				`);
			}
		}

		if (targets.includes(BudgetAllocationSelect.FundingType)) {
			if (json) {
				selects.push(`
					'fundingType', ${alias}_fundingtype."fundingType"
				`);
			} else {
				selects.push(`
					${alias}_fundingtype."fundingType" AS "fundingType"
				`);
			}
		}

		if (targets.includes(BudgetAllocationSelect.BrandAllocations)) {
			if (json) {
				selects.push(`
					'brandAllocationsPlanned', ${alias}_brandallocationsplanned."allocationsArr"
				`);
				selects.push(`
					'brandAllocationsActual', ${alias}_brandallocationsactual."allocationsArr"
				`);
			} else {
				selects.push(`
					${alias}_brandallocationsplanned."allocationsArr" AS "brandAllocationsPlanned"
				`);
				selects.push(`
					${alias}_brandallocationsactual."allocationsArr" AS "brandAllocationsActual"
				`);
			}
		}

		if (targets.includes(BudgetAllocationSelect.BudgetDistributionGroups)) {
			if (json) {
				selects.push(`
					'budgetDistributionGroups', ${alias}_budgetdistributiongroups."distributionGroupsArr"
				`);
			} else {
				selects.push(`
					${alias}_budgetdistributiongroups."distributionGroupsArr" AS "budgetDistributionGroups"
				`);
			}
		}

		if (targets.includes(BudgetAllocationSelect.Author)) {
			if (json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		if (targets.includes(BudgetAllocationSelect.Plan)) {
			if (json) {
				selects.push(`
					'plan', ${alias}_plan."plan"
				`);
			} else {
				selects.push(`
					${alias}_plan."plan" AS "plan"
				`);
			}
		}

		if (targets.includes(BudgetAllocationSelect.ChildBudgetAllocations)) {
			if (json) {
				selects.push(`
					'childBudgetAllocations', ${alias}_childbudgetallocations."budgetAllocationArr"
				`);
			} else {
				selects.push(`
					${alias}_childbudgetallocations."budgetAllocationArr" AS "childBudgetAllocations"
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.BUDGET_ALLOCATION_QUERY_ALIAS,
		targets: BudgetAllocationSelect[] = this.defaultSelects
	) {
		if (!targets.length) {
			return '';
		}

		const subQueries: string[] = [];

		if (targets.includes(BudgetAllocationSelect.FundingSource)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', fs2.id,
								'name', fs2.name
							)
						FROM
							"fundingSources" AS fs2
						WHERE
							fs2.id = ${alias}."fundingSourceId"
					) AS "fundingSource"
				) AS ${alias}_fundingsource
			`);
		}

		if (targets.includes(BudgetAllocationSelect.FundingType)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', ft2.id,
								'name', ft2.name
							)
						FROM
							"fundingTypes" AS ft2
						WHERE
							ft2.id = ${alias}."fundingTypeId"
					) AS "fundingType"
				) AS ${alias}_fundingtype
			`);
		}

		if (targets.includes(BudgetAllocationSelect.BrandAllocations)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', bra2.id,
								'brandId', brands2.id,
								'brand', JSONB_BUILD_OBJECT (
									'id', brands2.id,
									'name', brands2.name
								),
								'split', bra2.split,
								'budgetDistributions', (
									SELECT
										JSON_AGG(bd2)
									FROM
										"budgetDistributions" AS bd2
									WHERE
										bd2."brandAllocationId" = bra2.id
								)
							)
						FROM
							"brandAllocations" AS bra2
						LEFT JOIN
							brands as brands2
								ON
									brands2.id = bra2."brandId"
						WHERE
							bra2."budgetAllocationPlannedId" = ${alias}.id
					) AS "allocationsArr"
				) AS ${alias}_brandallocationsplanned
			`);

			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', bra2.id,
								'brandId', brands2.id,
								'brand', JSONB_BUILD_OBJECT (
									'id', brands2.id,
									'name', brands2.name
								),
								'split', bra2.split,
								'budgetDistributions', (
									SELECT
										JSON_AGG(bd2)
									FROM
										"budgetDistributions" AS bd2
									WHERE
										bd2."brandAllocationId" = bra2.id
								)
							)
						FROM
							"brandAllocations" AS bra2
						LEFT JOIN
							brands as brands2
								ON
									brands2.id = bra2."brandId"
						WHERE
							bra2."budgetAllocationActualId" = ${alias}.id
					) AS "allocationsArr"
				) AS ${alias}_brandallocationsactual
			`);
		}

		if (targets.includes(BudgetAllocationSelect.BudgetDistributionGroups)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							ROW_TO_JSON(bdg2)
						FROM
							"budgetDistributionGroups" AS bdg2
						WHERE
							bdg2."budgetAllocationId" = ${alias}.id
					) AS "distributionGroupsArr"
				) AS ${alias}_budgetdistributiongroups
			`);
		}

		if (targets.includes(BudgetAllocationSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSONB_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if (targets.includes(BudgetAllocationSelect.Plan)) {
			const planAlias = 'pl2';
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								${PlanQuery.getSelects(planAlias, [PlanSelect.Brands], true)}
							)
						FROM
							"plans" AS ${planAlias}
						${PlanQuery.getSubqueries(planAlias, [PlanSelect.Brands])}
						WHERE
							${planAlias}.id = ${alias}."planId"
					) AS "plan"
				) AS ${alias}_plan
			`);
		}

		if (targets.includes(BudgetAllocationSelect.ChildBudgetAllocations)) {
			const subAlias = 'cba2';
			const childSelects = [
				BudgetAllocationSelect.FundingSource,
				BudgetAllocationSelect.FundingType,
				BudgetAllocationSelect.BrandAllocations,
				//BudgetAllocationSelect.Author
			];
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${this.getSelects(subAlias, childSelects, true)}
							)
						FROM
							"budgetAllocations" AS ${subAlias}
						${this.getSubqueries(subAlias, childSelects)}
						WHERE
							${subAlias}."planId" = ${alias}.id
							AND ${subAlias}.deleted != true
					) AS "budgetAllocationArr"
				) AS ${alias}_childbudgetallocations
			`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}

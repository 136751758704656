import {
	Pipe,
	PipeTransform
} from '@angular/core';
import * as markdown from 'markdown-it';
@Pipe({
	name: 'markdown'
})
export class MarkDownPipe implements PipeTransform {
	transform(value: string): string {
        const md = markdown();
		return md.render(value);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArrayByMultiple'
})
export class FilterArrayByMultiplePipe implements PipeTransform {
	transform(array: any[], objKey: string, ...values: any[]): any[] {
		const filteredValues: any[] = [];

		if (array && objKey) {
			for (const arrayValue of array) {
				if (values.includes(arrayValue[objKey])) {
					filteredValues.push(arrayValue);
				}
			}

			return filteredValues;
		}
		return array;
	}
}

import { Parser } from '@json2csv/plainjs';

export class CsvUtils {
	public static async generateCsv(json: any): Promise<string> {
		try {
			const json2csvParser = new Parser();
			return await json2csvParser.parse(json);
		} catch (err) {
			console.error(err);
		}
	}

	public static async generateCsvAndDownload(fileName: string, json: any): Promise<void> {
		const csv = await this.generateCsv(json);
		this._generateDownloadLink(`${fileName}.csv`, new TextEncoder().encode(csv), 'text/csv');
	}

	public static _generateDownloadLink(downloadLinkName: string, response: ArrayBuffer, mimeType: string): void {
		const blob = new Blob([response], { type: mimeType });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = downloadLinkName;
		link.click();
	}
}

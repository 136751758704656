import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalStore } from './global.store';
import {
  AdminComplianceTrackerDto,
  AdminComplianceTrackerResponse,
  AdminDashboardClientDropdowns,
  AppSection,
  GlobalSettings,
  GlobalStateViewMode,
} from './global.model';
import { environment } from '../../../environments/environment';
import { catchError, take, tap } from 'rxjs/operators';
import { TacticType } from '../entities/tactic/tactic.model';
import { groupEntities } from '../../_core/utils/array.utils';
import { sortBy } from 'lodash';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Entity } from '../entities/entities.model';
import { defaultStyleOptions } from './chart.model';
import { arrayUpdate } from '@datorama/akita';
import { OrganizationSettings } from '../../../../../api/src/organization/organization.settings';
import { ProgramService } from '../entities/program/program.service';
import { InvoiceService } from '../entities/invoice/invoice.service';
import { PlanService } from '../entities/plan/plan.service';
import { TacticService } from '../entities/tactic/tactic.service';
import { GlobalQuery } from './global.query';
import { TacticsPhaseService } from '../entities/tactics-phase/tactics-phase.service';
import { TacticsPhaseStore } from '../entities/tactics-phase/tactics-phase.store';
import { ProgramPhaseService } from '../entities/program-phase/program-phase.service';
import { ProgramPhaseStore } from '../entities/program-phase/program-phase.store';
import { Observable, throwError } from 'rxjs';
import { UpdateEntityDropdownOptionsDto } from '../../../../../api/src/program-utilization/dtos/update-entity-dropdown-options.dto';
import { ProgramType, ProgramUtilization } from '../entities/program/program.model';
import { UserSettings } from '../../../../../api/src/user/models/user.settings';
import { PublicTacticAgency } from '../../../../../api/src/tactic-agency/tactic-agency.entity';
import { OverviewViewType } from '../overview/overview.model';

/**
 * Global Service
 * This service is responsible for global level functions and API calls.
 */
@Injectable({ providedIn: 'root' })
export class GlobalService {
  public basePath = environment.apiUrl;
  public defaultHeaders = new HttpHeaders({
    Accept: 'application/json',
  });

  constructor(
    private globalStore: GlobalStore,
    private httpClient: HttpClient,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private programService: ProgramService,
    private tacticService: TacticService,
    private planService: PlanService,
    private invoiceService: InvoiceService,
    private globalQuery: GlobalQuery,
    private tacticsPhaseService: TacticsPhaseService,
    private tacticsPhaseStore: TacticsPhaseStore,
    private programPhaseService: ProgramPhaseService,
    private programPhaseStore: ProgramPhaseStore
  ) {}

  /**
   * Get the organization settings for the site.
   */
  get() {
    this.globalStore.setLoading(true);

    return this.httpClient
      .get<GlobalSettings>(`${environment.apiUrl}/organization/${environment.organizationId}/settings`, {
        headers: this.defaultHeaders,
      })
      .pipe(
        tap((settings) => {
          settings.tacticTypeGroupByCategory = groupEntities(settings.tacticTypes, (type: TacticType) => type.tacticCategory.name);

          settings.tacticTypeGroupByCategory = this.sortGroupedItems(settings.tacticTypeGroupByCategory, 'name');

          this.globalStore.update({
            settings: {
              ...settings,
              fundingSources: this.setColorsForArray(sortBy(settings.fundingSources, 'name')),
              fundingTypes: this.setColorsForArray(sortBy(settings.fundingTypes, 'name')),
              agencies: this.setColorsForArray(sortBy(settings.agencies, 'name')),
              retailers: this.setColorsForArray(sortBy(settings.retailers, 'name')),
              brands: this.setColorsForArray(sortBy(settings.brands, 'name')),
              brandInitiatives: this.setColorsForArray(sortBy(settings.brandInitiatives, 'name')),
              programSectors: this.setColorsForArray(sortBy(settings.programSectors, 'name')),
              programPhases: this.setColorsForArray(sortBy(settings.programPhases, 'order')),
              tacticTypes: this.setColorsForArray(
                sortBy(
                  settings.tacticTypes.map((t) => ({
                    ...t,
                    name: `(${t.tacticCategory.name}) ${t.name}`,
                  })),
                  'name'
                )
              ),
              tacticPhases: this.setColorsForArray(sortBy(settings.tacticPhases, 'order')),
            },
          });

          // Migrations
          this.migrateProgramPhases();
          this.migrateTacticPhases();

          this.globalStore.setLoading(false);
        })
      );
  }

  /**
   * Get the public version of the organization settings.
   * This is used for login an other pre-authorization pages.
   */
  getPublic() {
    this.globalStore.setLoading(true);

    return this.httpClient
      .get<any>(`${environment.apiUrl}/organization/${environment.organizationId}/public`, { headers: this.defaultHeaders })
      .pipe(
        tap((settings) => {
          this.globalStore.update({
            settings,
          });
          this.globalStore.setLoading(false);
        })
      );
  }

  /**
   * Get a fully hydrated entity from the API.
   */
  getEntityDetails(entityType: string, id: string, parentId: string) {
    let getMethod;
    switch (entityType.toLowerCase()) {
      case 'plan':
        getMethod = this.planService.getOne(id);
        break;
      case 'program':
        getMethod = this.programService.getOne(id, true);
        break;
      case 'tactic':
        getMethod = this.tacticService.getOne(id, parentId);
        break;

      default:
        console.warn('No entity type found', entityType, id, parentId);
        return;
    }

    return getMethod;
  }

  /**
   * Determine what api call we need to make to create an entity
   */
  createEntity(type: string, value: any, parentId: string, skipHttp?: boolean) {
    let apiMethod;

    switch (type.toLowerCase()) {
      case 'program':
        apiMethod = this.programService.create(value);
        break;

      case 'tactic':
        apiMethod = this.tacticService.create(value, true);
        break;

      case 'plan':
        apiMethod = this.planService.create(value);
        break;

      case 'invoice':
        apiMethod = this.invoiceService.create(parentId, value);
        break;

      default:
        this.triggerErrorMessage(undefined, 'Cant update this row type');
        return;
    }

    return apiMethod;
  }

  /**
   * Determine what api call we need to make to update this row.
   */
  updateEntity(type: string, id: string, value: any, parentId: string, skipHttp?: boolean) {
    let apiMethod;

    switch (type.toLowerCase()) {
      case 'program':
        apiMethod = this.programService.update(id, value);
        break;

      case 'tactic':
        apiMethod = this.tacticService.update(id, parentId, value);
        break;

      case 'plan':
        apiMethod = this.planService.update(id, value);
        break;

      case 'invoice':
        apiMethod = this.invoiceService.update(parentId, id, value, skipHttp);
        break;

      default:
        this.triggerErrorMessage(undefined, 'Cant update this row type');
        return;
    }

    return apiMethod;
  }

  /**
   * Determine what api call we need to make to update this row.
   */
  removeEntity(type: string, id: string, parentId: string) {
    let apiMethod;

    switch (type.toLowerCase()) {
      case 'program':
        apiMethod = this.programService.remove(id);
        break;

      case 'tactic':
        apiMethod = this.tacticService.remove(id, parentId);
        break;

      case 'plan':
        apiMethod = this.planService.remove(id);
        break;

      case 'invoice':
        apiMethod = this.invoiceService.remove(parentId, id);
        break;

      default:
        this.triggerErrorMessage(undefined, 'Cant remove this row type');
        return;
    }

    return apiMethod;
  }

  updateOrganizationSettings(settings: Partial<OrganizationSettings>) {
    console.log('updateOrganizationSettings', settings);
    this.globalStore.setLoading(true);

    return this.httpClient
      .put<{ settings: any }>(`${environment.apiUrl}/organization/${environment.organizationId}/settings`, settings, {
        headers: this.defaultHeaders,
      })
      .pipe(
        tap((settings) => {
          this.globalStore.update({
            settings: {
              ...this.globalStore.getValue().settings,
              ...settings,
            },
          });
          this.globalStore.setLoading(false);
        })
      );
  }

  updateUserSettings(userId: string, userSettings: Partial<UserSettings>) {
    console.log('updateUserSettings', userSettings);
    this.globalStore.setLoading(true);

    return this.httpClient
      .put<{ settings: any }>(`${environment.apiUrl}/user/organization/${environment.organizationId}/${userId}/settings`, userSettings, {
        headers: this.defaultHeaders,
      })
      .pipe(
        tap((_userSettings) => {
          this.globalStore.update({
            settings: {
              ...this.globalStore.getValue().settings,
              userSettings: _userSettings.settings,
            },
          });
          this.globalStore.setLoading(false);
        })
      );
  }

  updateIntegrationConfig(endpoint: string, config: any) {
    this.globalStore.setLoading(true);

    return this.httpClient
      .put<any>(`${environment.apiUrl}/organization/${environment.organizationId}${endpoint}`, JSON.parse(config), {
        headers: this.defaultHeaders,
      })
      .pipe(
        tap((settings) => {
          this.globalStore.update({
            settings: {
              ...this.globalStore.getValue().settings,
              ...settings,
            },
          });
          this.globalStore.setLoading(false);
        })
      );
  }

  /**
   * Set an Admin Mode that triggers some debug UI on the site.
   * Search adminMode to find all of the places this applies.
   */
  setAdminMode(state?: boolean) {
    console.log('Setting Admin mode', state);
    this.globalStore.update({
      adminMode: state || !this.globalStore.getValue().adminMode,
    });
  }

  /**
   * Set an App Section
   *
   */
  setAppSection(appSection?: AppSection): void {
    console.log('Setting App Section', appSection);
    this.globalStore.update({
      appSection: appSection || this.globalStore.getValue().appSection,
    });
  }

  /**
   * Set global view mode
   *
   */
  setViewMode(viewMode: GlobalStateViewMode): void {
    this.globalStore.update({
      viewMode: viewMode,
    });
  }

  /**
   * Set overviewViewType
   *
   */
  setOverviewViewType(overviewViewType: OverviewViewType): void {
    this.globalStore.update({
      overviewViewType,
    });
  }

  /**
   * Set embed mode context data
   *
   */
  setEmbedModeContextData(contextData?: unknown): void {
    this.globalStore.update({
      embedModeContextData: contextData || this.globalStore.getValue().appSection,
    });
  }

  /**
   * Hide the top header bar.
   */
  hideHeader() {
    this.globalStore.update({
      header: {
        ...this.globalStore.getValue().header,
        visible: false,
      },
    });
  }

  /**
   * Show the top header bar.
   */
  showHeader() {
    this.globalStore.update({
      header: {
        ...this.globalStore.getValue().header,
        visible: true,
      },
    });
  }

  /**
   * Assign colors from our default pallet to an array of entities that don't have one.
   */
  setColorsForArray(arr: Partial<Entity>[]) {
    const colors = defaultStyleOptions.colors;
    const newArr = [];
    let count = 0;

    // this arr[i]?.color ? arr[i]?.color : colors[i]
    // will look for a custom color on `brands`
    // if nothing was found will populate with the array defaultStyleOptions.colors

    arr.forEach((item, i) => {
      newArr.push({
        ...item,
        color: arr[i]?.color ? arr[i]?.color : colors[i],
      });

      if (count >= defaultStyleOptions.colors.length - 1) {
        count = 0;
      } else {
        count++;
      }
    });

    return newArr;
  }

  getOrganizationSettingsFormObject(settings: OrganizationSettings, controlOverrides: any = {}) {
    return {
      applicationName: [settings.applicationName],
      logo: [settings.logo],
      logoSquare: [settings.logoSquare],
      frontendUrl: [settings.frontendUrl],
      notificationUrlAgencyUsers: [settings.notificationUrlAgencyUsers],
      notificationUrlNormalUsers: [settings.notificationUrlNormalUsers],
      fileReviewLogo: [settings.fileReviewLogo],
      favIcon: [settings.favIcon],
      customStyles: [settings.customStyles],
      geometricsOrganization: [settings.geometricsOrganization],
      geometricsStartingStep: [settings.geometricsStartingStep],
      defaultAuthenticationStrategy: [settings.defaultAuthenticationStrategy],
      percentageOfSpendCompleteColumn: [settings.percentageOfSpendCompleteColumn ?? 'daily'],
      showPerformanceView: [settings.showPerformanceView],
      showSOPView: [settings.showSOPView],
      guardsEnabled: [settings.guardsEnabled],
      showBudgetPlanWorksheetModule: [settings.showBudgetPlanWorksheetModule],
      showCalendarEditMode: [settings.showCalendarEditMode],
      showProgramPerformanceUpload: [settings.showProgramPerformanceUpload],
      hideActivateSection: [settings.hideActivateSection],
      hidePlanSection: [settings.hidePlanSection],
      hideReportingSection: [settings.hideReportingSection],
      showMediaPlanSection: [settings.showMediaPlanSection],
      showInitiativeSnapshot: [settings.showInitiativeSnapshot],
      showMediaPlanner: [settings.showMediaPlanner],
      fileReviewsEnabled: [settings.fileReviewsEnabled],
      showFileReviewPage: [settings.showFileReviewPage],
      hideInternalReview: [settings.hideInternalReview],
      fileReviewsNotesTemplate: [settings.fileReviewsNotesTemplate],
      startingProgramPhase: [settings.startingProgramPhase],
      enableAutomaticProgramPhases: [settings?.enableAutomaticProgramPhases],
      inProgressProgramPhase: [settings?.inProgressProgramPhase],
      completedProgramPhase: [settings.completedProgramPhase],
      startingTacticPhase: [settings.startingTacticPhase],
      enableAutomaticTacticPhases: [settings?.enableAutomaticTacticPhases],
      inProgressTacticPhase: [settings.inProgressTacticPhase],
      completedTacticPhase: [settings.completedTacticPhase],
      defaultPlanBP: [settings.defaultPlanBP],
      defaultActivateBP: [settings.defaultActivateBP],
      defaultReportingBP: [settings.defaultReportingBP],
      defaultActivateView: [settings.defaultActivateView],
      enableAgencyRole: [settings.enableAgencyRole],
      dashboardConfigMap: [settings?.dashboardConfigMap ? JSON.stringify(JSON.parse(settings.dashboardConfigMap), null, 4) : undefined],
      reportingConfigMap: [settings?.reportingConfigMap ? JSON.stringify(JSON.parse(settings.reportingConfigMap), null, 4) : undefined],
      VYCCommercePlatformConfig: [
        settings.integrations?.VYCCommercePlatformConfig
          ? JSON.stringify(settings.integrations.VYCCommercePlatformConfig, null, 4)
          : undefined,
      ],
      budgetDistributionGroupCustomFields: [
        settings?.budgetDistributionGroupCustomFields
          ? JSON.stringify(JSON.parse(settings.budgetDistributionGroupCustomFields), null, 4)
          : undefined,
      ],
      budgetViewConfigMap: [settings.budgetViewConfigMap ? JSON.stringify(JSON.parse(settings.budgetViewConfigMap), null, 4) : undefined],
      programSnapshotConfigMap: [
        settings.programSnapshotConfigMap ? JSON.stringify(JSON.parse(settings.programSnapshotConfigMap), null, 4) : undefined,
      ],
      programTacticsGridQuickView: [
        settings.programTacticsGridQuickView ? JSON.stringify(JSON.parse(settings.programTacticsGridQuickView), null, 4) : undefined,
      ],
      activateInlineFilters: [
        settings.activateInlineFilters ? JSON.stringify(JSON.parse(settings.activateInlineFilters), null, 4) : undefined,
      ],
      planInlineFilters: [settings.planInlineFilters ? JSON.stringify(JSON.parse(settings.planInlineFilters), null, 4) : undefined],
      mediaPlanInlineFilters: [
        settings.mediaPlanInlineFilters ? JSON.stringify(JSON.parse(settings.mediaPlanInlineFilters), null, 4) : undefined,
      ],
      strategicBriefType: [settings.strategicBriefType],
    };
  }

  getUserSettingsFormObject(settings: UserSettings, controlOverrides: any = {}) {
    return {
      enableNotificationForFileReviewApprovalStatus: [settings.enableNotificationForFileReviewApprovalStatus ?? true],
      enableNotificationForFileReviewComments: [settings.enableNotificationForFileReviewComments ?? true],
      enableNotificationForNewFileReview: [settings.enableNotificationForNewFileReview ?? true],
      enableNotificationForNewVersionFileReview: [settings.enableNotificationForNewVersionFileReview ?? true],
      enableNotificationForTaggedCommentsInFileReview: [settings.enableNotificationForTaggedCommentsInFileReview ?? true],
      enableNotificationForWeeklyFileReviewSummary: [settings.enableNotificationForWeeklyFileReviewSummary ?? true],
      enableNotificationForFileReviewDueDate: [settings.enableNotificationForFileReviewDueDate ?? true],
      enableHomepageQuickViews: [settings.enableHomepageQuickViews ?? true],
      enableHomepageRecentChanges: [settings.enableHomepageRecentChanges ?? true],
      enableHomepageNeedsAttention: [settings.enableHomepageNeedsAttention ?? true],
      enableHomepageFileReview: [settings.enableHomepageFileReview ?? true],
      enableHomepageMyPrograms: [settings.enableHomepageMyPrograms ?? true],
    };
  }

  /**
   * Migrate program phases to include order.
   * This is a one-time migration to add order to program phases that don't have it.
   * It will look into the current settings and update the program phases if any of them has no order set,
   * in such case will sort all program phases by name and assign order based on the order in the array.
   *
   * @returns {Subscription}
   */
  migrateProgramPhases() {
    return this.globalQuery.authenticatedSettings$.pipe(take(1)).subscribe((settings) => {
      let programPhases = settings.programPhases;
      if (settings.programPhases.find((e) => !e.order || e.order < 1)) {
        programPhases = programPhases.sort((a, b) => a.name.localeCompare(b.name));
        let idx = 0;
        for (const programPhase of programPhases) {
          idx++;
          programPhase.order = idx;
          const obj = {
            id: programPhase.id,
            ['order']: idx,
          };
          this.programPhaseService.update(programPhase.id, obj).subscribe(
            (response) => {
              console.log('Migrated ProgramPhase', response);
            },
            (err: HttpErrorResponse) => this.triggerErrorMessage(err)
          );
          this.programPhaseStore.update(programPhase.id, programPhase);
        }
      }
    });
  }

  /**
   * Migrate tactic phases to include order.
   * This is a one-time migration to add order to tactic phases that don't have it.
   * It will look into the current settings and update the tactic phases if any of them has no order set,
   * in such case will sort all tactic phases by name and assign order based on the order in the array.
   *
   * @returns {Subscription}
   */
  migrateTacticPhases() {
    this.globalQuery.authenticatedSettings$.pipe(take(1)).subscribe((settings) => {
      let tacticPhases = settings.tacticPhases;
      if (settings.tacticPhases.find((e) => !e.order || e.order < 1)) {
        tacticPhases = tacticPhases.sort((a, b) => a.name.localeCompare(b.name));
        let idx = 0;
        for (const tacticPhase of tacticPhases) {
          idx++;
          tacticPhase.order = idx;
          const obj = {
            id: tacticPhase.id,
            ['order']: idx,
          };
          this.tacticsPhaseService.update(tacticPhase.id, obj).subscribe(
            (response) => {
              console.log('Migrated TacticPhase', response);
            },
            (err: HttpErrorResponse) => this.triggerErrorMessage(err)
          );
          this.tacticsPhaseStore.update(tacticPhase.id, tacticPhase);
        }
      }
    });
  }

  /**
   * Update an item inside of a settings entity list.
   */
  updateSettingsEntityItem(key: string, itemId: string, property: string, value: any) {
    const obj = {};
    obj[property] = value;
    this.globalStore.update({
      settings: {
        ...this.globalStore.getValue().settings,
        [key]: arrayUpdate(this.globalStore.getValue().settings[key], itemId, obj),
      },
    });
  }

  /**
   * Get the color fo a certain entity in a settings array.
   */
  getColorFromSettingsEntity(key: string, id: string) {
    switch (key) {
      default:
        const entities = this.globalStore.getValue().settings[key];
        if (entities) {
          return entities.find((entity) => entity.id === id)?.color;
        }
        break;
    }
  }

  /**
   * Set the Browser title bar message.
   */
  setTitle(title: string) {
    this.titleService.setTitle(title + ' | ' + this.globalStore.getValue()?.settings?.name);
  }

  /**
   * Open a snack bar presenting the error message to the user.
   * @param err The error response object
   * @param message Override with a custom message
   */
  triggerSavingProgressMessage(message?: string) {
    return this.snackBar.open(message || 'Saving...', undefined, {
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: 'secondary',
    });
  }

  /**
   * Open a snack bar presenting the error message to the user.
   * @param err The error response object
   * @param message Override with a custom message
   */
  triggerSaveMessage(message?: string) {
    this.snackBar.open(message || 'Saved.', undefined, {
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      duration: 2000,
      panelClass: 'success',
    });
  }

  /**
   * Open a snack bar presenting the error message to the user.
   * @param err The error response object
   * @param message Override with a custom message
   */
  triggerErrorMessage(err: HttpErrorResponse, message?: string) {
    this.snackBar.open(err?.error?.message || err?.message || message || 'There was an error completing this task.', undefined, {
      duration: 4000,
      panelClass: 'danger',
    });
  }

  /**
   * Open a snack bar presenting the warning message to the user.
   * @param message Override with a custom message
   */
  triggerWarningMessage(message?: string) {
    this.snackBar.open(message || 'There was an error completing this task.', undefined, {
      duration: 4000,
      panelClass: 'secondary',
    });
  }

  /**
   * Sort a set of items inside multiple groups
   * @param groups
   * @param sortBy
   * @returns
   */
  sortGroupedItems(groups: { items: any[] }[], sortBy: string) {
    const tempArr = [...groups].sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
    const newArr = [];

    for (const i in tempArr) {
      const items = [...tempArr[i].items].sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
      newArr.push({
        ...tempArr[i],
        items,
      });
    }

    return newArr;
  }

  /**
   * Get Reporting Admin Client Drop Downs Count
   */
  getOrganizationClientDropDownCounts(budgetPeriodIds: string[]): Observable<AdminDashboardClientDropdowns> {
    return this.httpClient
      .post<AdminDashboardClientDropdowns>(
        `${environment.apiUrl}/organization/${environment.organizationId}/admin-dashboard-usage`,
        { budgetPeriodIds },
        { headers: this.defaultHeaders }
      )
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * Get Reporting Admin Compliance Tracker
   */

  getAdminComplianceTracker(dto: AdminComplianceTrackerDto): Observable<AdminComplianceTrackerResponse[]> {
    return this.httpClient
      .post<AdminComplianceTrackerResponse[]>(`${environment.apiUrl}/organization/${environment.organizationId}/compliance-tracker`, dto, {
        headers: this.defaultHeaders,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  updateEntityDropdowns(type: 'programUtilization' | 'programType' | 'tacticAgency', dto: UpdateEntityDropdownOptionsDto) {
    switch (type) {
      case 'programUtilization':
        return this.httpClient
          .patch<ProgramUtilization[]>(`${environment.apiUrl}/organization/${environment.organizationId}/program-utilization`, dto, {
            headers: this.defaultHeaders,
          })
          .pipe(
            tap((programUtilizations) => {
              this.globalStore.update({
                settings: {
                  ...this.globalStore.getValue().settings,
                  programUtilizations,
                },
              });
            }),
            catchError((err) => throwError(err))
          );
      case 'programType':
        return this.httpClient
          .patch<ProgramType[]>(`${environment.apiUrl}/organization/${environment.organizationId}/program-type`, dto, {
            headers: this.defaultHeaders,
          })
          .pipe(
            tap((programTypes) => {
              this.globalStore.update({
                settings: {
                  ...this.globalStore.getValue().settings,
                  programTypes,
                },
              });
            }),
            catchError((err) => throwError(err))
          );
      case 'tacticAgency':
        return this.httpClient
          .patch<PublicTacticAgency[]>(`${environment.apiUrl}/organization/${environment.organizationId}/tactic-agency`, dto, {
            headers: this.defaultHeaders,
          })
          .pipe(
            tap((tacticAgencies) => {
              this.globalStore.update({
                settings: {
                  ...this.globalStore.getValue().settings,
                  tacticAgencies,
                },
              });
            }),
            catchError((err) => throwError(err))
          );
      default:
        throw new Error(`No entity type found for ${type}`);
    }
  }

  /**
   * Get Organization Custom JSON Data If Exists
   */
  getOrganizationCustomData(): Observable<{ created: Date; updated: Date; data: any } | undefined> {
    return this.httpClient
      .get<{ created: Date; updated: Date; data: any } | undefined>(
        `${environment.apiUrl}/organization/${environment.organizationId}/custom-data`,
        { headers: this.defaultHeaders }
      )
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   *  Update Organization Custom JSON Data
   */
  updateOrganizationCustomData(data: any): Observable<{ created: Date; updated: Date; data: any } | undefined> {
    return this.httpClient
      .put<{ created: Date; updated: Date; data: any } | undefined>(
        `${environment.apiUrl}/organization/${environment.organizationId}/custom-data`,
        data,
        { headers: this.defaultHeaders }
      )
      .pipe(catchError((err) => throwError(err)));
  }
}

import { File, FileReviewStatus, PublicFile, ReviewerRole } from '../file.entity';
import { CommentStatus, PublicComment } from '../../comment/comment.entity';
import { PublicUser } from '../../user/user.entity';
import moment from 'moment';

export class FileReviewUtils {
	static readonly ROOT_VERSION = 1;

	public static getUserReviewRole = (userId: string, file: PublicFile): ReviewerRole | null => {
		if (file?.versions?.length) {
			// Use latest version if available
			file = file.versions[0] as PublicFile;
		}
		if (file?.approvers?.some(approver => approver.id === userId)) {
			return ReviewerRole.Approver;
		} else if (file?.reviewers?.some(reviewer => reviewer.id === userId)) {
			return ReviewerRole.Reviewer;
		}
		return null;
	};

	public static getUserReviewStatus = (userId: string, file: PublicFile) => this._getReviewStatus(file, userId);

	public static getReviewStatus = (file: PublicFile): FileReviewStatus => this._getReviewStatus(file);

	public static getAvgNumberOfVersions = (files: File[]): number =>
		Number((files.reduce((acc, file) => acc + ((file?.versions?.length ?? 0) + this.ROOT_VERSION), 0) / files.length)?.toFixed(1));

	public static isFileStillInReview = (file: File): boolean => {
		// If versions exists check status from latest version
		if (file?.versions?.length && !file?.versions[0]?.approved) {
			return true;
		} else if (!file?.versions?.length && !file?.approved) {
			return true;
		}
		return false;
	};

	public static isFileApproved = (file: File): boolean => {
		// If versions exists check status from latest version
		if (file?.versions?.length && file?.versions[0]?.approved) {
			return true;
		} else if (!file?.versions?.length && file?.approved) {
			return true;
		}
		return false;
	};

	public static getMaxNumOfVersions = (files: File[]): number => {
		if (!files.length) {
			return 0;
		}
		const maxNumOfVersions = files.reduce((max, obj) => {
			const length = (obj.versions ?? []).length;
			return Math.max(max, length);
		}, 0);
		return maxNumOfVersions + this.ROOT_VERSION;
	};

	public static getFriendlyReviewStatus(fileReviewStatus: FileReviewStatus): string {
		switch (fileReviewStatus) {
			case FileReviewStatus.Approved:
				return 'Approved';
			case FileReviewStatus.ChangesRequested:
				return 'Changes Requested';
			case FileReviewStatus.Pending:
				return 'Pending';
			case FileReviewStatus.ReviewOnly:
				return 'Review Only';
			default:
				return '';
		}
	}

	public static getFriendlyCommentStatus(fileReviewStatus: CommentStatus): string {
		switch (fileReviewStatus) {
			case CommentStatus.Approved:
				return 'Approved';
			case CommentStatus.ChangesRequested:
				return 'Changes Requested';
			default:
				return 'n/a';
		}
	}

	public static getLastCommentByUser(comments: PublicComment[], approvers: PublicUser[]): PublicComment[] {
		// Create a set of approver IDs
		const approverIds = new Set((approvers ?? []).map(approver => approver.id));

		if (!approverIds.size) {
			return [];
		} else if (!(comments ?? []).length) {
			return [];
		} else {
			// Sort the comments by created date in descending order
			comments = (comments ?? []).filter(comment => comment.status).sort((a, b) => (a.created < b.created ? 1 : -1));

			return comments.reduce((acc, comment) => {
				// Determine the author's ID, considering both 'author?.id' and 'authorId'
				const authorId = comment.author?.id || comment.authorId;

				// Check if the author's ID is already in the set
				if (acc.some(c => (c.author?.id || c.authorId) === authorId)) {
					// If we already have a comment from this author, skip it
					return acc;
				}

				// Add the comment to the accumulator if the author is an approver
				if (approverIds.has(authorId)) {
					acc.push(comment);
				}

				return acc;
			}, []);
		}
	}

	public static isFileApprovalComplete(comments: PublicComment[], approvers: PublicUser[]): boolean {
		const lastCommentStatusByUser = FileReviewUtils.getLastCommentByUser(comments, approvers);
		const approvedCommentsByUser = lastCommentStatusByUser?.filter(comment => comment.status === CommentStatus.Approved);
		return approvedCommentsByUser?.length && approvers?.length && approvedCommentsByUser?.length === approvers?.length;
	}

	private static _getReviewStatus(file: PublicFile, userId?: string): FileReviewStatus {
		if (file?.versions?.length) {
			// Use latest version if available
			const parentFile = file;
			file = file.versions[0] as PublicFile;
			file = {
				...file,
				approvers: parentFile.approvers,
				reviewers: parentFile.reviewers
			};
		}

		// Check for global approval first for all users
		if (!userId && file.approved) {
			return FileReviewStatus.Approved;
		}
		// check if user is an reviewer
		if (userId && file?.reviewers?.some(reviewer => reviewer.id === userId)) {
			return FileReviewStatus.ReviewOnly;
		}

		// Check if it is filter by user and user is not an approver
		if (userId && !file?.approvers?.some(approver => approver.id === userId)) {
			return FileReviewStatus.NA;
		}

		if (userId) {
			// Retrieve all comments or filter by specific user if userId is provided
			let comments = file.comments?.filter(comment => comment.status)?.sort((a, b) => (a.created < b.created ? 1 : -1));
			comments = comments?.filter(comment => comment?.author?.id === userId);
			// Determine the review status based on the latest comment
			if (comments?.length > 0) {
				if (comments[0].status === CommentStatus.Approved) {
					return FileReviewStatus.Approved;
				} else if (comments[0].status === CommentStatus.ChangesRequested) {
					return FileReviewStatus.ChangesRequested;
				}
			}
			return FileReviewStatus.Pending;
		}
		// Check all comments for approval status
		else {
			const approverIds = [...new Set((file.approvers ?? [])?.map(approver => approver.id as string) ?? [])];
			const lastCommentByUser = this.getLastCommentByUser(file?.comments ?? [], file?.approvers ?? []);
			const approvedCommentsByUser = lastCommentByUser?.filter(comment => comment.status === CommentStatus.Approved);
			const changesRequestedCommentsByUser = lastCommentByUser?.filter(comment => comment.status === CommentStatus.ChangesRequested);

			if (approvedCommentsByUser?.length && approverIds?.length && approvedCommentsByUser?.length === approverIds?.length) {
				return FileReviewStatus.Approved;
			} else if (changesRequestedCommentsByUser.length && approverIds?.length) {
				return FileReviewStatus.ChangesRequested;
			} else {
				return FileReviewStatus.Pending;
			}
		}
	}

	public static getFileDueDateClass(file: PublicFile) {
		const reviewStatus = this.getReviewStatus(file);
		if (reviewStatus === FileReviewStatus.Approved) {
			return '';
		}

		const dateNow = moment();
		const dueDate = moment(file.feedbackDueDate);

		if (dateNow.isSameOrAfter(dueDate, 'day')) {
			return 'danger';
		}

		if (dueDate.isBetween(dateNow, dateNow.clone().add(3, 'days'), 'day', '[]')) {
			return 'warning';
		}

		return '';
	}
}

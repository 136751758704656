<ngx-masonry [options]="{ gutter: 20, columnWidth: '.grid-sizer' }" [ordered]="true" [updateLayout]="updateMasonryLayout">
	<div class="grid-sizer"></div>
	<div *ngFor="let file of files; let i = index" class="masonry-item file" ngxMasonryItem>
		<ng-container *localVar="file | unwindLastFileVersion as fileVersion">
			<div (click)="showFileModal(file)" *ngIf="fileVersion?.thumbnail || isImage(fileVersion)" class="image">
				<img
					(load)="reLayout()"
					[src]="fileVersion?.thumbnail || fileVersion.path | secure | async"
					[ngClass]="{ pointer: authUserRole !== UserRole.Analyst }"
					loading="lazy"
					masonryLazy
				/>

				<!-- <mat-spinner [diameter]="40"></mat-spinner> -->
			</div>

			<div
				(click)="showFileModal(file)"
				*ngIf="!isImage(fileVersion) && !fileVersion?.thumbnail"
				class="thumb"
				[ngClass]="{ pointer: authUserRole !== UserRole.Analyst }"
			>
				<i [ngClass]="getFileTypeIcon(fileVersion.mimeType)" class="media-type"></i>
				<img
					(error)="$event.target.classList.add('hidden')"
					(load)="$event.target.classList.remove('hidden')"
					[src]="fileVersion.path + '?thumb=true' | secure: true | async"
					class="media-thumbnail"
					type="image/jpeg"
				/>
			</div>

			<div class="caption">
				<div class="text">
					<span class="name display-block" title="{{ file.name }}">{{ file.name }} </span>
					<span *ngIf="showFileReviewStatus" class="meta-label display-block" style="margin-bottom: 10px"
						>Version {{ file?.versions?.length + 1 }}</span
					>
					<div class="entities">
						<ng-container *ngIf="showEntityName">
							<a *ngIf="file.planId" [queryParams]="{ panel: 'resources' }" [routerLink]="['/plan', file.planId]" class="link"
								>Go to Plan</a
							>
							<!-- Program -->
							<a *ngIf="file.program && entityType !== 'program'" (click)="goToProgram(file.program.id)" class="link">{{
								file.program.name
							}}</a>
							<!-- Tactic -->
							<a
								*ngIf="file.tactic"
								(click)="goToTactic(file.program.id, file.tactic.id)"
								[routerLink]="['/program', file.program.id, 'tactic', file.tactic.id]"
								class="link display-block"
								>{{ file.tactic.name }}</a
							>
						</ng-container>
						<div *ngIf="showFileReviewStatus" class="file-review-status">
							<div>
								<span class="meta-label">My Review: </span>
								<span class="meta-label-value">{{ getUserReviewStatus(fileVersion) }}</span>
							</div>
							<div>
								<span class="meta-label">Feedback due: </span>
								<span class="meta-label-value">{{
									fileVersion?.feedbackDueDate ? (fileVersion?.feedbackDueDate | date) : "n/a"
								}}</span>
							</div>
							<div>
								<span class="meta-label">Review Status: </span>
								<ng-container *localVar="getReviewStatus(fileVersion) as reviewStatus">
									<span
										[ngClass]="{
											success: reviewStatus === FileReviewStatus.Approved,
											warning: reviewStatus === FileReviewStatus.ChangesRequested
										}"
										class="meta-label-value"
										>{{ FileReviewUtils.getFriendlyReviewStatus(reviewStatus) }}</span
									>
								</ng-container>
							</div>
						</div>
						<!-- <a
						*ngIf="showEntityName"
						class="link"
						[routerLink]="['/' + (file.planId || file.program?.id), file.tactic?.id || file.program?.id]"
					>{{ file?.plan?.name || file?.program?.name }}</a>
					{{ file.program?.id }}
					<span *ngIf="showEntityName && file.tactic"> - </span>
					<a *ngIf="file.planId && file.program" [routerLink]="['/program', file.program.id]">{{ file?.program?.name }}&nbsp;</a>
					<a *ngIf="file.tactic" [routerLink]="['/program', file.program.id, 'tactic', file.tactic.id]"
						>{{ file?.tactic?.name }}&nbsp;</a
					> -->
					</div>
				</div>

				<div *ngIf="showActions" class="actions margin-left-auto" fxLayout="row">
					<ng-container *notAllowedRoles="[UserRole.Analyst]">
						<i
							(click)="showFileModal(file)"
							*ngIf="file.requiresApproval && !file.approved"
							[matTooltip]="'In Review'"
							class="uil-file-search-alt pointer"
						></i>
						<i
							(click)="showFileModal(file)"
							*ngIf="file.requiresApproval && file.approved"
							[matTooltip]="'Approved'"
							class="uil-file-check text-success"
						></i>
					</ng-container>
					<div [matMenuTriggerData]="{ file: file }" [matMenuTriggerFor]="menu" class="btn">
						<i class="uil-ellipsis-v"></i>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</ngx-masonry>

<mat-menu #menu="matMenu">
	<ng-template let-file="file" matMenuContent>
		<button (click)="download(file)" mat-menu-item>
			<i class="uil-download-alt"></i>
			<span>Download</span>
		</button>
		<ng-container *notAllowedRoles="[UserRole.Analyst, UserRole.Guest, UserRole.OutsideAgency]">
			<button (click)="edit(file)" *ngIf="!readOnly" mat-menu-item>
				<i class="uil-file-edit-alt"></i>
				<span>Edit</span>
			</button>
			<ng-container *ngIf="fileReviewsEnabled">
				<button (click)="addToReview(file)" *ngIf="!file.requiresApproval" mat-menu-item>
					<i class="uil-file-search-alt"></i>
					<span>Add to Review</span>
				</button>
				<button (click)="reviewersModal(file)" *ngIf="file.requiresApproval" mat-menu-item>
					<i class="uil-users-alt"></i>
					<span>Manage Reviewers</span>
				</button>
				<button (click)="removeFromReview(file)" *ngIf="file.requiresApproval" mat-menu-item>
					<i class="uil-file-times-alt"></i>
					<span>Remove from Review</span>
				</button>
			</ng-container>
			<button (click)="removeModal(file)" *ngIf="mode !== 'snapshot' && !readOnly" color="danger" mat-menu-item>
				<i class="uil-file-times"></i>
				<span>Remove</span>
			</button>
		</ng-container>
	</ng-template>
</mat-menu>

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as uuid from 'uuid';

@Component({
	selector: 'app-strategic-brief-text-editor',
	templateUrl: './text-editor.component.html',
	styleUrls: [
		'./text-editor.component.scss',
		'../../../../../state/entities/program/program-measurement/program-measurement.component.scss',
	],
})
export class StrategicBriefTextEditorComponent implements OnInit, AfterViewInit {
	@Input() textValue: string;
	@Input() placeholder: string = 'Enter text here...';
	@Input() formControl: FormControl;
	@Input() readonly: boolean = false;

	@Output() textChanged: EventEmitter<string> = new EventEmitter<string>();

	public readonly editorId: string = uuid.v4();

	editorConfig: AngularEditorConfig;
	public text: string;

	constructor(private readonly elementRef: ElementRef) {}

	ngOnInit(): void {
		this.text = this.textValue;
		this.editorConfig = {
			editable: !this.readonly,
			spellcheck: true,
			height: 'auto',
			minHeight: '200px',
			maxHeight: 'auto',
			minWidth: '0',
			enableToolbar: !this.readonly,
			showToolbar: false,
			sanitize: true,
			placeholder: this.placeholder,
			toolbarPosition: 'top',
			toolbarHiddenButtons: [
				['strikeThrough', 'subscript', 'superscript', 'fontName'],
				[
					'fontSize',
					'textColor',
					'backgroundColor',
					'customClasses',
					'link',
					'unlink',
					'insertImage',
					'insertVideo',
					'insertHorizontalRule',
					'removeFormat',
					'toggleEditorMode',
				],
			],
			customClasses: [
				{
					name: 'custom-quote',
					class: 'custom-quote',
				},
			],
		};
	}

	ngAfterViewInit(): void {
		if (this.readonly) {
			const editorTextArea = this.elementRef.nativeElement.querySelector(`.angular-editor-textarea`);
			if (editorTextArea) {
				editorTextArea.style.border = 'none';
			}
		}
	}

	onEditorFocus(): void {
		if (!this.readonly) {
			this.editorConfig = { ...this.editorConfig, showToolbar: true };
		}
	}

	onEditorBlur(): void {
		this.editorConfig = { ...this.editorConfig, showToolbar: false };
		this.formControl.setValue(this.text);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterByArrayCustom'
})
export class FilterArrayByCustomPipe implements PipeTransform {
	transform<T>(values: any[], searchedItem: any, compare: (v1, v2) => boolean): any[] | undefined {
		if (values) {
			return values.filter(val => compare(val, searchedItem));
		}
		return undefined;
	}
}

import {
	IsEnum,
	IsNotEmpty,
	IsNumber,
	IsOptional,
	IsString,
	ValidateNested
} from 'class-validator';
import { Type } from 'class-transformer';
import { AggregateFunction, Condition } from '../../_core/models/math-operations';

export enum Conversion {
	Date = 'date'
}

export enum ExportType {
	List = 'list',
	Calendar = 'calendar'
}

export enum ExportKind {
	Plan = 'plan',
	Program = 'program',
	Tactic = 'tactic',
	Invoice = 'invoice'
}

export enum ExportRange {
	Months = 'months'
}

export class Filter {
	@IsOptional()
	@IsString()
	path?: string;

	@IsEnum(Condition)
	condition: Condition;

	@IsNotEmpty()
	value: any;
}

export class Aggregate {
	@IsOptional()
	@IsString()
	path?: string;

	@IsEnum(AggregateFunction)
	function: AggregateFunction;
}

export class ColumnDef {
	@IsString()
	@IsNotEmpty()
	name: string;

	@IsString()
	@IsNotEmpty()
	path: string;

	@IsOptional()
	@IsNumber()
	width: number;

	@IsOptional()
	@IsEnum(Conversion)
	convert?: Conversion;

	@IsOptional()
	@ValidateNested()
	@Type(() => Filter)
	filter?: Filter;

	@IsOptional()
	@ValidateNested()
	@Type(() => Aggregate)
	aggregate?: Aggregate;

	@IsOptional()
	@IsString({ each: true })
	dependencies?: string[];
}

export class ExportDto {
	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => ColumnDef)
	columns?: ColumnDef[];

	@IsOptional()
	@IsEnum(ExportType)
	exportType?: ExportType;

	@IsOptional()
	@IsEnum(ExportRange)
	exportRange?: ExportRange;
}

<div class="loader-checkmark-container" *ngIf="showCheckmark || loading; else content" [style.width.px]="size" [style.height.px]="size">
	<mat-spinner *ngIf="loading" [diameter]="size"></mat-spinner>
	<svg *ngIf="showCheckmark" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
	  <polyline class="path check" fill="none" stroke="var(--color-primary)" stroke-width="13" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
	</svg>
</div>

<ng-template #content>
	<div [@fadeIn]>
		<ng-content></ng-content>
	</div>
</ng-template>


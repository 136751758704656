import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScenarioSaveState } from '@vmlyr/size-of-prize-calc';
import { SessionStore } from '../../../state/session/session.store';
import { environment } from '../../../../environments/environment';

/**
 * Size of Pize Calculator Dialog
 * A dialog that houses the SOP calculator application
 */
@Component({
	selector: 'app-size-of-prize-calculator-dialog',
	templateUrl: './size-of-prize-calculator-dialog.component.html',
	styleUrls: ['./size-of-prize-calculator-dialog.component.scss']
})
export class SizeOfPrizeCalculatorDialogComponent implements OnInit {

	public authToken: string;
	public orgId: string;
	public apiUrl: string;

	constructor(
		public dialogRef: MatDialogRef<SizeOfPrizeCalculatorDialogComponent>,
		private sessionStore: SessionStore,
		@Inject(MAT_DIALOG_DATA) public state: ScenarioSaveState
	) {
		this.authToken = this.sessionStore.getValue().token;
		this.orgId = environment.organizationId;
		this.apiUrl = environment.apiUrl;
	}

	ngOnInit(): void {
		// console.log('TeTE', this.objectives);
	}

	onComplete(state: ScenarioSaveState) {
		this.dialogRef.close(state);
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataCache } from '../../../_core/utils/cache.utils';
import { environment } from '../../../../environments/environment';
import { Program } from '../program/program.model';
import { Tactic } from '../tactic/tactic.model';
import { Tag } from './tag.model';

/**
 * Tag Service
 * This service is responsible for tag logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class TagService {
	public cache: DataCache = new DataCache();

	constructor(private http: HttpClient) {}

	/**
	 * Return a list of tags based on a search query and an entity type.
	 */
	suggest(text: string, type?: 'program' | 'tactic', avoidCache?: boolean) {
		// Check if we've already made this API call in this session.  Use the cached result if so.
		if (this.cache.get(`${type}-${text}`) && !avoidCache) {
			return of(this.cache.get(`${type}-${text}`));
		}

		let endpoint = `${environment.apiUrl}/organization/${environment.organizationId}`;

		switch (type) {
			case 'program':
				endpoint += `/tag/program/suggest/?query=${text}`;
				break;

			case 'tactic':
				endpoint += `/tag/tactic/suggest/?query=${text}`;
				break;

			default:
				endpoint += `/tag/suggest/?query=${text}`;
				break;
		}

		return this.http.get<Tag[]>(endpoint).pipe(
			tap((response) => {
				this.cache.set(`${type}-${text}`, response);
			})
		);
	}
}

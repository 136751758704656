import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { StagedModification } from './staged-modification.model';

export interface StagedModificationState extends EntityState<StagedModification> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'staged-modification' })
export class StagedModificationStore extends EntityStore<StagedModificationState> {

  constructor() {
    super();
  }

}

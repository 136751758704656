import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { PublicUser } from '../../../../../../../api/src/user/user.entity';
import { User } from '../../user/user.model';

@Component({
	selector: 'app-file-review-status',
	templateUrl: './file-review-status.component.html',
	styleUrls: ['./file-review-status.component.scss']
})
export class FileReviewStatusComponent implements OnChanges {
	@Input() file: PublicFile;
	@Input() showWithLabels: boolean;

	pending: PublicUser[] = [];
	requestingChanges: PublicUser[] = [];
	approved: PublicUser[] = [];

	constructor() {
		this.showWithLabels = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.pending = [];
		this.requestingChanges = [];
		this.approved = [];
		if (changes.file) {
			(this.file?.approvers ?? [])?.forEach(approver => {
				const comments = this.file.comments
					?.filter(comment => comment.author.id === approver.id && comment.status)
					?.sort((a, b) => (a.created < b.created ? 1 : -1));

				if (comments?.length > 0) {
					if (comments[0].status === 'approved') {
						this.approved.push(approver);
					} else {
						this.requestingChanges.push(approver);
					}
				} else {
					this.pending.push(approver);
				}
			});
		}
	}

	getNameOrEmail(user: User) {
		return (user.nameFirst)
			? user.nameFirst + ' ' + user.nameLast
			: user.email;
	}
}

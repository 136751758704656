import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export interface AmountDifferenceParams {
	number1: number;
	number2: number;
	invert?: boolean;
	appendPhrase?: string;
}

/**
 * Amount Different Component
 * A simple component to display the difference between two numbers.
 * Supports inverting the difference, and color scheme for positive / negative.
 */
@Component({
	selector: 'app-amount-difference',
	templateUrl: './amount-difference.component.html',
	styleUrls: ['./amount-difference.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmountDifferenceComponent implements OnChanges {
	@Input() number1: number;
	@Input() number2: number;
	@Input() invert = false;
	@Input() appendPhrase: string;
	@Input() invertColor: boolean = false;

	public difference: number;
	public prefix: string = "-";

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if(changes){
			this.prefix = "";
			this.difference = (this.number1 - this.number2) * (this.invert ? -1 : 1);
			if(this.difference > 0) this.prefix = "+";
		}
	}
}

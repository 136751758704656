<mat-form-field class="input full-width" floatLabel="auto">
	<mat-label>{{ label }} {{ required ? "*" : "" }}</mat-label>
	<mat-chip-list #chipList aria-label="Item selection">
		<mat-chip *ngFor="let item of selected$ | async" (removed)="removeCb(item, id)">
			{{ item?.name || item?.profile?.nameFirst + " " + item?.profile?.nameLast }}
			<i class="icon icon-close" matChipRemove></i>
		</mat-chip>
		<input
			#multiselectInputRef
			[id]="id"
			[placeholder]="placeholder"
			[formControl]="searchCtrl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeyCodes"
			autocomplete="off"
			[required]="required"
		/>
	</mat-chip-list>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectCb($event, id)">
		<mat-option *ngFor="let item of filtered$ | async" [value]="item" [class.bold]="item.bold">
			{{ item?.name || item?.profile?.nameFirst + " " + item?.profile?.nameLast }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>

import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { MilestonesStore, MilestonesState } from './milestones.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
	sortBy: 'created',
	sortByOrder: Order.ASC
})
export class MilestonesQuery extends QueryEntity<MilestonesState> {
	constructor(protected store: MilestonesStore) {
		super(store);
	}
}

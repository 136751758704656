import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { SortStrategy } from '../../table/table.model';
import { FileCategories, FileState } from './file.model';

const initialState: FileState = {
	totalResults: 0,
	limit: 12,
	page: 1,
	category: FileCategories[0],
	budgetPeriodId: undefined,
	programId: undefined,
	tacticId: undefined,
	sort: 'created',
	sortStrategy: SortStrategy.DESC
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'file' })
export class FileStore extends EntityStore<FileState> {
	constructor() {
		super(initialState);
	}

	resetFilters() {
		this.update(initialState);
	}

	resetPageFilters() {
		this.update({
			totalResults: initialState.totalResults,
			page: initialState.page
		});
	}
}

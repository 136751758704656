<h1 mat-dialog-title *ngIf="!this.isUpdate">Add Files</h1>
<h1 mat-dialog-title *ngIf="this.isUpdate">Modify name and category</h1>

<div mat-dialog-content>
	<app-file-upload-container
		[entityId]="data.id"
		[entityType]="data.entityType"
		[file]="data.file"
		[referencePath]="data.referencePath"
		[referenceOptions]="data.tactics || data.programs"
		[isCategoryIgnored]="data.isCategoryIgnored"
		(stateChange)="setState($event)"
		(updated)="cancel()"
		(validFileList)="setCanUpload($event)"
		(validUpdateForm)="setCanUpdate($event)"
	></app-file-upload-container>
</div>

<div mat-dialog-actions>
	<button *ngIf="state !== 'complete'" mat-button (click)="cancel()">Close</button>
	<button *ngIf="state === 'adding' && !this.isUpdate" [disabled]="!this.canUpload" mat-button mat-flat-button class="button-extra-small" (click)="upload()">
		Upload</button
	><!-- [disabled]="!validate()" -->
	<button *ngIf="state === 'uploading'" mat-button mat-flat-button class="button-extra-small">Uploading...</button>
	<button *ngIf="this.isUpdate" [disabled]="!this.canUpdate" mat-button mat-flat-button class="button-extra-small" (click)="update()">Update</button>
	<button *ngIf="state === 'complete'" mat-button mat-flat-button class="button-extra-small margin-left-auto" (click)="cancel()">
		Done
	</button>
</div>

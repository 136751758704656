<div mat-dialog-title fxLayout="row">
	<h3>{{ data.title }}</h3>

	<div class="actions margin-left-auto">
		<button mat-mini-fab aria-label="Add" class="button-extra-small" color="tertiary" (click)="dismiss()">
			<i class="uil-multiply"></i>
		</button>
	</div>
</div>

<mat-dialog-content> <section [innerHTML]="data.content"></section> </mat-dialog-content>

<mat-dialog-actions align="end"> </mat-dialog-actions>

<swiper
	#swiper
	*ngIf="config"
	[config]="config"
	[class.bordered-slides]="borderedSlide"
>
	<ng-container *ngFor="let item of entities">
		<ng-template swiperSlide>
			<ng-container [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
		</ng-template>
	  </ng-container>
</swiper>


